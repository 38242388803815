<!-- main app container -->
<app-navbar></app-navbar>
  <header class="bg-surface  pt-6">
    <div style="float: right;padding-right: 9%;" class="col-lg-5 col-md-6 col-sm-12">
      <ul class="breadcrumb float-md-right">
        <li class="breadcrumb-item"> 
          <a class="color-blue" style="cursor: default;" 
            ><i class="zmdi zmdi-home"></i> Accueil</a
          >
        </li>
        <li class="breadcrumb-item color-blue">
          <a class="color-blue" routerLink="../">Contacts
          </a>
        </li>
        <li class="breadcrumb-item active color-blue " style="cursor: default;">Créer un contact</li>
      </ul>
    </div>
    <br>
    <br>
    <div class="container-fluid">
      <div class="mb-npx" style="width: 92%;">
        <div class="row align-items-center">
            <div class="col-1"></div>
          <div class="col-sm-11 col-12 mb-4 mb-sm-0 ">
            <!-- Title -->
            <h1 class="h2 mb-0 ls-tight color-blue title-1" >
                Créer un contact
            </h1>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>
        </div>
      
       
      </div>
    </div>
  </header>
  <br>
<div class="card m-3-cust">
    <div class="card-body ml-5">
        <form  #f0="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
            
             
          <div class="form-row">
            <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Genre</label>
                <ng-select [items]="gendres"
                formControlName="gendre"
               
                class="form-control input-custom"
                [closeOnSelect]="true"
                >
           </ng-select>
                
              </div>
            
            <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Prénom<b style="color: red;"> *</b></label>
                <input type="text" formControlName="lastName"  class="form-control input-custom" 
                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Champ obligatoire.</div>
                </div>
            </div>
            <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <label>Nom<b style="color: red;"> *</b></label>
              <input type="text" formControlName="firstName" class="form-control input-custom" 
               [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">Champ obligatoire.</div>
              </div>
          </div>
        </div>
            <div class="form-row">
              <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Société (Client)</label>
                <ng-select [items]="clients"
                bindLabel="companyName"
                bindValue="_id"
                class="form-control input-custom"
                [closeOnSelect]="true"
                multiple="true"
                formControlName="companyClient"
                #companySelect
                >
                </ng-select>
            </div>
            
            <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Société (Prestataire)</label>
                <ng-select [items]="sub_contractors"
                bindLabel="companyName"
                bindValue="_id"
                class="form-control input-custom"
                [closeOnSelect]="true"
                multiple="true"
                formControlName="companySubContractor"
                >
                </ng-select>
            </div>                              
              </div>
              <div class="row">
                <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>Téléphone</label>
                  <br>
                  <ngx-intl-tel-input
                  [cssClass]="'form-control input-custom-phone'"
                  formControlName="phone"
                  [enableAutoCountrySelect]="false"
                  [enablePlaceholder]="true"
                  [searchCountryFlag]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="CountryISO.Belgium"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  name="phone"
                 
                ></ngx-intl-tel-input>
                <div *ngIf="registerForm.value.phone && !f0.form.controls['phone'].invalid == false" style="color: red;">
                  Numéro de téléphone non validé <i class="bi bi-x"></i>
              </div>
              <div *ngIf=" registerForm.value.phone && !f0.form.controls['phone'].invalid == true" style="color: green;">
                Numéro de téléphone  validé <i class="bi bi-check"></i>
                 </div>
                
              </div>
              </div>
              
            
           

            <div class="form-row">
              <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>Adresse mail<b style="color: red;"> *</b></label>
                  <input type="text" formControlName="email" class="form-control input-custom"   [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Champ obligatoire.</div>
                      <div *ngIf="f.email.errors.email">Merci de saisir une adresse mail valide.</div>
                  </div>
               
              </div>
              <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>Langue</label>
                  <ng-select [items]="languages"
                  formControlName="language"
                
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  >
             </ng-select>
                
              </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
                <label>Commentaire</label>
                <textarea  type="text" formControlName="comment" class="form-control input-custom" 
                [ngClass]="{ 'is-invalid': submitted && f.comment.errors }" >
              
               </textarea>
               <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
                <div *ngIf="f.comment.errors.maxlength">Ce champ peut comporter 200 caractères maximum.</div>
            </div>
            </div>
          
        </div>
     
           
            <div style="padding-top: 3%;" class="text-center">
                
                <button routerLink="/admin/contacts" class="button_list btn btn-secondary mr-4 mb-2" type="reset">Annuler</button>
                <button class="button_list btn btn-primary mb-2">Sauvegarder</button>
            </div>
        </form>
    </div>
</div>
<br>

<!-- credits -->

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { ClientService } from 'src/app/_services/client.service';

@Component({
  selector: 'app-list-clients-archived',
  templateUrl: './list-clients-archived.component.html',
  styleUrls: ['../../../../_shared/list.css','./list-clients-archived.component.css']
})
export class ListClientsArchivedComponent implements OnInit {

  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  clients :any
  clientArchived
  copyListClients;
  ListCompanyName:any;
  ListContactPrincipale:any;
  companyNameToFiltred:any;
  tvaToFiltred:any;
  contactPrincipalToFiltred:any;
  emailFacturationToFiltred:any;
  constructor( private router: Router,
    private clientService :ClientService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService
    ) { }


  ngOnInit(): void {
    this.getClients();
  }

  getClient(client) {
    this.clientArchived = client;
  }
  getClients() {
    // this.companyNameToFiltred = undefined
    // this.tvaToFiltred = undefined
    // this.contactPrincipalToFiltred =undefined
    // this.emailFacturationToFiltred = undefined
  
    // this.clientArchived = true
    // this.page = 1

    this.clientService.getClientsArchived().subscribe((e) => {

      this.clients = e.reverse();;
      this.copyListClients = this.clients
      this.ListCompanyName = Array.from(new Set(this.clients.map(item => item.companyName)));
      this.ListContactPrincipale =   Array.from(new Set(this.clients.filter(item => item.contactMain !== "").map(item => item.contactMain)))
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.clients = this.clients;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getClients()
  }
  sort(column, descend,col) {
    switch (column) {
      case "companyName":
        if (this.col1 === false) {
          this.col1 = true;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      case "tva":
        if (this.col2 === false) {
          this.col1 = false;
          this.col2 = true;
          this.col3 = false;
          this.col4 = false;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      case "contactMain":
        if (this.col3 === false) {
          this.col1 = false;
          this.col2 = false;
          this.col3 = true;
          this.col4 = false;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      case "email":
        if (this.col4 === false) {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = true;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      default:
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
        break;
    }
  
    // if (column === "column4") {
    //   if (descend === false) {
    //     this.criteria = {
    //       property: column,
    //       descending: descend
    //     };
    //     this.descend = true;
    //   } else {
    //     this.criteria = {
    //       property: column,
    //       descending: descend
    //     };
    //     this.descend = false;
    //   }
    // } else {
      if (descend === false) {
        this.criteria = {
          property: column,
          descending: true
        };
        this.descend = true;
      } else {
        this.criteria = {
          property: column,
          descending: false
        };
        this.descend = false;
      }
    }
  details(id) {
    this.router.navigate(["admin/clients/details", id]);
  }
  filter() {
    this.clients = this.copyListClients;
    let filteredClients = [...this.copyListClients];
    // Convert filter criteria to lowercase
    const companyNameFilterLower = this.companyNameToFiltred ? this.companyNameToFiltred.toLowerCase() : '';
    const tvaFilterLower = this.tvaToFiltred ? this.tvaToFiltred.toLowerCase() : '';
    const contactPrincipalFilterLower = this.contactPrincipalToFiltred ? this.contactPrincipalToFiltred.toLowerCase() : '';
    const emailFacturationFilterLower = this.emailFacturationToFiltred ? this.emailFacturationToFiltred.toLowerCase() : '';
    filteredClients = this.clients.filter(item => {
      const companyNameFilter = companyNameFilterLower ? item.companyName.toLowerCase() === companyNameFilterLower : true;
      const contactPrincipalFilter = contactPrincipalFilterLower ? item.contactMain.toLowerCase() === contactPrincipalFilterLower : true;
  
      // Case-insensitive name search for a single character
      const tvaFilter = tvaFilterLower ? item.tva.toLowerCase().includes(tvaFilterLower) : true;
      const mailFacturationFilter = emailFacturationFilterLower ? item.email.toLowerCase().includes(emailFacturationFilterLower) : true;
      
      return companyNameFilter && tvaFilter && contactPrincipalFilter && mailFacturationFilter ;
    });
    this.clients = filteredClients;

    // Reset pagination
    this.page = 1;
  }
  filterByCompanyName(event) {
    this.companyNameToFiltred = event
  }
  filterByContactPrincipale(event) {
    this.contactPrincipalToFiltred = event
  }
  resetFilters() {
    this.tvaToFiltred = ""; 
    this.emailFacturationToFiltred = ""; 
    this.companyNameToFiltred = undefined; 
    this.contactPrincipalToFiltred = undefined;
    
    this.filter();
 }
 restoreClient(sub_contractor: any) {
  sub_contractor = this.clientArchived;
  this.clientService
    .archive_restoreClient(sub_contractor._id)
    .subscribe((e) => {
      this.iziToast.show({
        message: "Désarchivage avec succès",
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      // this.getClients()
      // this.page = 1
      this.router.navigate(["admin/clients"]);
    });
}


}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentUser: any
  d
  user
  constructor(private authentificationService :AuthenticationService, private userService :UserService
    ) {}

  ngOnInit(): void {
    this.currentUser=localStorage.getItem('user')
    this.d=this.userService.getUserrById(this.currentUser).subscribe(async data=>{
      this.user=data
     
    })
  }

  logout()
  {
    this.userService.disconnetUser(this.currentUser).subscribe(async data=>{
    })
    this.authentificationService.logout()
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EtablissementService {
  // getClients() {
  //   throw new Error('Method not implemented.');
  // }

  private _etablissementURL=`${environment.baseUrl}/etablissements`
  constructor(private http: HttpClient) { }

     getEtablissements() :Observable <any> {
      return this.http.get(`${this._etablissementURL}/listStormEtablissements`);
      }

      // tri by order By order alphabetique
      getEtablissementsByAlphabetical() :Observable <any> {
        return this.http.get(`${this._etablissementURL}/listStormEtablissementsBy-alphabetical`);
        }
 
      createEtablissements(user: any):Observable<Object> {
        return this.http.post(`${this._etablissementURL}`,user);
      }
      
    //   deleteContact(id : string)
    //   {
    //     return this.http.delete(`${this._contactURL}/${id}`);
    //   } 
 
     getEtablissementById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._etablissementURL}/${id}`);
      } 
      
      EditEtablissement(id:string,etablissement:any):Observable<Object> {
        return this.http.put(`${this._etablissementURL}/${id}`,etablissement);
      }
      getEtablissementArchiveds() :Observable <any> {
        return this.http.get(`${this._etablissementURL}/list_etablissement/archived`);
        }
     
    archive_restoreEtablissements(id :any){
      return this.http.get(`${this._etablissementURL}/archive-restore/${id}`);
    }

    getClientsByEtablissement(id : string):Observable<any>
    {
      return this.http.get<any>(`${this._etablissementURL}/clients-By-Etablissement/${id}`);
    } 

     // get client d'ont have etablissement

     getClientsWithNotEtablissement():Observable<any>
     {
       return this.http.get<any>(`${this._etablissementURL}/allClients/ByAllEtablissement`);
     } 
    
}

<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
  <ul class="breadcrumb float-md-right">
    <li class="breadcrumb-item"> 
      <a class="color-blue" style="cursor: default;" 
        ><i class="zmdi zmdi-home"></i> Accueil</a
      >
    </li>
    <li class="breadcrumb-item color-blue">
      <a class="color-blue" routerLink="../../">Suppafacs</a>
    </li>
    <li *ngIf="suppafac" class="breadcrumb-item active color-blue " style="cursor: default;">{{suppafac.title}}</li>
  </ul>
  </div>
  <br />
  <br />

</header>
<!-- Student Profile -->
<div class="student-profile py-4">
  <div *ngIf="suppafac" class="container">
    <div class="col-sm-11 col-12 mb-4 mb-sm-0">
      <h1 class="h2 mb-0 ls-tight color-blue title-1" style="color: #62B9CB;">{{suppafac.title}}</h1>
            <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails d'un Suppafac </h1>
      <br>
    </div>
    <div class="h2 mb-0 ls-tight text-sm-end">
      <a (click)="goToUpdateSuppafac()"  class="btn d-inline-flex btn-sm btn-primary mx-1">
        <span class="button_list" style="color: #fff">Modifier</span>
      </a>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="suppafac" class="card shadow-sm">
          <div class="card-header bg-transparent">
            <h3 class="mb-0"><i class="bi bi-info-circle-fill"></i> Informations générales</h3>
          </div>
          <div class="card-body row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Titre :</strong>
              <p class="mb-3 title-info">{{suppafac.title}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Chantier :</strong>
              <p class="mb-3 title-info" >{{suppafac.site.name}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Responsable :</strong>
                <p class="mb-3 title-info">{{suppafac.manager.firstName}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Planifié :</strong>
                <br>
                <i *ngIf="suppafac.planfie == true" style="color: #28A745;" class="bi bi-circle-fill"></i> 
                <i *ngIf="suppafac.planfie == false" style="color: #DC3545;" class="bi bi-circle-fill"></i> 
            </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Durée :</strong>
                <p class="mb-3 title-info" >{{suppafac.duration}}</p>
                <p *ngIf="!suppafac.duration" class="mb-3 title-info"> <span class="aucun-text" >Aucun</span></p>
              </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Statut :</strong>
                <!-- <p *ngIf="suppafac.statut == 'Reporting'"><span  class="badge badge-success">{{suppafac.statut}}</span></p>
                <p *ngIf="suppafac.statut == 'En cours'"><span  class="badge badge-pill badge-danger">{{suppafac.statut}}</span></p>
                <p *ngIf="suppafac.statut == 'Planifié'"><span  class="badge badge-pill badge-yellow">{{suppafac.statut}}</span></p> -->
                <p *ngIf="suppafac.statut == 'Clôturé'"><span class="badge badge-closed-gray">{{suppafac.statut}}</span></p>
                <p *ngIf="suppafac.statut == 'En cours'"><span class="badge badge-pill badge-inProgress-red">{{suppafac.statut}}</span></p>
                <p *ngIf="suppafac.statut == 'Planifié'"><span class="badge badge-pill badge-yellow-cheduled">{{suppafac.statut}}</span></p>
                <p *ngIf="suppafac.statut == 'A faire'"><span class="badge badge-pill badge-Blue-toDo">{{suppafac.statut}}</span></p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Personne de contact :</strong>
              <p class="mb-3 title-info" >{{suppafac.contact.lastName}} {{suppafac.contact.firstName}}</p>
            </div>
            <div *ngIf="suppafac.planfie == true">
              <strong class="pr-1 title-info">Date(s) de planification</strong>
              <div class="row" *ngFor="let d of suppafac.dates ;let k =index">
                  <p class="col-3">Date {{k+1}} : {{d.datePlanfie|date:'dd/MM/yyyy'}}
                  <span *ngIf="compareDates(d.datePlanfie)" style="margin-left: 5%;color: #28A745;">
                    Réalisée <i style="color: #28A745;" class="bi bi-check"></i>
                  </span>
                  <span *ngIf="!compareDates(d.datePlanfie)" style="margin-left: 5%;color: #888;">
                    Programmée  <i style="color: #888;" class="bi bi-calendar-event"></i>
                  </span>
                </p>
              </div>
          </div>
          </div>
          <div class="card-body row">
            <div class="col-12">
                <strong class="pr-1 title-info">Description :</strong>
                <p *ngIf="!suppafac.description" class="mb-3 title-info"> <span class="aucun-text" >Aucun</span></p>
                <p *ngIf="suppafac.description" class="mb-3 title-info" [innerHTML]="suppafac.description"> </p>
            </div>
            
           </div> 
           <div class="mt-4">
            <div class="d-flex justify-content-end">
              <div class="mr-3">
                <i style="color: #28A745;" class="bi bi-circle-fill"></i> Planifié
              </div>
              <div class="mr-3">
                <i style="color: #DC3545;" class="bi bi-circle-fill"></i> Non planifié
              </div>
            </div>
          </div>  
          <br>   
        </div>
      </div>
     
    </div>
    <br>
     <div>
     <div *ngIf="suppafac" class="card shadow-sm">
       <div class="card-header bg-transparent border-0">
         <h3 class="mb-0"><i class="bi bi-diagram-3 pr-1"></i>Liste des ouvriers de {{suppafac.title}}</h3>
       </div>
       <div class="card-body pt-0">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                <th scope="col">PRÉNOM</th>
                <th scope="col">NOM </th>
                <th scope="col">TÉLÉPHONE</th>
              </tr>
            </thead>
            <tbody *ngFor="let i of suppafac.worker  ;let k =index">
              <tr  (click)="goToDetailsWorker(i._id)" style="cursor: pointer;" *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden; cursor: pointer;">
                <td> 
                  <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                  {{i.lastName }}</td>
                 <td>
                  <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                  {{i.firstName}}</td>
                 <td>
                  <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                  {{i.phone}}
                 </td>
              </tr>
              <tr (click)="goToDetailsWorker(i._id)"  *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden; cursor: pointer;">
                <td> 
                  <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                  {{i.lastName }}</td>
                 <td>
                  <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                  {{i.firstName}}</td>
                 <td>
                  <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                  {{i.phone}}
                 </td>
            </tbody>
          </table>
        </div>
         <div
         *ngIf="suppafac && !suppafac.worker"
         class="table-responsive"
         style="text-align: center"
         >
         <p style="padding-bottom: 45px; padding-top: 35px ">
           Aucun ouvrier n'est trouvé
         </p>
         </div>
       </div>
     </div>
   </div>
  
<br>
<div>
  <div  class="card shadow-sm">
    <div class="card-header bg-transparent border-0">
      <h3 class="mb-0"><i class="bi bi-file-earmark-text-fill pr-1"></i>Liste des documents de {{suppafac.title}}</h3>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table">
          
          <!-- <h2>fff  {{listFiles | json }}</h2> -->
          <tbody *ngIf="listFiles" class="upload-name-style">
            <tr *ngFor="let item of listFiles; let i=index">
                <td (click)="openFileInNewWindow(suppafac.key,item)"><strong>{{ item }}</strong></td>
                <!-- <td><i (click)="deleteFileFromserver(myUUID,item)" style="color: #0e309f;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i></td> -->
                <td>
                <button
                class="btn btn-sm btn-square btn-neutral text-danger-hover"
              >
                <i
                  class="bi bi-eye"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Visualiser"
                  (click)="openFileInNewWindow(suppafac.key,item)"
                 
                ></i>
              </button>
            </td>
            </tr>
        </tbody>
    </table>
      </div>
    <div
    *ngIf="listFiles && listFiles.length == 0"
    class="table-responsive"
    style="text-align: center"
    >
    <p style="padding-bottom: 45px; padding-top: 35px ">
      Aucun document n'est trouvé
    </p>
    </div>
</div>
  </div>
</div>
</div>
</div>


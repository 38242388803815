<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
  <ul class="breadcrumb float-md-right">
    <li class="breadcrumb-item"> 
      <a class="color-blue" style="cursor: default;" 
        ><i class="zmdi zmdi-home"></i> Accueil</a
      >
    </li>
    <li class="breadcrumb-item color-blue">
      <a class="color-blue" routerLink="../../">Prestations vitres
      </a>
    </li>
    <li *ngIf="benefit" class="breadcrumb-item active color-blue " style="cursor: default;">{{benefit.type}}</li>
  </ul>
  </div>
  <br />
  <br />

</header>
<!-- Student Profile -->
<div class="student-profile py-4">
  <div *ngIf="benefit" class="container">
    <div class="col-sm-11 col-12 mb-4 mb-sm-0">
      <h1 class="h2 mb-0 ls-tight color-blue title-1" style="color: #62B9CB;"> {{benefit.type}}</h1>
      <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails d'une prestation vitres</h1>
    </div>
    <div class="h2 mb-0 ls-tight text-sm-end">
      <a (click)="goToUpdateGlass()" class="btn d-inline-flex btn-sm btn-primary mx-1">
        <span class="button_list" style="color: #fff">Modifier</span>
      </a>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="benefit" class="card shadow-sm">
          <div class="card-header bg-transparent">
            <h3 class="mb-0"><i class="bi bi-info-circle-fill"></i> Informations générales</h3>
          </div>
          <div class="card-body row">
            
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Chantier :</strong>
              <p class="mb-3 title-info">
                <span class="aucun-text" *ngIf="!benefit.site.name">Aucun</span>
                {{benefit.site.name}}
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Type de prestation:</strong>
              <p class="mb-3 title-info">
                <span class="aucun-text" *ngIf="!benefit.type">Aucun</span>
                {{benefit.type}}
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Prestataire :</strong>
              <p class="mb-3 title-info">
                <span class="aucun-text" *ngIf="!benefit.subContractor.companyName">Aucun</span>
                {{benefit.subContractor.companyName}}
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Contrat sous-traitant signé :</strong>
              <br>
              <i *ngIf="benefit.contratSST == true" style="color: #28A745;" class="bi bi-circle-fill"></i> 
              <i *ngIf="benefit.contratSST == false" style="color: #DC3545;" class="bi bi-circle-fill"></i> 
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <strong class="pr-1 title-info">Contact :</strong>
            <p class="mb-3 title-info">
              <span class="aucun-text" *ngIf="!benefit.contact">Aucun</span>
              {{benefit.contact.lastName}}   {{benefit.contact.firstName}}
            </p>
          </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Fréquence :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.frequence">Aucun</span>
                  <span *ngIf="benefit.frequence == 'Hebdomadaire' " class="badge badge-Blue">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Mensuelle' " class="badge badge-Pink">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Trimestrielle 1-4-7-10' " class="badge badge-Green">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Trimestrielle 2-5-8-11' " class="badge badge-Peach">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Trimestrielle 3-6-9-12' " class="badge badge-Lavender">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Quadrimestrielle' " class="badge badge-SkyBlue">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Semestrielle' " class="badge badge-Salmon">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Annuelle' " class="badge badge-Grey">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Bi-annuelle' " class="badge badge-Black">{{benefit.frequence}}</span>
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Date d’intervention :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.startDate">Aucun</span>
                  {{benefit.startDate |date:'dd/MM/yyyy' }}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Date de fin :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.endDate">Aucun</span>
                  {{benefit.endDate |date:'dd/MM/yyyy'}}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Dispositif :</strong>
                <br>
                {{benefit.typesMaterial}}
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="benefit.typesMaterial[0]==''">Aucun</span> </p>
               
              </div>
              <div *ngIf="benefit.typesMaterial.includes('Camions') " class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Plaque :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.plaque">Aucun</span>
                  {{benefit.plaque }}
                </p>
            </div>
            <div *ngIf="benefit.typesMaterial.includes('Nacelles') || benefit.typesMaterial.includes('Potences')" class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Attestation de contrôle :</strong>
              <br>
              <i *ngIf="benefit.controlCertificate == true" style="color: #28A745;" class="bi bi-circle-fill"></i> 
              <i *ngIf="benefit.controlCertificate == false" style="color: #DC3545;" class="bi bi-circle-fill"></i> 
          </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Numéro Osiris :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.numerosOsiris">Aucun</span>
                  {{benefit.numerosOsiris}}
                </p>
              </div>
      
         
          </div>
          <div class="card-body row">
            <div class="col-12">
                <strong class="pr-1 title-info">Description :</strong>
                  <p class="mb-3 title-info"> <span class="aucun-text" *ngIf="!benefit.description">Aucun</span>
                  {{benefit.description}}</p>
            </div>
            <div class="mt-4">
              <div class="d-flex justify-content-end">
                <div class="mr-3">
                  <i style="color: #28A745;" class="bi bi-circle-fill"></i> Oui
                </div>
                <div class="mr-3">
                  <i style="color: #DC3545;" class="bi bi-circle-fill"></i> Non 
                </div>
              </div>
            </div>
           </div>
      
        </div>
      </div>
    </div>
    <br>
    <div>
      <div  class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0"><i class="fa fa-sort-numeric-asc pr-1"></i> Liste des récurrences</h3>
        </div>
        <div class="card-body row">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                  <th scope="col">PRESTATION </th>
                  <th scope="col">DATE </th>
                  <th scope="col">TYPE DE JOUR</th>
                  <th scope="col">Planning</th>
                  <th scope="col">Statut</th>
                  </tr>
              </thead>
              <tbody *ngFor="let i of listOccurrences  | paginate
              : {
                  itemsPerPage: tableSize,
                  currentPage: page,
                  totalItems: count
                };let k =index"> 
                <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" >
                  <td>{{i.name}}</td>
                  <td *ngIf="!i.toUpdated" style="cursor: pointer;" (click)="updateDate(i)"><p title="Cliquer pour modifier">{{i.date|date:'dd/MM/yyyy'}}</p></td> 
                  <td *ngIf="i.toUpdated">
                    <div class="row">
                      <div class="col-8"> <input type="date" [(ngModel)]="dateConverted" class="form-control input-custom"></div>
                      <div class="col-1"> 
                        <button
                        class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i
                        style="font-size: 28px;"
                        class="bi bi-check"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        (click)="onSubmit()"
                       
                      ></i></button>
                    </div>
                    <div class="col-1"> 
                      <button
                      class="btn btn-sm btn-square btn-neutral text-danger-hover">
                      <i
                      style="font-size: 28px;"
                      class="bi bi-x"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      (click)="cancel(i)"
                     
                    ></i></button>
                  </div>
                    </div>
                  </td>   
                  <td *ngIf="i.type == 'default' " ><span class="badge badge-pill badge-success">Jour valide</span></td>
                  <td *ngIf="i.type == 'Weekend' "><span class="badge badge-danger">Weekend</span></td>
                  <td *ngIf="i.type == 'Holiday' "><span class="badge badge-danger">Jour ferié</span></td>
                  <td>
                  <button class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2">
                      <i
                        class="bi bi-calendar3"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Planning"
                        data-toggle="modal"
                        data-target="#exampleModalquiz"
                        (click)="getOcccurence(i)"

                      
                      ></i>
                    </button>
                  </td>
                  <td *ngIf="!i.planningConfirmationOcc">
                    <span class="aucun-text">Aucun</span>
                   </td>
                  <td *ngIf="i.planningConfirmationOcc && i.confirmationRequired"> 
                    <strong>Étape 1  <i style="color: #28A745;" class="bi bi-check-circle-fill"></i></strong><br>
                    <strong>Étape 2
                      <i *ngIf="i.emailSent" style="color: #28A745;" class="bi bi-check-circle-fill"></i>
                      <i *ngIf="!i.emailSent" style="color: #DC3545;" class="bi bi-x-circle-fill"></i>
                    </strong><br>
                    <strong>Étape 3
                      <i *ngIf="i.confirmationSendMail" style="color: #28A745;" class="bi bi-check-circle-fill"></i>
                      <i *ngIf="!i.confirmationSendMail" style="color: #DC3545;" class="bi bi-x-circle-fill"></i>
                    </strong>
                  </td>
                  <td *ngIf="i.planningConfirmationOcc && !i.confirmationRequired">
                   <p> Pas de confirmation nécessaire</p>
                  </td>

                </tr>
                <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;">
                  <td>{{i.name}}</td>
                  <!-- <td>{{i.date|date:'dd/MM/yyyy'}}</td> -->
                  <td *ngIf="!i.toUpdated" style="cursor: pointer;" (click)="updateDate(i)"><p title="Cliquer pour modifier">{{i.date|date:'dd/MM/yyyy'}}</p></td> 
                  <td *ngIf="i.toUpdated">
                    <div class="row">
                      <div class="col-8"> <input type="date" [(ngModel)]="dateConverted" class="form-control input-custom"></div>
                      <div class="col-1"> 
                        <button
                        class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i
                        style="font-size: 28px;"
                        class="bi bi-check"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        (click)="onSubmit()"
                       
                      ></i></button>
                    </div>
                    <div class="col-1"> 
                      <button
                      class="btn btn-sm btn-square btn-neutral text-danger-hover">
                      <i
                      style="font-size: 28px;"
                      class="bi bi-x"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      (click)="cancel(i)"
                     
                    ></i></button>
                  </div>
                    </div>
                  </td>   
                  <td *ngIf="i.type == 'default' " ><span class="badge badge-pill badge-success">Jour valide</span></td> 
                  <td *ngIf="i.type == 'Weekend' "><span class="badge badge-danger">Weekend</span></td>
                  <td *ngIf="i.type == 'Holiday' "><span class="badge badge-danger">Jour ferié</span></td>
                
                  <td>
                    <button class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2">
                      <i
                        class="bi bi-calendar3"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Planning"
                        data-toggle="modal"
                        data-target="#exampleModalquiz"
                        (click)="getOcccurence(i)"

                      
                      ></i>
                    </button>
                  </td>
                  <td *ngIf="!i.planningConfirmationOcc">
                    <span class="aucun-text">Aucun</span>
                   </td>
                   <td *ngIf="i.planningConfirmationOcc && i.confirmationRequired"> 
                    <strong>Étape 1  <i style="color: #28A745;" class="bi bi-check-circle-fill"></i></strong><br>
                    <strong>Étape 2
                      <i *ngIf="i.emailSent" style="color: #28A745;" class="bi bi-check-circle-fill"></i>
                      <i *ngIf="!i.emailSent" style="color: #DC3545;" class="bi bi-x-circle-fill"></i>
                    </strong><br>
                    <strong>Étape 3
                      <i *ngIf="i.confirmationSendMail" style="color: #28A745;" class="bi bi-check-circle-fill"></i>
                      <i *ngIf="!i.confirmationSendMail" style="color: #DC3545;" class="bi bi-x-circle-fill"></i>
                    </strong>
                  </td>
                  <td *ngIf="i.planningConfirmationOcc && !i.confirmationRequired">
                   <p> Pas de confirmation nécessaire</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="listOccurrences && listOccurrences.length > 10 " class="card-footer border-0 py-5">
              <div class="d-flex justify-content-start">
                <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
                  (pageChange)="onTableDataChange($event)">
                </pagination-controls>
              </div>
            </div>
          </div>
            <div
            *ngIf="listOccurrences && listOccurrences.length == 0"
            class="table-responsive"
            style="text-align: center"
            >
            <p style="padding-bottom: 45px; padding-top: 35px ">
              Aucune récurrence n'est trouvée
            </p>
            </div>
        
        </div>
      </div>

    </div>


    <!--Modal quiz-->

    <div

class="modal fade"
id="exampleModalquiz"
tabindex="-1"
role="dialog"
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>

<div class="modal-dialog" role="document"  [formGroup]="registerForm">
<div *ngIf="occurenceToUpdated" class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Confirmation du planning de la date {{occurenceToUpdated.date|date:'dd/MM/yyyy'}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Est-ce qu'il faut demander une confirmation ? <ui-switch  formControlName="confirmationRequired" size="small"></ui-switch></p>
  </div>
  <div *ngIf="registerForm.value.confirmationRequired"  class="modal-body">
    <p>Est-ce que le mail a été envoyé ? <ui-switch  formControlName="emailSent" size="small"></ui-switch></p>
  </div>
  <div *ngIf="registerForm.value.confirmationRequired && registerForm.value.emailSent" class="modal-body">
    <p>Est-ce que c'est confirmé ? <ui-switch  formControlName="confirmationSendMail" size="small"></ui-switch></p>
  </div>
  <div class="modal-footer">
   
    <button type="button" style="
    background: white!important;
    color: #0E309F!important;
    border: 1px solid #0E309F!important;
  " class="btn btn-secondary"  data-dismiss="modal">Annuler</button>
    <button type="button" class="btn btn-primary" (click)="onSubmitQuizReccrence()" data-dismiss="modal">Confirmer</button>
  </div>
</div>
</div>
</div>


 
   
  


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  //private _installerURL="http://localhost:3200/api/fitters" 
  private apiUrl = 'http://api.geonames.org';
  private _userURL=`${environment.baseUrl}/users`
  constructor(private http: HttpClient) { }


  stayConnected(email : string):Observable<User>
  {
    return this.http.get<User>(`${this._userURL}/stayConnected/${email}`);

  } 
 
     getUserrById(id : string):Observable<User>
      {
        return this.http.get<User>(`${this._userURL}/${id}`);
    
      } 
      
      EditUser(id:string,user:User):Observable<Object> {
        return this.http.put(`${this._userURL}/${id}`,user);
      }

      EditPasswordUser(user:User):Observable<Object> {
        return this.http.put(`${this._userURL}/updatePassowrd`,user);
      }

      disconnetUser(id : string):Observable<User>
      {
        return this.http.get<User>(`${this._userURL}/disconenct/${id}`);
    
      } 

      // list Admins
      getListAdmin() :Observable <any> {
        return this.http.get(`${this._userURL}/listsAdmin`);
        }

      // list all users (suppafacs :tony)
      getListUsers() :Observable <any> {
        return this.http.get(`${this._userURL}`);
        }  
   

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private _contactURL=`${environment.baseUrl}/contacts`
  constructor(private http: HttpClient) { }

     getContacts() :Observable <any> {
      return this.http.get(`${this._contactURL}`);
      }
      getContactsArchived() :Observable <any> {
        return this.http.get(`${this._contactURL}/list_contact/archived`);
        }

      getList_clients_subContractors() :Observable <any> {
        return this.http.get(`${this._contactURL}/clients-subContractors`);
        }
 
      createContact(user: any):Observable<Object> {
        return this.http.post(`${this._contactURL}`,user);
      }

      getContactById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._contactURL}/${id}`);
    
      } 
      
    //   deleteContact(id : string)
    //   {
    //     return this.http.delete(`${this._contactURL}/${id}`);
    //   } 

      
      EditContact(id:string,contact:any):Observable<Object> {
        return this.http.put(`${this._contactURL}/${id}`,contact);
      }
     
    archive_restoreContact(id :any){
      return this.http.get(`${this._contactURL}/archive-restoreContact/${id}`);
    }




    // 10-08-2023 // get contacts dont have companyClient and company subContractors
    getContactNotAffected(){
      return this.http.get(`${this._contactURL}/unaffected`);
     }

 

    
}

<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
  <ul class="breadcrumb float-md-right">
    <li class="breadcrumb-item"> 
      <a class="color-blue" style="cursor: default;" 
        ><i class="zmdi zmdi-home"></i> Accueil</a
      >
    </li>
    <li class="breadcrumb-item color-blue">
      <a class="color-blue" routerLink="../../">Ouvriers
      </a>
    </li>
    <li *ngIf="worker" class="breadcrumb-item active color-blue " style="cursor: default;">{{worker.lastName}} {{worker.firstName}}</li>
  </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" >
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <!-- <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails d'ouvrier</h1> -->
        </div>
      </div>
    </div>
  </div>
</header>
<br>
<!-- Student Profile -->
<div class="student-profile py-4">
  <div *ngIf="worker" class="container">
    <div class="col-sm-11 col-12 mb-4 mb-sm-0">
      <!-- Title -->
      <h1 class="h2 mb-0 ls-tight color-blue title-1" style="color: #62B9CB;">{{worker.lastName}} {{worker.firstName}} </h1>
      <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails de l'ouvrier</h1>
      <br>
    </div>
    <div class="h2 mb-0 ls-tight text-sm-end">
      <a (click)="goToUpdateWorker()" class="btn d-inline-flex btn-sm btn-primary mx-1">
        <span class="button_list" style="color: #fff">Modifier</span>
      </a>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="worker" class="card shadow-sm">
          <div class="card-header bg-transparent">
            <h3 class="mb-0"><i class="bi bi-info-circle-fill"></i> Informations générales</h3>
          </div>
          <div class="card-body row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Prénom :</strong>
              <p class="mb-3 title-info">
                <span class="aucun-text" *ngIf="!worker.lastName">Aucun</span>
                {{worker.lastName}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Nom :</strong>
              <p class="mb-3 title-info" >
                <span class="aucun-text" *ngIf="!worker.firstName">Aucun</span>
                {{worker.firstName}}</p>
            </div>
            <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Société :</strong>
              <p *ngIf="contact.companyClient" class="mb-0 title-info">{{contact.companyClient.companyName}}</p>
              <p *ngIf="contact.companySubContractor" class="mb-0 title-info">{{contact.companySubContractor.companyName}}</p>
            </div> -->
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Adresse mail :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!worker.email">Aucun</span>
                  {{worker.email}}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Téléphone :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!worker.phone">Aucun</span>
                  {{worker.phone}}</p>
  
              </div>
          </div>
          <div class="card-body row">
            <div class="col-12">
                <strong class="pr-1 title-info">Commentaire :</strong>
                <p class="mb-3 title-info"> <span class="aucun-text" *ngIf="!worker.comment">Aucun</span>
                  {{worker.comment}}</p>
            </div>
            
           </div>
        </div>
      </div>
     
    </div>

    <br>
     <div>
        <div class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="bi bi-briefcase-fill pr-1"></i> Liste des prestataires de {{worker.lastName}} {{worker.firstName}}</h3>
          </div>
          <div class="card-body row">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                    <th scope="col">Nom de la société </th>
                    <th scope="col">TVA </th>
                    <th scope="col">Personne de contact</th>
                    <th scope="col">Numéro de téléphone</th>
                    </tr>
                </thead>
                <tbody *ngFor="let i of worker.subContractor; let k =index">
                  <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" routerLink="/admin/prestataires/details/{{i._id}}">
                    <td>{{i.companyName }}</td>
                    <td>
                     <span class="aucun-text" *ngIf="!i.tva">Aucun</span>
                     {{i.tva}}
                   </td>
                    <td *ngIf="!i.contactMain"><span class="aucun-text">Aucun</span></td>
                    <td *ngIf="i.contactMain">{{i.contactMain.lastName}} {{i.contactMain.firstName}}</td>
                    <td>
                     <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                     {{i.phone}}
                   </td>
                  </tr>
                  <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;" routerLink="/admin/prestataires/details/{{i._id}}">
                    <td>{{i.companyName }}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.tva">Aucun</span>
                    {{i.tva}}
                  </td>
                   <td *ngIf="!i.contactMain"><span class="aucun-text">Aucun</span></td>
                   <td *ngIf="i.contactMain">{{i.contactMain.lastName}} {{i.contactMain.firstName}}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                    {{i.phone}}
                  </td>
                  </tr>
                 
              
                </tbody>
              </table>
            </div>
            <div
            *ngIf="worker && (worker.subContractor == null || worker.subContractor.length == 0)"
            class="table-responsive"
            style="text-align: center"
            >
            <p style="padding-bottom: 45px; padding-top: 35px ">
              Aucun prestataire n'est trouvé
            </p>
            </div>
          
          </div>
        </div>
  
      </div>
      <br>
      <div>
         <div class="card shadow-sm">
           <div class="card-header bg-transparent border-0">
             <h3 class="mb-0"><i class="bi bi-building pr-1"></i>Liste des chantiers de {{worker.lastName}} {{worker.firstName}}</h3>
           </div>
           <div class="card-body pt-0">
            <div class="table-responsive">
             <table class="table">
               <thead >
                 <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                   <th style="cursor: pointer;" (click)="sort('column2',decend,col2)" scope="col">N° chantier 
                     <i *ngIf="col2 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col2 == true" class="bi bi-chevron-up"></i>
                 </th>
                   <th style="cursor: pointer;" (click)="sort('column3',decend,col3)" scope="col">Nom 
                     <i *ngIf="col3 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col3 == true" class="bi bi-chevron-up"></i>
                 </th>
                   <th style="cursor: pointer;" (click)="sort('adress',decend,col4)" scope="col">Adresse 
                     <i *ngIf="col4 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col4 == true" class="bi bi-chevron-up"></i>
                 </th>
                   <th style="cursor: pointer;" (click)="sort('column6',decend,col6)"  scope="col">Statut 
                     <i *ngIf="col6 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col6 == true" class="bi bi-chevron-up"></i>
                 </th>
                 </tr>
               </thead>
               <tbody *ngFor="let i of sites   | sort: criteria  ; let k =index">
                 <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" routerLink="/admin/chantiers/details/{{i._id}}">
                   <td >{{i.column2}}</td>
                     <td >{{i.column3}}</td>
                     <td>
                       {{ getAddressParts(i.adress).street }}, {{ getAddressParts(i.adress).number }} <br>
                       {{ getAddressParts(i.adress).postalCode }} {{ getAddressParts(i.adress).city }}
                     </td>
                     <td *ngIf="i.column6 == 'STOP'"><span class="badge badge-danger">{{i.column6}}</span></td>
                     <td *ngIf="i.column6 == 'ACTIF'"><span class="badge badge-pill badge-success">{{i.column6}}</span></td>
                     <td  *ngIf="i.column6 == 'ONE SHOT'"><span class="badge badge-pill badge-info">{{i.column6}}</span></td>
                     <td  *ngIf="i.column6 == 'OFFRE'"><span class="badge badge-pill badge-orange">{{i.column6}}</span></td>
                  
                 </tr>
                 <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;" routerLink="/admin/chantiers/details/{{i._id}}">
                   <td >{{i.column2}}</td>
                   <td>{{i.column3}}</td>
                   <td >
                     {{ getAddressParts(i.adress).street }}, {{ getAddressParts(i.adress).number }} <br>
                     {{ getAddressParts(i.adress).postalCode }} {{ getAddressParts(i.adress).city }}
                   </td>
                   <td  *ngIf="i.column6 == 'STOP'"><span class="badge badge-danger">{{i.column6}}</span></td>
                   <td  *ngIf="i.column6 == 'ACTIF'"><span class="badge badge-pill badge-success">{{i.column6}}</span></td>
                   <td  *ngIf="i.column6 == 'ONE SHOT'"><span class="badge badge-pill badge-info">{{i.column6}}</span></td>
                   <td *ngIf="i.column6 == 'OFFRE'"><span class="badge badge-pill badge-orange">{{i.column6}}</span></td>
                 
                 </tr>
                
             
               </tbody>
            </table>
            </div>
                <div
                *ngIf="sites && sites.length == 0"
                class="table-responsive"
                style="text-align: center"
                >
                <p style="padding-bottom: 45px; padding-top: 35px ">
                  Aucun chantier n'est trouvé
                </p>
                </div>
              </div>
         </div>
       </div>
  </div>
</div>


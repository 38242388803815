<div class="modal-header">
    <h4 class="modal-title">Créer un chantier</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
    <div>
        <form
        [formGroup]="registerForm"
        (ngSubmit)="onSubmit()"
        class="margin-right-50"
      >
        <!-- <div class="form-row">
          <div class="form-group col-4">
              <label>Genre</label>
              <ng-select [items]="gendres"
              bindLabel="name"
              class="form-control input-custom"
              [closeOnSelect]="true"
              >
         </ng-select>
              
            </div>
          <div class="form-group col-4">
              <label></label>
              <input type="text" class="form-control input-custom"  />
            
          </div>
          <div class="form-group col-4">
              <label>Prénom</label>
              <input type="text"  class="form-control input-custom" />
          </div>
      </div> -->
        <div class="form-row">
          <div class="form-group col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label>Etablissement</label>
            <ng-select
              [items]="listestablishment"
              bindLabel="name"
              formControlName="establishment"
              class="form-control input-custom"
              [closeOnSelect]="true"
            >
            </ng-select>
          </div>
          <div class="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Nom du chantier</label>
            <input
              type="text"
              formControlName="name"
              class="form-control input-custom"
            />
          </div>
  
          <div class="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <label> Numéro</label>
            <input
              type="text"
              formControlName="siteNumber"
              class="form-control input-custom"
            />
          </div>
        </div>
         <div class="form-row">
    <!-- <div class="form-group row mb-4"> -->
      <div class="form-group col-12" formArrayName="adress">
          <label>Adresse
            <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addQuantity()"></i>
          </label>
          <div *ngFor="let quantity of adress().controls; let i=index" class="row" [formGroupName]="i">
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 margin-custom padding-custom" >
              <label *ngIf="i > 0">
              <i style="cursor: pointer;" class="bi bi-trash" (click)="removeQuantity(i)"></i>
              </label>  
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11">
             <label>Rue</label>
                <input type="text" formControlName="street" class="form-control">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label>Numéro</label>  
                <input type="number" formControlName="number" class="form-control">
              </div>
  
                <br>
        </div>
  
  </div>
  </div>
        <!-- <div class="form-row">
          <div class="form-group col">
            <label>Rue</label>
            <input
              type="text"
              formControlName="street"
              class="form-control input-custom"
            />
          </div>
          <div class="form-group col">
            <label>Numéro</label>
            <input
              type="text"
              formControlName="number"
              class="form-control input-custom"
            />
          </div>
        </div> -->
  
        <div class="form-row">
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Code postal</label>
            <ng-select
              [items]="codes_postales"
              bindLabel="postal_code"
              formControlName="postalCode"
              class="form-control input-custom"
              [closeOnSelect]="true"
              (change)="getCity()"
            >
            </ng-select>
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Ville</label>
            <input
              disabled
              type="text"
              formControlName="city"
              [(ngModel)]="this.city_codePostal"
              class="form-control input-custom"
            />
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Étage du chantier </label>
            <input
              type="text"
              formControlName="siteFloor"
              class="form-control input-custom"
            />
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Responsable</label>
            <ng-select
              [items]="listSiteManager"
              bindLabel="name"
              formControlName="siteManager"
              class="form-control input-custom"
              [closeOnSelect]="true"
            >
            </ng-select>
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label
              >Client
              <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addClient()"></i>
            </label>
            <ng-select
              [items]="clients"
              bindLabel="name"
              formControlName="client"
              class="form-control input-custom"
              [closeOnSelect]="true"
            >
            </ng-select>
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label
              >Contact(s)
              <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addContact()"></i>
            </label>
            <ng-select
              [items]="contact"
              bindLabel="name"
              formControlName="contact"
              class="form-control input-custom"
              [closeOnSelect]="true"
              multiple="true"
            >
            </ng-select>
          </div>
        </div>
  
        <div class="form-row">
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Date de début </label>
            <input
              type="date"
              formControlName="startdate"
              class="form-control input-custom"
            />
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label
              >Prestation(s)
              <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addBenefit()" ></i>
            </label>
            <ng-select
              [items]="services"
              bindLabel="name"
              formControlName="service"
              class="form-control input-custom"
              [closeOnSelect]="true"
              (change)="setListNamesServices()"
              multiple="true"
            >
            </ng-select>
          </div>
        </div>
  
        <div *ngFor="let service of this.listNamesServices">
          <br />
          <p style="color: #0e309f; font-weight: bold">{{ service.name }}</p>
          <hr style="color: #0e309f" />
          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <label>Fréquence </label>
              <ng-select
                [items]="frequences"
                (change)="getFreguence($event, service.name)"
                class="form-control input-custom"
                [closeOnSelect]="true"
              >
              </ng-select>
            </div>
            <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"  *ngIf="service.frequence == 'hebdomadaire'"
            style="padding-top: 2%">
              <div>
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="myCheckbox1"
                        />
                        <label class="form-check-label" for="myCheckbox1">
                          <span class="checkbox-label">Lundi</span>
                          <span class="checkbox-box"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="myCheckbox2"
                        />
                        <label class="form-check-label" for="myCheckbox2">
                          <span class="checkbox-label">Mardi</span>
                          <span class="checkbox-box"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="myCheckbox3"
                        />
                        <label class="form-check-label" for="myCheckbox3">
                          <span class="checkbox-label">Mercredi</span>
                          <span class="checkbox-box"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="myCheckbox4"
                        />
                        <label class="form-check-label" for="myCheckbox4">
                          <span class="checkbox-label">Jeudi</span>
                          <span class="checkbox-box"></span>
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="myCheckbox5"
                        />
                        <label class="form-check-label" for="myCheckbox5">
                          <span class="checkbox-label">Vendredi</span>
                          <span class="checkbox-box"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
  
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" *ngIf="
            service.frequence != 'hebdomadaire' &&
            service.frequence != 'mensuelle' &&
            service.frequence != ''
          ">
              <div >
                <label style="color: white">nodispaly</label>
                <ng-select
                  [items]="listMonths"
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  multiple="true"
                >
                </ng-select>
              </div>
              </div>
          </div>
  
          <div class="form-row">
            <div class="form-group col">
              <label>Note </label>
              <textarea class="form-control input-custom"> </textarea>
            </div>
          </div>
  
          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <label>Durée de la prestation</label>
              <input class="form-control input-custom" />
            </div>
            <div class="form-group col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <label
                >Prestataire
               
                <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addSubContractor()"></i>
              </label>
              <ng-select
                [items]="ServiceProvider"
                bindLabel="name"
                (change)="recuperatePrestataire($event , service.name)"
                class="form-control input-custom"
                [closeOnSelect]="true"
              >
              </ng-select>
              <!-- <input type="radio" name="option" value="yes" >   
                <input type="radio" name="option" value="no" >              -->
            </div>
            </div>
           
             <div style="background-color: #f5f9fc;padding: 20px;" *ngIf="service.ServiceProvider.name != '' ">
              <p style="color: #0e309f; font-weight: bold">{{ service.ServiceProvider.name }} :</p>
              <br>
            <div  class="form-row">
              <div class="form-group col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Date de début </label>
                <input type="date" class="form-control input-custom" />
              </div>
              <div class="form-group col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Date de fin </label>
                <input type="date" class="form-control input-custom" />
              </div>
              <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Contart SST signé</label>
                <br>
                <ui-switch
                size="small"
              >
              </ui-switch>
              </div>
              
          </div>
        </div>
  
          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Déclaration de chantier obligatoire</label>
              <br />
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <input
                    (change)="
                      getEtatSiteDeclaration($event.target.value, service.name)
                    "
                    type="radio"
                    name="option{{ service.name }}"
                    value="1"
                  />
                  Oui
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <input
                    (change)="
                      getEtatSiteDeclaration($event.target.value, service.name)
                    "
                    type="radio"
                    name="option{{ service.name }}"
                    value="0"
                  />
                  Non
                </div>
              </div>
            </div>
            <div *ngIf="service.siteDeclaration == true" class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Déclaration faite</label>
              <br />
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <input type="radio" name="optionfaite{{ service.name }}" 
                  (change)=" getStatutDeclarationDone($event.target.value, service.name)"
                  value="1" /> Oui
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <input type="radio" name="optionfaite{{ service.name }}" 
                  (change)=" getStatutDeclarationDone($event.target.value, service.name)"
                  value="0" /> Non
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="service.declarationDone == true" class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Date de début de déclaration </label>
              <input
                type="date"
                class="form-control input-custom"
              />
            </div>
  
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Date de fin de déclaration </label>
              <input
                type="date"
                class="form-control input-custom"
              />
            </div>
  
            </div>
  
          <div class="form-row">
            <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <label>Fréquence de facturation </label>
              <ng-select
                [items]="billingFrequency"
                class="form-control input-custom"
                [closeOnSelect]="true"
                formControlName="statut"
              >
              </ng-select>
            </div>
          </div>
  
          <div class="form-row">
            <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <label>Ouvrier(s) 
                <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addWorker()"></i>
              </label>
              <ng-select
                [items]="workers"
                bindLabel="name"
                (change)="setListNamesWorkers($event,service.name)"
                class="form-control input-custom"
                [closeOnSelect]="true"
                multiple =true
                
              >
              </ng-select>
            </div>
          </div>
          <div *ngFor="let worker of service.workers ;let k =index" style="background-color: #f5f9fc;padding: 20px;" >
            
            <p style="color: #0e309f; font-weight: bold">{{ worker[k].name }} :</p> <br>
            <div class="form-row">
              <div class="form-group col">
                <label>Date de début</label>
                <input
                  type="date"
                  class="form-control input-custom"
                />
              </div>
              <div class="form-group col">
                <label>Date de fin</label>
                <input
                  type="date"
                  class="form-control input-custom"
                />
              </div>
            </div>
        
          </div>
  
          
        </div>
  
        <div class="form-group row" class="center">
          <label>Contrat </label>
          <ngx-file-drop
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)"
            (onFileOver)="fileOver($event)"
            (onFileLeave)="fileLeave($event)"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              <p style="color: #636363">Déposer un fichier ici</p>
  
              <a (click)="openFileSelector()"
                ><i class="bi bi-file-earmark-arrow-down i-file"></i>
              </a>
            </ng-template>
          </ngx-file-drop>
          <div class="upload-table">
            <table class="table">
              <thead>
                <tr>
                  <th>Fichier :</th>
                </tr>
              </thead>
              <!-- <h2>fff  {{listFiles | json }}</h2> -->
              <tbody class="upload-name-style">
                <tr *ngFor="let item of files; let i = index">
                  <td>
                    <strong>{{ item.relativePath }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br>
        <div class="form-row">
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Statut </label>
            <ng-select
              [items]="statuts"
              class="form-control input-custom"
              [closeOnSelect]="true"
            >
            </ng-select>
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Archivé</label>
            <br>
            <ui-switch
            formControlName="archive"
            size="small"
          >
          </ui-switch>
          </div>
        </div>
  
        <div style="padding-top: 3%" class="text-center">
          <button
           (click)="onReset()"
            class="btn btn-secondary mr-4 button_list mb-2"
            type="reset"
          >
            Annuler
          </button>
          <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
        </div>
      </form>

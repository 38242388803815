<div class="modal-header">
    <h4 class="modal-title">Créer un Prestataire</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
    <div>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
            
             
            <!-- <div class="form-row">
              <div class="form-group col-4">
                  <label>Genre</label>
                  <ng-select [items]="gendres"
                  bindLabel="name"
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  >
             </ng-select>
                  
                </div>
              <div class="form-group col-4">
                  <label></label>
                  <input type="text" class="form-control input-custom"  />
                
              </div>
              <div class="form-group col-4">
                  <label>Prénom</label>
                  <input type="text"  class="form-control input-custom" />
              </div>
          </div> -->
          <div class="form-row">
            <div class="form-group col">
                <label>Nom de la société</label>
                <input type="text" formControlName="companyName" class="form-control input-custom" 
                [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
                <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                    <div *ngIf="f.companyName.errors.required">Champ obligatoire.</div>
                </div>
             
            </div>
      
            <div class="form-group col">
              <label>TVA</label>
              <input type="text" formControlName="tva"  class="form-control input-custom"  />
            
          </div>
        </div>
      
        <div class="form-row">
          <div class="form-group col">
              <label>Adresse</label>
              <input type="text" formControlName="address" class="form-control input-custom"  />
           
          </div>
          <div class="form-group col">
            <label>Numéro</label>
            <input type="text" formControlName="number"  class="form-control input-custom"  />
          
        </div>
      </div>
      
      <div class="form-row">
        <div class="form-group col">
            <label>Code postal</label>
            <ng-select [items]="codes_postales"
                bindLabel="postal_code"
                formControlName="postalCode"
                class="form-control input-custom"
                [closeOnSelect]="true"
                (change)="getCity()"
                >
                </ng-select>
         
        </div>
        <div class="form-group col">
          <label>Ville</label>
          <input disabled type="text"  formControlName="city" [(ngModel)]="this.city_codePostal" class="form-control input-custom"  />
        
      </div>
      </div>
      
          <div class="form-row">
            <div class="form-group col">
              <label>Contact</label>
              <ng-select [items]="contact"
                    bindLabel="name"
                    formControlName="contact"
                    class="form-control input-custom"
                    [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"
                    >
                    </ng-select>
                    <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
                      <div *ngIf="f.contact.errors.required">Champ obligatoire.</div>
                    </div>
      
           
          </div>
            <div class="form-group col">
                <label>Adresse mail</label>
                <input type="text" formControlName="email" class="form-control input-custom" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
                    <div *ngIf="f.email.errors.email">Merci de saisir une adresse mail valide.</div>
                </div>
            
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col">
              <label>Numéro de téléphone</label>
              <input type="text" formControlName="phone" class="form-control input-custom"  />
           
          </div>
          <div class="form-group col">
            <label>Ouvriers</label>
            <ng-select [items]="workers"
            formControlName="worker"
            bindLabel="name"
            multiple =true
            class="form-control input-custom"
            [closeOnSelect]="true"
            >
            </ng-select>
         
        </div>
          </div>
          <div class="form-row">
         
            <div class="form-group col-6">
              <label>Taux horaire</label>
              <input type="text"  formControlName="tauxTime" class="form-control input-custom"  />
           
          </div>
          <!-- <div class="form-group col">
            <label>Bon de commande</label>
            <input type="text" class="form-control input-custom"  />
         
        </div> -->
            </div>
          
      
            <div class="form-group row" class="center">
              <label>Documents</label>
              <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
              (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <p style="color: #636363;">Déposer un fichier ici </p> 
                    
                    <a (click)="openFileSelector()"><i class="bi bi-file-earmark-arrow-down i-file"></i>
                    </a>
                  </ng-template>
              </ngx-file-drop>
              <div class="upload-table">
                  <table class="table">
                      <thead>
                          <tr>
                              <th>Fichier :</th>
                          </tr>
                      </thead>
                      <!-- <h2>fff  {{listFiles | json }}</h2> -->
                      <tbody class="upload-name-style">
                          <tr *ngFor="let item of files; let i=index">
                              <td><strong>{{ item.relativePath}}</strong></td>
                          </tr>
                      </tbody>
                  </table>
              </div>
            </div>
      
       
       
             
              <div style="padding-top: 3%;" class="text-center">
                  
                  <button (click)="onReset()" class="btn btn-secondary mr-4 button_list" type="reset">Annuler</button>
                  <button class="btn btn-primary button_list">Sauvegarder</button>
              </div>
          </form>
          </div>
          </div>



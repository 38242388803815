import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Ng2IzitoastService } from 'ng2-izitoast';
import codes from 'src/app/BE_Postal_codes.json';
import contries from 'src/app/List_countries.json';

import { ClientService } from 'src/app/_services/client.service';
import { ContactService } from 'src/app/_services/contact.service';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventServiceService } from 'src/app/_services/event-service.service';


@Component({
  selector: 'app-popup-add-client',
  templateUrl: './popup-add-client.component.html',
  styleUrls: ['./popup-add-client.component.css']
})
export class PopupAddClientComponent implements OnInit {

  postalCode: string ="1099";
  city :any 
  registerForm: FormGroup;
  submitted = false;
  city_codePostal : any = ""
  autoliquidationVerif = false
  contacts :any
  listContactsByThisClient :any
  etablissements :any
  etab
  etablissementRecovered
  worksite = [
    {  name: 'Chantier 1' },
    {  name: 'Chantier 2' },
    {  name: 'Chantier 3' },
   
    
  ]
  codes_postales = codes 
  list_contries = contries

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private clientService :ClientService,
    private contactService :ContactService,
    private etablissementService :EtablissementService,
    public iziToast: Ng2IzitoastService,
    private config: NgSelectConfig, 
    public modal: NgbActiveModal,
    private eventService: EventServiceService,
    private etbalissementService :EtablissementService
    ) { 
      this.config.notFoundText = "Aucun élement trouvé"
    }


    ngOnInit() {
      let city = codes.find(el => el.postal_code === "2060");
      this.registerForm = this.formBuilder.group({
          companyName: ['', Validators.required],
          tva: ['', ],
          autoliquidation: ['', ],
          country: ['', ],
          street : ['', ],
          number : ['', ],
          postalCode : ['', ],
          city : ['', ],
          contact: ['', ],
          constructionSite: [''],
          email:['', Validators.email],
          contactMain: ['',], // principale
          etablissement : ['', ],
      },);
      this.getContactsNotAffected()
      this.getEtablissement()
      if(this.etab)
      this.getEtablissementByIdRecuperateFromfromAddSite(this.etab)
  }
  
  
  getContactsNotAffected() {
    this.contactService.getContacts().subscribe((e) => {
      this.contacts = e;
    });
  }
  
  getEtablissement() {
    this.etablissementService.getEtablissements().subscribe((e) => {
      this.etablissements = e;
    });
  }

  // recuper l'id de etablissement si s'agit de le fromulaire d'ajout d 'un chantier 
  getEtablissementByIdRecuperateFromfromAddSite(id){
    this.etbalissementService.getEtablissementById(id).subscribe((e) => {
      this.etablissementRecovered = e;
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }

  // display form values on success
  //this.registerForm.value.autoliquidation = this.autoliquidationVerif
  if(this.registerForm.value.country === "Belgique" && this.registerForm.value.postalCode != "" ){
    this.registerForm.value.postalCode = this.registerForm.value.postalCode["postal_code"]
  }
  if(this.registerForm.value.autoliquidation == ""){
    this.registerForm.value.autoliquidation = false
  }

  const exists = this.registerForm.value.contact.includes(this.registerForm.value.contactMain); 
  
  if(this.registerForm.value.contactMain && !exists ){
    let msg = "Le contact principal choisi n'existe pas dans la liste des contacts de ce client."
    this.iziToast.show({
      message: msg,
      messageColor:'#800f2f',
      titleColor:'#800f2f',
      progressBarColor:'#c9184a',
      icon:'bi-exclamation-diamond',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay:true,
      overlayClose:true,
      
    });
    this.registerForm.patchValue({
      contactMain: '' // Update contactMain to an empty string
    });
    return;
  }

  //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    //this.router.navigate(['/admin/clients' ])
    if(this.etab){
      this.registerForm.value.etablissement = [this.etab]
    }
    this.clientService.createClient(this.registerForm.value).subscribe(
      (data) => {
       
        
        // this.iziToast.show({
        //   message:'Ajout avec succés',
        //   messageColor:'#386641',
        //   progressBarColor:'#6a994e',
        //   icon:'bi-check-circle',
        //   imageWidth:45,
        //   position:'topRight',
        //   timeout:5000,
        //   backgroundColor:'#dde5b6',
        //   transitionIn: 'flipInX',
        //   transitionOut: 'flipOutX',
        //    });
        //    this.router.navigate(['/admin/clients' ])
        localStorage.setItem('clientAdedInEtablissement',this.registerForm.value.companyName.toString())
        this.eventService.launchEvent.emit();
        this.modal.dismiss();
          })
}
onReset() {
  this.modal.dismiss();

}

getCity()
{
  if(this.registerForm.value.postalCode != null)
  {
  this.city = codes.find(el => el.postal_code === this.registerForm.value.postalCode.postal_code);
      this.registerForm.value.city = this.city
      this.city_codePostal = this.city["place_name"];
  }
  else{
    this.city_codePostal = ""
  }

}

// changeAutoliquidationStatuts()
// {
//   if(this.autoliquidationVerif == false)
//   {
//     this.autoliquidationVerif = true
//   }
//   else
//   {
//     this.autoliquidationVerif = false
//   }
// }
getContactByThisClient(){
  this.listContactsByThisClient =this.contacts.filter(item => this.registerForm.value.contact.includes(item._id));
}


}

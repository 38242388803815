import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import codes from 'src/app/BE_Postal_codes.json';
@Component({
  selector: 'app-popup-add-sub-contractor',
  templateUrl: './popup-add-sub-contractor.component.html',
  styleUrls: ['./popup-add-sub-contractor.component.css']
})
export class PopupAddSubContractorComponent implements OnInit {

  public files: NgxFileDropEntry[] = [];
  registerForm: FormGroup;
  submitted = false;
 
  codes_postales = codes 
  contact = [
    {  name: 'Exemple 1' },
    {  name: 'Exemple 2' },
    {  name: 'Exemple 3' },
    {  name: 'Exemple 4' },
  ]
  workers = [
    {  name: 'Ouvrier 1' },
    {  name: 'Ouvrier 2' },
    {  name: 'Ouvrier 3' },
   
  ]
  city: { country_code: string; postal_code: string; place_name: string; admin_name1: string; admin_code1: string; admin_name2: string; admin_code2: string; admin_name3: string; admin_code3: string; latitude: number; longitude: number; accuracy: number; coordinates: { lon: number; lat: number; }; };
  city_codePostal: string;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public modal: NgbActiveModal) { }


  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      tva: ['', ],
      address : ['', ],
      number : ['', ],
      postalCode : ['', ],
      city : ['', ],
      contact: ['', [Validators.required]],
      //constructionSite: [''],
      email:['', Validators.email],
      phone :['', ],
      worker :['', ],
      tauxTime : ['',],
      order :['',],
      key :['',]
      

      // acceptTerms: [false, Validators.requiredTrue]
  },);

}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

// onSubmit() {
//     this.submitted = true;

//     // stop here if form is invalid
//     if (this.registerForm.invalid) {
//         return;
//     }

//     // display form values on success
//     this.router.navigate(['/admin/prestataires' ])

// }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }

  // display form values on success
  //
  
  
  //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
 
  this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}

public dropped(files: NgxFileDropEntry[]) {
  this.files = files;
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file

        /**
        // You could upload it like this:
        const formData = new FormData()
        formData.append('logo', file, relativePath)

        // Headers
        const headers = new HttpHeaders({
          'security-token': 'mytoken'
        })

        this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
        .subscribe(data => {
          // Sanitized logo returned from backend
        })
        **/

      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
}

public fileOver(event){
}

public fileLeave(event){
}


getCity()
{
  if(this.registerForm.value.postalCode != null)
  {
  this.city = codes.find(el => el.postal_code === this.registerForm.value.postalCode.postal_code);
// => {name: "Albania", code: "AL"}
      this.registerForm.value.city = this.city
      this.city_codePostal = this.city["place_name"];
      this.registerForm.value.postalCode = this.city["postal_code"]
  }
  else{
    this.city_codePostal = ""
  }

}


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { BenefitService } from 'src/app/_services/benefit.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-sub-contractor',
  templateUrl: './details-sub-contractor.component.html',
  styleUrls: ['./details-sub-contractor.component.css','../../../../_shared/list.css']
})
export class DetailsSubContractorComponent implements OnInit {

      id
      sub_contrator
      contacts
      workers
      listFiles
      sites
      criteria: SortCriteria;
      decend = false
      col1 = false
      col2 = false
      col3 = false
      col4 = false
      col5 = false
      col6 = false
      BaseUrl_subContractor = environment.baseUrl.split('/api')[0] + '/media/sub_contrator/';
      constructor(
        private subContractorService :SubContractorService,
        private route :ActivatedRoute,
        private router :Router,
        private benefitService :BenefitService

        ) { }


      ngOnInit() {
        this.id = this.route.snapshot.params["id"];
          this.subContractorService.getSubContratcorById(this.id).subscribe(data => {
            this.sub_contrator = data;
                })
            this.getContactsBy_sub_contrator()
            this.getWorkersBy_sub_contrator()    
            this.getlistDocumentsSub_contractor()   
            this.getList_Sites_By_sub_contrator()
                    }     
         
     getContactsBy_sub_contrator(){
       this.subContractorService.getContactsBySub_contractor(this.id).subscribe(data => {
           this.contacts = data;              
             })              
             }               
     getWorkersBy_sub_contrator(){
       this.subContractorService.getWorkersBySub_contractor(this.id).subscribe(data => {
        this.workers = data;              
         })
         }
         getlistDocumentsSub_contractor(){
          this.subContractorService.listNamesFiles(this.id).subscribe(data => {
            this.listFiles = data;
          })
        }

        getList_Sites_By_sub_contrator(){
          this.benefitService.getList_Sites_by_subContractor(this.id).subscribe(data => {
           this.sites = data;       
            })
            }

         goToDetailsContact(id){
          this.router.navigate(["admin/contacts/details", id]);
      
        }

        goToDetailsWorker(id){
          this.router.navigate(["admin/ouvriers/details", id]);
      
        }
        openFileInNewWindow(key: string,filename) {
          let url :any
        
          url =this.BaseUrl_subContractor+key+"/"+filename
          
         
          window.open(url, '_blank');
        }

        goToUpdateSubContractor(){
          this.router.navigate(["admin/prestataires/modification", this.id]);
        }

        sort(column,decend,col)
        {
         
        
        switch (column) {
         
         case "column1":
           if(this.col1 == false){
           this.col1 = true
           this.col2 = false
           this.col3 = false
           this.col4 = false
           this.col5 = false
           this.col6 = false
           }
           else
           {
             this.col1 = false
             this.col2 = false
             this.col3 = false
             this.col4 = false
             this.col5 = false
             this.col6 = false
           }
           break
         case "column2":  
         if(this.col2 == false){
           this.col1 = false
           this.col2 = true
           this.col3 = false
           this.col4 = false
           this.col5 = false
           this.col6 = false
         }
         else
           {
             this.col1 = false
             this.col2 = false
             this.col3 = false
             this.col4 = false
             this.col5 = false
             this.col6 = false
           }
           break
         case "column3":
           if(this.col3 == false){  
           this.col1 = false
           this.col2 = false
           this.col3 = true
           this.col4 = false
           this.col5 = false
           this.col6 = false  
           }
           else
           {
             this.col1 = false
             this.col2 = false
             this.col3 = false
             this.col4 = false
             this.col5 = false
             this.col6 = false
           }
           break
         case "adress":   
         if(this.col4 == false){   
           this.col1 = false
           this.col2 = false
           this.col3 = false
           this.col4 = true
           this.col5 = false
           this.col6 = false  
         }
         else
           {
             this.col1 = false
             this.col2 = false
             this.col3 = false
             this.col4 = false
             this.col5 = false
             this.col6 = false
           }
           break
         case "column5":  
         if(this.col5 == false){   
           this.col1 = false
           this.col2 = false
           this.col3 = false
           this.col4 = false
           this.col5 = true
           this.col6 = false
         }
         else
           {
             this.col1 = false
             this.col2 = false
             this.col3 = false
             this.col4 = false
             this.col5 = false
             this.col6 = false
           }
           break
         case "column6":
           if(this.col6 == false){   
           this.col1 = false
           this.col2 = false
           this.col3 = false
           this.col4 = false
           this.col5 = false
           this.col6 = true
           }
           else
           {
             this.col1 = false
             this.col2 = false
             this.col3 = false
             this.col4 = false
             this.col5 = false
             this.col6 = false
           }
           break
         default :
             this.col1 = false
             this.col2 = false
             this.col3 = false
             this.col4 = false
             this.col5 = false
             this.col6 = false   
             break   
        
        }
        
         if(column == "column6"){
         if(decend == false){
         this.criteria = {
           property: column ,
           descending: decend
         };
         this.decend = true
         }
         else{
           this.criteria = {
             property: column ,
             descending: decend
           };
           this.decend = false
         }
        }
        else
        {
        
           if(decend == false){
           this.criteria = {
             property: column ,
             descending: true
           };
           this.decend = true
           }
           else{
             this.criteria = {
               property: column ,
               descending: false
             };
             this.decend = false
           }
        
        }
        }
        getAddressParts(address) {
          const parts = address.split('/n');
          const street = parts.slice(0, parts.length - 3).join('/n');
          const number = parts[parts.length - 3];
          const postalCode = parts[parts.length - 2];
          const city = parts[parts.length - 1];
          return { street, number, postalCode, city };
        }

    }


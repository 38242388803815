import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Ng2IzitoastService } from "ng2-izitoast";
import { EtablissementService } from "src/app/_services/etablissement.service";
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { SortCriteria } from "src/app/_helpers/sort.pipe";
@Component({
  selector: "app-list-etablissement",
  templateUrl: "./list-etablissement.component.html",
  styleUrls: ['../../../_shared/list.css','./list-etablissement.component.css'],
})
export class ListEtablissementComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  etablissements: any;
  siteArchived;
  copyListEtablissemets;
  listManagers: any;
  listCities: any;
  ResponsableToFiltred: any;
  citiesToFiltred: any;
  etablissmentNameToFiltred: any;
  etablissmentAdressToFiltred: any;
  constructor(
    private router: Router,
    private etbalissementService: EtablissementService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getEtablissements();
    localStorage.removeItem("lastPageContactVisited")
    localStorage.removeItem("lastPageClientVisited")
    localStorage.removeItem("lastPageBenefitVisited")
    localStorage.removeItem("lastPageDocumentVisited")
    localStorage.removeItem("lastPageSiteVisited")
    localStorage.removeItem("lastPageWorkerVisited")
    localStorage.removeItem("lastPageSubContractorVisited")
    localStorage.removeItem("lastPageSuppafacVisited")
    localStorage.removeItem("lastPageGlassVisited")




    if(localStorage.getItem("lastPageEtablissementVisited") && 
    localStorage.getItem("lastPageEtablissementVisited") != undefined && 
    localStorage.getItem("lastPageEtablissementVisited") != "")
   this.page = Number(localStorage.getItem("lastPageEtablissementVisited"))
  
  }

  getEtablissements() {
    this.etbalissementService.getEtablissements().subscribe((e) => {
      this.etablissements = e;
      this.copyListEtablissemets = this.etablissements;
      this.listManagers = Array.from(new Set(this.etablissements.map(item => item.manager)));
      this.listCities =  Array.from(new Set(this.etablissements.map(item => item.city)));
    });
  }

  create() {
    this.router.navigate(["admin/etablissements/ajout"]);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.etablissements = this.etablissements;
    localStorage.setItem("lastPageEtablissementVisited",this.page.toString())
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.etablissements;
  }

  getEtablissment(site) {
    this.siteArchived = site;
  }

  archiveEtablissment(site: any) {
    site = this.siteArchived;
    let index = this.etablissements.findIndex(etab => etab._id === site._id);
    let lastDigit = index % 10;
     if (lastDigit === 0) {
      this.page--;
      localStorage.setItem("lastPageEtablissementVisited",this.page.toString())
    }
    this.etbalissementService
      .archive_restoreEtablissements(site._id)
      .subscribe((e) => {
        this.iziToast.show({
          message: "Archivage avec succès",
          messageColor: "#343A40",
          progressBarColor: "#212529",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#CED4DA",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        // this.getEtablissements();
        // this.page = 1
        this.router.navigate(["admin/etablissements/archives"]);

      });
  }

  details(id) {
    const url = `admin/etablissements/details/${id}`;
    window.open(url, '_blank');
  }
  update(id) {
    this.router.navigate(["admin/etablissements/modification", id]);
  }
  filterByResponsable(event) {
    this.ResponsableToFiltred = event
  }
  filterByCities(event) {
    this.citiesToFiltred = event
  }
  filter() {
    this.etablissements = this.copyListEtablissemets;
    let filteredEtablissemets = [...this.copyListEtablissemets];
    // Convert filter criteria to lowercase
    const etablissementNameFilterLower = this.etablissmentNameToFiltred ? this.etablissmentNameToFiltred.toLowerCase() : '';
    const etablissemenAdressFilterLower = this.etablissmentAdressToFiltred ? this.etablissmentAdressToFiltred.toLowerCase() : '';
    const maangerFilterLower = this.ResponsableToFiltred ? this.ResponsableToFiltred.toLowerCase() : '';
    const citiesilterLower = this.citiesToFiltred ? this.citiesToFiltred.toLowerCase() : '';
    // if (etablissementNameFilterLower || etablissemenAdressFilterLower || maangerFilterLower || citiesilterLower) {
    //   this.spinnerService.show();
  
    //   setTimeout(() => {
    //     this.spinnerService.hide();
    //   }, 1000); // 1 second
    // }
    filteredEtablissemets = this.etablissements.filter(item => {
      const maangerFilter = maangerFilterLower ? item.manager.toLowerCase() === maangerFilterLower : true;
      const citiesFilter = citiesilterLower ? item.city.toLowerCase() === citiesilterLower : true;
      
  
      // Case-insensitive name search for a single character
      const nameFilter = etablissementNameFilterLower ? item.name.toLowerCase().includes(etablissementNameFilterLower) : true;
      const aderssFilter = etablissemenAdressFilterLower ? item.adress.toLowerCase().includes(etablissemenAdressFilterLower) : true;
  
      return nameFilter && aderssFilter && maangerFilter && citiesFilter ;
    });
    this.etablissements = filteredEtablissemets;

    // Reset pagination
    this.page = 1;
  }

  resetFilters() {
    this.etablissmentNameToFiltred = ""; 
    this.etablissmentAdressToFiltred = ""; 
    this.ResponsableToFiltred = undefined; 
    this.citiesToFiltred = undefined;
    
    this.filter();
 }
 exportToExcel(etablissements: any[]): void {
  const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
  
  const data = etablissements.map(etablissement => {
       // Remplacer les occurrences de "/n" par un espace dans l'adresse
       const formattedAddress = etablissement.adress.replace(/\/n/g, ' ');
    return {
      'NOM': etablissement.name,
      'ADRESSE': formattedAddress,
      'RESPONSABLE': etablissement.manager,
    };
  });
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const columnWidths = [
    { wpx: 120 }, 
    { wpx: 220 }, 
    { wpx: 100 }, 
  ];

  // Apply column widths
  ws['!cols'] = columnWidths;
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Établissements');
  const filename = `${currentDate}_etablissements.xls`;
  XLSX.writeFile(wb, filename );
}
sort(column, descend,col) {
  switch (column) {
    case "name":
      if (this.col1 === false) {
        this.col1 = true;
        this.col2 = false;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "city":
      if (this.col2 === false) {
        this.col1 = false;
        this.col2 = true;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "manager":
      if (this.col3 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = true;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    default:
      this.col1 = false;
      this.col2 = false;
      this.col3 = false;
      break;
  }

  if (column === "name" || column === "city" || column === "manager")  {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: descend
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: descend
      };
      this.descend = false;
    }
  } else {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: true
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: false
      };
      this.descend = false;
    }
  }
}

getAddressParts(address) {
  const parts = address.split('/n');
  const street = parts.slice(0, parts.length - 3).join('/n');
  const number = parts[parts.length - 3];
  const postalCode = parts[parts.length - 2];
  const city = parts[parts.length - 1];
  return { street, number, postalCode, city };
}

}

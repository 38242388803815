<app-navbar></app-navbar>
<br>
<header class="bg-surface  pt-6">
  <div style="float: right;padding-right: 9%;" class="col-lg-5 col-md-6 col-sm-12">
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item"><a class="color-blue" routerLink="../../"><i class="zmdi zmdi-home"></i>
          Accueil</a>
      </li>
      <li class="breadcrumb-item active">Prestations</li>
      <li class="breadcrumb-item active">Calendrier</li>
    </ul>
  </div>
  <br>
  <br>
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%;">
      <!-- <div class="col-lg-5 col-md-6 col-sm-12">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
              Backoffice</a>
          </li>
          <li class="breadcrumb-item"><a routerLink="../">Articles</a></li>
          <li class="breadcrumb-item active">Ajouter</li>
        </ul>
      </div> -->
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0 ">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1">
            Calendrier
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>
      </div>
      <ul class="nav nav-tabs mt-4 overflow-x border-0">

      </ul>
    </div>
  </div>
</header>
<div class="student-profile py-4">
  <div  class="container">
      <div class="year-controls">
        <button class="btn btn-secondary button_list mb-2" (click)="setYear(year - 1)">Précédent</button>
        <span class="year-label">{{year}}</span>
        <button class="btn btn-primary button_list mb-2" (click)="setYear(year + 1)">Suivant</button>
    </div>
    <br>
<br>
<div class="calendar row">
	<div *ngFor="let month of months" class="month col-sm-6 col-md-4 col-lg-3">
    <div *ngIf="month.name == 'Jan' " class="calendar-header">Janvier</div>
    <div *ngIf="month.name == 'Feb' " class="calendar-header">Février</div>
    <div *ngIf="month.name == 'Mar' " class="calendar-header">Mars </div>
    <div *ngIf="month.name == 'Apr' " class="calendar-header">Avril </div>
    <div *ngIf="month.name == 'May' " class="calendar-header">Mai </div>
    <div *ngIf="month.name == 'Jun' " class="calendar-header">Juin </div>
    <div *ngIf="month.name == 'Jul' " class="calendar-header">Juillet </div>
    <div *ngIf="month.name == 'Aug' " class="calendar-header">Août </div>
    <div *ngIf="month.name == 'Sep' " class="calendar-header">Septembre </div>
    <div *ngIf="month.name == 'Oct' " class="calendar-header">Octobre </div>
    <div *ngIf="month.name == 'Nov' " class="calendar-header">Novembre</div>
    <div *ngIf="month.name == 'Dec' " class="calendar-header">Décembre </div>


    <div class="calendar-content">
      <!-- Day of week labels -->
      <div *ngFor="let day of dow" class="calendar-day" >{{day[0]}}</div>
      <div class="calendar-day" [ngStyle]="getPadding(month)"></div>
      <div *ngFor="let day of month.days; let d = index" class="calendar-day" [ngClass]="getCssClassForDate(month, d)">
        <button   data-toggle="modal"
        data-target="#exampleModalArchiver" (click)="clickDay(month, d)">{{d+1}}</button>
      </div>
      <!-- Ensure every month is 6 rows -->
      <!-- <div *ngIf="month.days[0].dow + month.days.length < 36" class="calendar-day" style="flex-basis: 100%"></div> -->
      <!-- Exception if Feb has 28 days and last day falls on a Saturday (see: 2037) -->
      <!-- <div *ngIf="month.days[0].dow + month.days.length < 29" class="calendar-day" style="flex-basis: 100%"></div> -->
    </div>
	</div>
</div>
</div>



<!--modal-->
<div

  class="modal fade"
  id="exampleModalArchiver"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>

  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 style="color: #0e309f;"  class="modal-title" id="exampleModalLabel">
          Détails d'une récurrence
        </h4> 
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <p><strong>Date:</strong> test-date</p>
          <p><strong>Site Name:</strong> chantier</p>
          <p><strong>Address:</strong> adresse</p>
          <p><strong>Workers:</strong> ouvries</p>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ClientService } from 'src/app/_services/client.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
declare var $: any;


@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css','../../../../_shared/add_update.css']
})
export class AddContactComponent implements OnInit {
  yourForm: FormGroup; // Assurez-vous d'avoir initialisé correctement votre formulaire

  @ViewChild('companySelect', { static: false }) companySelect;

  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
 
  registerForm: FormGroup;
    submitted = false;
    idClient_subContractor 
    gendres = ['Mr','Mme']
    clients :any 
    clients_subContractors :any 
    sub_contractors
    languages = ["FR","NL","EN"]
  value: any;
    constructor(private formBuilder: FormBuilder,
      private router: Router,
      private contactService :ContactService,
      private clientService :ClientService,
      private subContractorService :SubContractorService,
      public iziToast: Ng2IzitoastService,
      private fb: FormBuilder) {
        this.yourForm = this.fb.group({
          companyClient: []
        });
    
        // Écoutez les changements dans le formulaire
        this.yourForm.get('companyClient').valueChanges.subscribe((selectedItems) => {
          // Ajustez la hauteur du champ de sélection
          if (this.companySelect) {
            this.companySelect.opened = true;
            this.companySelect.close();
            this.companySelect.opened = false;
          }
        });
       }


    ngOnInit() {
      this.registerForm = this.formBuilder.group({
          gendre: [''],
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          companyClient: [],
          companySubContractor: [],
          phone: ['',],
          email: ['', [Validators.required, Validators.email]],
          language: ['',],
          comment: ['', Validators.maxLength(200)], 
      },);
      this.getClients()
      this.getClients_subContractors()
      this.getSubContractors()
  }

  getClients() {
    this.clientService.getClients().subscribe((e) => {
      this.clients = e;
    });
  }

  getSubContractors() {
    this.subContractorService.getList_subContractor().subscribe((e) => {
      this.sub_contractors = e;
    });
  }

  getClients_subContractors(){
    this.contactService.getList_clients_subContractors().subscribe((e) => {
      this.clients_subContractors = e;
    });
  
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
  
    if(this.registerForm.value.phone){
      this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber 
    }
    // display form values on success
    //
   this.contactService.createContact(this.registerForm.value).subscribe(
    (data) => {
     
      
      this.iziToast.show({
        message:'Ajout avec succès',
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
         this.router.navigate(['/admin/contacts' ])
        })
  //this.router.navigate(['/admin/contacts' ])
  }

  onReset() {
      // this.submitted = false;
      // this.registerForm.reset();

  }



}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BenefitService } from 'src/app/_services/benefit.service';
import { SharedService } from 'src/app/_services/shared.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';



@Component({
  selector: 'app-listoccurences',
  templateUrl: './listoccurences.component.html',
  styleUrls: ['../../../_shared/list.css','./listoccurences.component.css']
})
export class ListoccurencesComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  occurenceToUpdated
  idBenefit
  listOccurrences :any
  CopyListOccurrences :any
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  dateConverted
  statuts =["Planifiée","Effectuée","Modifiée","Annulée"]
  typesOfDays =["Jour valide","Weekend","Jour ferié"]
  statutToFiltred: Event;
  typeOfDaysToFiltred: any;
  startDateToFiltred :any
  constructor(private sharedService :SharedService,private router : Router,private benefitService :BenefitService,
    private modalService: NgbModal,private formBuilder: FormBuilder,private route :ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      date: ['',],
      name : ['',],
      benefit : [,],
      site : [,],
        },);
    //this.idBenefit = this.sharedService.getBenfitSended();
    this.idBenefit=this.route.snapshot.params["id"];
    if(!this.idBenefit){
      this.router.navigate(['/admin/prestations'])
      return;
    }
    this.getOccurences()
    
  }
  getOccurences() {
  this.benefitService
    .getOccurrencesByBenefit(this.idBenefit)
    .subscribe((e) => {
      this.listOccurrences =  e 
      this.CopyListOccurrences = this.listOccurrences;
    });
}
onTableDataChange(event: any) {
  this.page = event;
  this.getOccurences()
}
onTableSizeChange(event: any): void {
  this.tableSize = event.target.value;
  this.page = 1;
  this.getOccurences()
}

getOccurence(occurence){
  this.occurenceToUpdated = occurence;
  const [year, month, day] = this.occurenceToUpdated.date.split('-').map(Number);
  const initialDate = new Date(Date.UTC(year, month - 1, day));
  this.dateConverted = initialDate.toISOString().split('T')[0];
}

get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }
  this.registerForm.value.status = "Modifiée"
  this.registerForm.value.benefit = this.occurenceToUpdated.benefit
  this.registerForm.value.site = this.occurenceToUpdated.site
  this.benefitService.EditOccurence(this.occurenceToUpdated._id,this.registerForm.value).subscribe(
    (data) => {
     this.getOccurences()

    })
  
  //this.modal.dismiss();
}
onSubmitStatut(){
  this.benefitService.EditOccurence(this.occurenceToUpdated._id,this.occurenceToUpdated).subscribe(
    (data) => {
     this.getOccurences()

    })
}
onReset() {
  //this.modal.dismiss();

}
updateDateStatus(occurence){
  for(let occ of this.listOccurrences){
    if(occ.toUpdatedStatus === true){
      occ.toUpdatedStatus = false
    }
  }
  occurence.toUpdatedStatus = true
  this.occurenceToUpdated = occurence;
}
updateDate(occurence){
  for(let occ of this.listOccurrences){
    if(occ.toUpdated === true){
      occ.toUpdated = false
    }
  }
  occurence.toUpdated = true
  this.occurenceToUpdated = occurence;
  const [year, month, day] = this.occurenceToUpdated.date.split('-').map(Number);
  const initialDate = new Date(Date.UTC(year, month - 1, day));
  this.dateConverted = initialDate.toISOString().split('T')[0];
}


cancel(occurence){
  occurence.toUpdated = false
  occurence.toUpdatedStatus = false
}

filterByTypeOfDay(event){
  if(event == "Jour valide"){
    event = "default" 
  }
  else if(event == "Jour ferié"){
    event= "Holiday"
    }
  else{
    event= "Weekend"
  }
  this.typeOfDaysToFiltred = event

}


filterByStatut(event){
  this.statutToFiltred = event

}

filter() {
  this.listOccurrences = this.CopyListOccurrences;
  let filteredsListoccuences = [...this.CopyListOccurrences];
  // Convert filter criteria to lowercase
  let typeofDaysFilterLower = this.typeOfDaysToFiltred ? this.typeOfDaysToFiltred : '';
  if(typeofDaysFilterLower == "Jour valide"){
    typeofDaysFilterLower = "default" 
  }
  else if(typeofDaysFilterLower == "Jour ferié"){
    typeofDaysFilterLower= "Holiday"
    }

  const statutFilterLower = this.statutToFiltred ? this.statutToFiltred : '';
   const sartDateFilterLower = this.startDateToFiltred ? this.startDateToFiltred.toLowerCase() : '';
  if (typeofDaysFilterLower || statutFilterLower || sartDateFilterLower ) {
    //this.spinnerService.show();

    // setTimeout(() => {
    //   this.spinnerService.hide();
    // }, 1000); // 1 second
  }

  filteredsListoccuences = this.listOccurrences.filter(item => {
    const typeOfDaysFilter = typeofDaysFilterLower ? item.type === typeofDaysFilterLower : true;
    const statutFilter = statutFilterLower ? item.status === statutFilterLower : true;
    // Case-insensitive name search for a single character
     const startDateFilter = sartDateFilterLower ? item.date.toLowerCase().includes(sartDateFilterLower) : true;
    return typeOfDaysFilter && statutFilter && startDateFilter
    
  });
  this.listOccurrences = filteredsListoccuences;

  // Reset pagination
  this.page = 1;
}

resetFilters() {
   this.startDateToFiltred = "";
  this.typeOfDaysToFiltred = undefined; 
  this.statutToFiltred = undefined;
  
  this.filter();
}


exportToExcel(occurences: any[], filename: string): void {
  const data = occurences.map(occ => {
    if(occ.type == "default" ){
      occ.type = "Jour valide"
    }
    else if(occ.type == "Holiday"){  
      occ.type= "Jour ferié"
      }
    else{
      occ.type= "Weekend"
    }
    return {
      'Prestation': occ.name,
      'date': occ.date,
      'type de jour': occ.type,
      'Statut': occ.status,
    };
  });
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Recurrence');
  XLSX.writeFile(wb, filename + 'récurrences.xls');
}
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BenefitService } from 'src/app/_services/benefit.service';

@Component({
  selector: 'app-updateoccurence',
  templateUrl: './updateoccurence.component.html',
  styleUrls: ['./updateoccurence.component.css']
})
export class UpdateoccurenceComponent implements OnInit {

  city :any 
  registerForm: FormGroup;
  submitted = false;
  date
  benefit
  site
  name
  idOccurence
  dateConverted 


  listOccurrences

  listlistOccurrencesV2
  
  constructor(private formBuilder: FormBuilder,
    public modal: NgbActiveModal,
    private benefitService : BenefitService
    ) { }


  ngOnInit() {

    const [year, month, day] = this.date.split('-').map(Number);
    const initialDate = new Date(Date.UTC(year, month - 1, day));
    this.dateConverted = initialDate.toISOString().split('T')[0];
    this.registerForm = this.formBuilder.group({
            date: ['',],
            name : ['',],
            benefit : [,],
            site : [,],
            

            // acceptTerms: [false, Validators.requiredTrue]
        },);
    }

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }
  this.registerForm.value.benefit = this.benefit
  this.registerForm.value.site = this.site

  this.benefitService.EditOccurence(this.idOccurence,this.registerForm.value).subscribe(
    (data) => {
      // this.getOccurences()

    })
  
  this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}


}

<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
  <ul class="breadcrumb float-md-right">
    <li class="breadcrumb-item"> 
      <a class="color-blue" style="cursor: default;" 
        ><i class="zmdi zmdi-home"></i> Accueil</a
      >
    </li>
    <li class="breadcrumb-item color-blue">
      <a class="color-blue" routerLink="../../">Clients
      </a>
    </li>
    <li *ngIf="client" class="breadcrumb-item active color-blue " style="cursor: default;">{{client.companyName}}</li>
  </ul>
  </div>
  <br />
  <br />
  <!-- <div class="container-fluid">
    <div class="mb-npx" >
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails du client</h1>
        </div>
      </div>
    </div>
  </div> -->
</header>
<br>
<!-- Student Profile -->
<div class="student-profile py-4">
  <div  *ngIf="client" class="container">
    <div class="col-sm-11 col-12 mb-4 mb-sm-0">
      <!-- Title -->
      <h1 class="h2 mb-0 ls-tight color-blue title-1" style="color: #62B9CB;">{{client.companyName}}</h1>
      <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails du client </h1>
    </div>
    <div class="h2 mb-0 ls-tight text-sm-end">
      <a  (click)="goToUpdateClient()" class="btn d-inline-flex btn-sm btn-primary mx-1">
        <span class="button_list" style="color: #fff">Modifier</span>
      </a>
    </div>
<br>
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="client" class="card shadow-sm">
          <div class="card-header bg-transparent">
            <h3 class="mb-0"><i class="bi bi-info-circle-fill"></i> Informations générales</h3>
          </div>
          <div class="card-body row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Nom :</strong>
              <p class="mb-3 title-info">{{client.companyName}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">TVA :</strong>
              <p class="mb-3 title-info" >
                <span class="aucun-text" *ngIf="!client.tva">Aucun</span>
                {{client.tva}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Autoliquidation :</strong>
              <p class="mb-3 title-info">
                <span *ngIf="client.autoliquidation">
                  <i style="color: #28A745;" class="bi bi-circle-fill"></i>
                </span>
                <span *ngIf="!client.autoliquidation">
                  <i style="color: #DC3545;" class="bi bi-circle-fill"></i>
                </span>
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Pays :</strong>
              <p class="mb-3 title-info">
                <span class="aucun-text" *ngIf="!client.country">Aucun</span>
                {{client.country}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Adresse :</strong>
              <p *ngIf="!client.street && !client.number && !client.postalCode && !client.city " class="mb-3 title-info">
                <span class="aucun-text">Aucun</span></p>
                <p *ngIf="client.street && client.number"  class="mb3 title-info">{{ client.street }}, {{ client.number }}</p>
                <p *ngIf="client.postalCode && client.city" class="mb3 title-info">{{ client.postalCode }} {{ client.city }}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Contact principal :</strong>
              <p *ngIf="client.contactMain" class="mb-3 title-info">{{client.contactMain.firstName}} {{client.contactMain.lastName}}</p>
              <p *ngIf="!client.contactMain" class="mb-3 title-info">
                <span class="aucun-text">Aucun</span>
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Adresse mail de facturation :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!client.email">Aucun</span>
                  {{client.email}}</p>
              </div>
             
        
             
            <br>
            <br>
            <div class="mt-4">
              <div class="d-flex justify-content-end">
                <div class="mr-3">
                  <i style="color: #28A745;" class="bi bi-circle-fill"></i> Oui
                </div>
                <div class="mr-3">
                  <i style="color: #DC3545;" class="bi bi-circle-fill"></i> Non 
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      
     
    </div>
    <br>
    <br>
    <div>
       <div class="card shadow-sm">
         <div class="card-header bg-transparent border-0">
           <h3 class="mb-0"><i class="bi bi-bricks pr-1"></i>Liste des établissements de {{client.companyName}} </h3>
         </div>
         <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                  <th scope="col">Nom de l'établissement</th>
                  <th scope="col">Adresse </th>
                  <th scope="col">NOM du responsable</th>
                </tr>
              </thead>
              <tbody *ngFor="let i of etablissements  ;let k =index">
                <tr  (click)="goToDeailsEtablissement(i._id)" style="cursor: pointer;" *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden; cursor: pointer;">
                 <td>{{i.name}}</td>
                 <td>{{i.adress[0].street}}, {{i.adress[0].number}} <br>
                   {{i.adress[0].postalCode}} {{i.adress[0].city}}</td>
                 <td>{{i.manager.firstName}}</td>
                </tr>
                <tr (click)="goToDeailsEtablissement(i._id)"  *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden; cursor: pointer;">
                 <td>{{i.name}}</td>
                 <td>{{i.adress[0].street}}, {{i.adress[0].number}} <br>
                     {{i.adress[0].postalCode}} {{i.adress[0].city}}</td>
                 <td>{{i.manager.firstName}}</td>
                </tr>
              </tbody>
            </table>
          </div>

           <div
           *ngIf="etablissements && etablissements.length == 0"
           class="table-responsive"
           style="text-align: center"
           >
           <p style="padding-bottom: 45px; padding-top: 35px ">
             Aucun établissement n'est trouvé
           </p>
           </div>
         </div>
       </div>
     </div>

     <br>
     <div>
        <div class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="bi bi-file-person-fill pr-1"></i>Liste des contacts de {{client.companyName}}</h3>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;"  >
                    <th scope="col">PRÉNOM</th>
                    <th scope="col">NOM </th>
                    <th scope="col">ADRESSE MAIL </th>
                    <th scope="col">TÉLÉPHONE</th>
                  </tr>
                </thead>
                <tbody *ngFor="let i of contacts  ;let k =index">
                  <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" routerLink="/admin/contacts/details/{{i._id}}">
                    <td><span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                      {{i.lastName}}</td>
                    <td> <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                      {{i.firstName}}</td>
                    <td> <span class="aucun-text" *ngIf="!i.email">Aucun</span>
                      {{i.email}}</td>
                    <td> <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                      {{i.phone}}
                    </td>
                    </tr>
                  <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;" routerLink="/admin/contacts/details/{{i._id}}">
                    <td><span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                      {{i.lastName}}</td>
                    <td> <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                      {{i.firstName}}</td>
                    <td> <span class="aucun-text" *ngIf="!i.email">Aucun</span>
                      {{i.email}}</td>
                    <td> <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                      {{i.phone}}
                    </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <div
            *ngIf="contacts && contacts.length == 0"
            class="table-responsive"
            style="text-align: center"
            >
            <p style="padding-bottom: 45px; padding-top: 35px ">
              Aucun contact n'est trouvé
            </p>
            </div>
          </div>
        </div>
      </div>
   
      <br>
      <div>
        <div class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="bi bi-building pr-1"></i>Liste des chantiers de {{client.companyName}}</h3>
          </div>
          <div class="card-body pt-0">
        <div class="table-responsive">
         <table class="table">
           <thead >
             <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
               <th style="cursor: pointer;" (click)="sort('column2',decend,col2)" scope="col">N° chantier 
                 <i *ngIf="col2 == false" class="bi bi-chevron-down"></i>
                 <i *ngIf="col2 == true" class="bi bi-chevron-up"></i>
             </th>
               <th style="cursor: pointer;" (click)="sort('column3',decend,col3)" scope="col">Nom 
                 <i *ngIf="col3 == false" class="bi bi-chevron-down"></i>
                 <i *ngIf="col3 == true" class="bi bi-chevron-up"></i>
             </th>
               <th style="cursor: pointer;" (click)="sort('adress',decend,col4)" scope="col">Adresse 
                 <i *ngIf="col4 == false" class="bi bi-chevron-down"></i>
                 <i *ngIf="col4 == true" class="bi bi-chevron-up"></i>
             </th>
               <th style="cursor: pointer;" (click)="sort('column6',decend,col6)"  scope="col">Statut 
                 <i *ngIf="col6 == false" class="bi bi-chevron-down"></i>
                 <i *ngIf="col6 == true" class="bi bi-chevron-up"></i>
             </th>
             </tr>
           </thead>
           <tbody *ngFor="let i of sites   | sort: criteria  ; let k =index">
             <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" routerLink="/admin/chantiers/details/{{i._id}}">
               <td >{{i.column2}}</td>
                 <td >{{i.column3}}</td>
                 <td>
                   {{ getAddressParts(i.adress).street }}, {{ getAddressParts(i.adress).number }} <br>
                   {{ getAddressParts(i.adress).postalCode }} {{ getAddressParts(i.adress).city }}
                 </td>
                 <td *ngIf="i.column6 == 'STOP'"><span class="badge badge-danger">{{i.column6}}</span></td>
                 <td *ngIf="i.column6 == 'ACTIF'"><span class="badge badge-pill badge-success">{{i.column6}}</span></td>
                 <td  *ngIf="i.column6 == 'ONE SHOT'"><span class="badge badge-pill badge-info">{{i.column6}}</span></td>
                 <td  *ngIf="i.column6 == 'OFFRE'"><span class="badge badge-pill badge-orange">{{i.column6}}</span></td>
              
             </tr>
             <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;" routerLink="/admin/chantiers/details/{{i._id}}">
               <td >{{i.column2}}</td>
               <td>{{i.column3}}</td>
               <td >
                 {{ getAddressParts(i.adress).street }}, {{ getAddressParts(i.adress).number }} <br>
                 {{ getAddressParts(i.adress).postalCode }} {{ getAddressParts(i.adress).city }}
               </td>
               <td  *ngIf="i.column6 == 'STOP'"><span class="badge badge-danger">{{i.column6}}</span></td>
               <td  *ngIf="i.column6 == 'ACTIF'"><span class="badge badge-pill badge-success">{{i.column6}}</span></td>
               <td  *ngIf="i.column6 == 'ONE SHOT'"><span class="badge badge-pill badge-info">{{i.column6}}</span></td>
               <td *ngIf="i.column6 == 'OFFRE'"><span class="badge badge-pill badge-orange">{{i.column6}}</span></td>
             
             </tr>
            
         
           </tbody>
        </table>
        </div>
            <div
            *ngIf="sites && sites.length == 0"
            class="table-responsive"
            style="text-align: center"
            >
            <p style="padding-bottom: 45px; padding-top: 35px ">
              Aucun chantier n'est trouvé
            </p>
            </div>
          </div>
        </div>
       </div>
  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuppafacService } from 'src/app/_services/suppafac.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-suppafac',
  templateUrl: './details-suppafac.component.html',
  styleUrls: ['./details-suppafac.component.css','../../../../../_shared/list.css']
})
export class DetailsSuppafacComponent implements OnInit {
id
suppafac
  listFiles: any =[];
  BaseUrl_suppafac = environment.baseUrl.split('/api')[0] + '/media/suppafacs/';

  constructor(private suppafacService :SuppafacService ,private route :ActivatedRoute,private router :Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.suppafacService.getSuppafacById(this.id).subscribe(data => {
       this.suppafac = data;      
    })
    this.getlistDocumentsSuppafacs()
  }
  
  goToDetailsWorker(id){
    this.router.navigate(["admin/ouvriers/details", id]);

  }
  goToUpdateSuppafac()
   {
    this.router.navigate(["admin/chantiers/suppafacs/modification", this.id]);
   }
   compareDates(datePlanfie: Date): boolean {
    let date1 = new Date(datePlanfie);

// Create the second date (current date)
   var date2 = new Date();

   
    return date1.getTime() < date2.getTime()
  }

  getlistDocumentsSuppafacs(){
    this.suppafacService.listNamesFiles(this.id).subscribe(data => {
      this.listFiles = data;
    }),
    (error => {
      
    })

  }

  openFileInNewWindow(key: string,filename) {
    let url :any
  
    url =this.BaseUrl_suppafac+key+"/"+filename
    
   
    window.open(url, '_blank');
  }
  

}

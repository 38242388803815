import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private _clientURL=`${environment.baseUrl}/clients`
  private _siteURL=`${environment.baseUrl}/sites`

  constructor(private http: HttpClient) { }

     // updated -- organisé
     getClientsStorm() :Observable <any> {
      return this.http.get(`${this._clientURL}/listStormClients`);
      }

     getClients() :Observable <any> {
      return this.http.get(`${this._clientURL}`);
      }
      getClientsArchived() :Observable <any> {
        return this.http.get(`${this._clientURL}/list_Clients/archived`);
        }

 
      getClientById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._clientURL}/${id}`);
      } 
      createClient(client: any):Observable<Object> {
        return this.http.post(`${this._clientURL}`,client);
      }

      editContact(id:string,client:any):Observable<Object> {
        return this.http.put(`${this._clientURL}/${id}`,client);
      }
      
    getsiteByClient(id : string):Observable<any>
    {
      return this.http.get<any>(`${this._siteURL}/byClient/${id}`);
    } 


    archive_restoreClient(id :any){
      return this.http.get(`${this._clientURL}/archive-restoreclient/${id}`);
    }
     
    getEtablissementsByClient(id : string):Observable<any>
    {
      return this.http.get<any>(`${this._clientURL}/etablissements/byClient/${id}`);
    } 
    getContactsByClient(id : string):Observable<any>
    {
      return this.http.get<any>(`${this._clientURL}/contacts/byClient/${id}`);
    } 
    // archive_restoreEtablissements(id :any){
    //   return this.http.get(`${this._etablissementURL}/archive-restore/${id}`);
    // }




  }

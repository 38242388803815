<div class="modal-header">
    <h4 class="modal-title">Créer un contact</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
    <div>
      <form  #f0="ngForm" [formGroup]="registerForm" (ngSubmit)="onSubmit()" >
            
             
        <div class="form-row">
          <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <label>Genre</label>
              <ng-select [items]="gendres"
              formControlName="gendre"
             
              class="form-control input-custom"
              [closeOnSelect]="true"
              >
         </ng-select>
              
            </div>
          
          <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <label>Prénom<b style="color: red;"> *</b></label>
              <input type="text" formControlName="lastName"  class="form-control input-custom" 
              [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">Champ obligatoire.</div>
              </div>
          </div>
          <div class="form-group col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <label>Nom<b style="color: red;"> *</b></label>
            <input type="text" formControlName="firstName" class="form-control input-custom" 
             [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">Champ obligatoire.</div>
            </div>
        </div>
      </div>
          <div class="form-row">
              <!-- <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>Société (Client ou Sous-traitant)</label>
                  <ng-select [items]="clients_subContractors"
                  bindLabel="companyName"
                  bindValue="_id"
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  formControlName ="company"
                  >
                  </ng-select>
            
             
               
              </div> -->
              <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label>Téléphone</label>
                <br>
                <ngx-intl-tel-input
                [cssClass]="'form-control input-custom-phone'"
                formControlName="phone"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.Belgium"
                [maxLength]="15"
                [phoneValidation]="true"
                name="phone"
               
              ></ngx-intl-tel-input>
              <div *ngIf="registerForm.value.phone && !f0.form.controls['phone'].invalid == false" style="color: red;">
                Numéro de téléphone non validé <i class="bi bi-x"></i>
            </div>
            <div *ngIf=" registerForm.value.phone && !f0.form.controls['phone'].invalid == true" style="color: green;">
              Numéro de téléphone  validé <i class="bi bi-check"></i>
               </div>
              
            </div>
            
          </div>
         

          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label>Adresse mail<b style="color: red;"> *</b></label>
                <input type="text" formControlName="email" class="form-control input-custom"   [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Champ obligatoire.</div>
                    <div *ngIf="f.email.errors.email">Merci de saisir une adresse mail valide.</div>
                </div>
             
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label>Langue</label>
                <ng-select [items]="languages"
                formControlName="language"
              
                class="form-control input-custom"
                [closeOnSelect]="true"
                >
           </ng-select>
              
            </div>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
              <label>Commentaire</label>
              <textarea  type="text"  formControlName="comment" class="form-control input-custom"  > </textarea>
           
          </div>
        
      </div>
   
         
          <div style="padding-top: 3%;" class="text-center">
              
              <button (click)="onReset()" class="button_list btn btn-secondary mr-4 mb-2" type="reset">Annuler</button>
              <button class="button_list btn btn-primary mb-2">Sauvegarder</button>
          </div>
      </form>
          </div>
          </div>


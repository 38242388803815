import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-add-worker',
  templateUrl: './popup-add-worker.component.html',
  styleUrls: ['./popup-add-worker.component.css']
})
export class PopupAddWorkerComponent implements OnInit {

 

  registerForm: FormGroup;
  submitted = false;
  worksite = [
    {  name: 'Chantier 1' },
    {  name: 'Chantier 2' },
    {  name: 'Chantier 3' },
   
  ]

  ServiceProvider = [
    {  name: 'Prestataire 1' },
    {  name: 'Prestataire 2' },
    {  name: 'Prestataire 3' },
   
  ]
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public modal: NgbActiveModal) { }


  ngOnInit() {
    this.registerForm = this.formBuilder.group({
        // title: ['', Validators.required],
        firstName: ['', ],
        lastName: ['', Validators.required],
        ServiceProvider :['', Validators.required],
        phone : ['', Validators.required],
        email:['', Validators.email],
        worksite: ['',],
        comment: ['', ],
        // acceptTerms: [false, Validators.requiredTrue]
    },);
}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }
  this.modal.dismiss();

  // display form values on success
  //
  
  
  //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
 
}

onReset() {
  this.modal.dismiss();

}

}

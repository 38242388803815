import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { WorkerService } from 'src/app/_services/worker.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';

@Component({
  selector: 'app-list-workers',
  templateUrl: './list-workers.component.html',
  styleUrls: ['../../../_shared/list.css','./list-workers.component.css']
})
export class ListWorkersComponent implements OnInit {

  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  workers : any
  workerArchived
  copyListWorkers;
  prenomToFiltred: any;
  nomToFiltred: any;
  emailToFiltred: any;
  telephoneToFiltred: any;
  constructor( private router: Router,
    private workerService :WorkerService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService

    ) { }

  ngOnInit(): void {
    this.getWorkers()
    localStorage.removeItem("lastPageContactVisited")
    localStorage.removeItem("lastPageClientVisited")
    localStorage.removeItem("lastPageBenefitVisited")
    localStorage.removeItem("lastPageDocumentVisited")
    localStorage.removeItem("lastPageEtablissementVisited")
    localStorage.removeItem("lastPageSubContractorVisited")
    localStorage.removeItem("lastPageSiteVisited")
    localStorage.removeItem("lastPageSuppafacVisited")
    localStorage.removeItem("lastPageGlassVisited")



    if(localStorage.getItem("lastPageWorkerVisited") && 
    localStorage.getItem("lastPageWorkerVisited") != undefined && 
    localStorage.getItem("lastPageWorkerVisited") != "")
   this.page = Number(localStorage.getItem("lastPageWorkerVisited"))
  }
  getWorkers() {
    this.workerService.getWorkers().subscribe((e) => {
      this.workers = e.reverse();
      this.copyListWorkers = this.workers;
    });
  }


  create() {
    this.router.navigate(["admin/ouvriers/ajout"]);
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.workers=this.workers;
    localStorage.setItem("lastPageWorkerVisited",this.page.toString())
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getWorkers();
  }

  getWorker(worker) {
    this.workerArchived = worker;
  }
  archiveWorker(worker: any) {
    worker = this.workerArchived;
    let index = this.workers.findIndex(wor => wor._id === worker._id);
    let lastDigit = index % 10;
     if (lastDigit === 0) {
      this.page--;
      localStorage.setItem("lastPageWorkerVisited",this.page.toString())
    }
    this.workerService
      .archive_restoreWorker(worker._id)
      .subscribe((e) => {
        this.iziToast.show({
          message: "Archivage avec succès",
          messageColor: "#343A40",
          progressBarColor: "#212529",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#CED4DA",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        // this.getWorkers()
        // this.page = 1
        this.router.navigate(["admin/ouvriers/archives"]);

      });
  }
  details(id) {
    const url = `admin/ouvriers/details/${id}`;
    window.open(url, '_blank');
  }
  update(id) {
    this.router.navigate(["admin/ouvriers/modification", id]);
  }
  filter() {
    this.workers = this.copyListWorkers;
    let filteredWorkers = [...this.copyListWorkers];
    // Convert filter criteria to lowercase
    const prenomFilterLower = this.prenomToFiltred ? this.prenomToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const telephoneFilterLower = this.telephoneToFiltred ? this.telephoneToFiltred.toLowerCase() : '';
    // if (prenomFilterLower || nomFilterLower || emailFilterLower || telephoneFilterLower) {
    //     this.spinnerService.show();

    //     setTimeout(() => {
    //         this.spinnerService.hide();
    //     }, 1000); // 1 second
    // }

    filteredWorkers = this.workers.filter(item => {
      const nomFilter = nomFilterLower ? (item.lastName && item.lastName.toLowerCase().includes(nomFilterLower)) : true;
      const prenomFilter = prenomFilterLower ? (item.firstName && item.firstName.toLowerCase().includes(prenomFilterLower)) : true;
      const telephoneFilter = telephoneFilterLower ? (item.phone && item.phone.toLowerCase() && item.phone.toLowerCase().replace(/\s/g, '').includes(telephoneFilterLower)) : true;
  
      return nomFilter && prenomFilter && telephoneFilter;
  });
    this.workers = filteredWorkers;

    // Reset pagination
    this.page = 1;
}


  resetFilters() {
    this.nomToFiltred = ""; 
    this.prenomToFiltred = ""; 
    this.telephoneToFiltred = "";
    
    this.filter();
 }
 exportToExcel(workers: any[]): void {
  const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
  const data = workers.map(worker => {
    return {
      'PRÉNOM': worker.lastName,
      'NOM': worker.firstName,
      'TÉLÉPHONE': worker.phone,
    };
  });
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const columnWidths = [
    { wpx: 120 },
    { wpx: 200 }, 
    { wpx: 200 }, 
  ];
  ws['!cols'] = columnWidths;
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Ouvriers');
  const filename = `${currentDate}_ouvriers.xls`;
  XLSX.writeFile(wb, filename );
}
sort(column, descend,col) {
  switch (column) {
    case "lastName":
      if (this.col1 === false) {
        this.col1 = true;
        this.col2 = false;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "firstName":
      if (this.col2 === false) {
        this.col1 = false;
        this.col2 = true;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "phone":
      if (this.col3 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = true;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    default:
      this.col1 = false;
      this.col2 = false;
      this.col3 = false;
      break;
  }

  // if (column === "column4") {
  //   if (descend === false) {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = true;
  //   } else {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = false;
  //   }
  // } else {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: true
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: false
      };
      this.descend = false;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BenefitService } from 'src/app/_services/benefit.service';

@Component({
  selector: 'app-details-benefit',
  templateUrl: './details-benefit.component.html',
  styleUrls: ['./details-benefit.component.css','../../../../_shared/list.css']
})
export class DetailsBenefitComponent implements OnInit {
  registerForm: FormGroup;

id :any 
  benefit: any;
  submitted = false;
  occurenceToUpdated
  listOccurrences :any
  dateConverted
  statuts =["Planifiée","Effectuée","Annulée"]
  etatDuration = 0 
  constructor(
    private benefitService :BenefitService,
    private route :ActivatedRoute,
    private formBuilder: FormBuilder,
    private router :Router
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      date: ['',],
      name : ['',],
      benefit : [,],
      site : [,],
        },);
    this.id = this.route.snapshot.params["id"];
    this.benefitService.getDetailsBenefit(this.id).subscribe(data => {
      this.benefit = data;
      if(this.benefit && this.benefit.frequence == "Hebdomadaire" || this.benefit.frequence == "Quotidienne" ){
      this.benefit.listDates = this.convertToDayName(this.benefit.listDates);
      }
      if(!this.benefit.worker){
      this.benefit.worker = []
      }
     
   })
   this.getOccurences()
  }
  getOccurences() {
    this.benefitService
      .getOccurrencesByBenefit(this.id)
      .subscribe((e) => {
        this.listOccurrences =  e 
      });
  }
  getOccurence(occurence){
    this.occurenceToUpdated = occurence;
    const [year, month, day] = this.occurenceToUpdated.date.split('-').map(Number);
    const initialDate = new Date(Date.UTC(year, month - 1, day));
    this.dateConverted = initialDate.toISOString().split('T')[0];
  }
  
  // updateOccurence(occurence){
  //   let modalRef
  //   modalRef =this.modalService.open(UpdateoccurenceComponent);
  //   modalRef.componentInstance.idOccurence = occurence._id;
  //   modalRef.componentInstance.date = occurence.date.toString();
  //   modalRef.componentInstance.name = occurence.name;
  //   modalRef.componentInstance.benefit = occurence.benefit;
  //   modalRef.componentInstance.site = occurence.site;
    
  //   modalRef.componentInstance.listOccurrences = this.listOccurrences
  //   }
  get f() { return this.registerForm.controls; }
  
  onSubmit() {
    this.submitted = true;
  
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    this.registerForm.value.status = "Modifiée"
    this.registerForm.value.name = this.benefit.name
    this.registerForm.value.benefit = this.benefit._id
    this.registerForm.value.site =  this.benefit.site._id
    this.registerForm.value.date =  this.dateConverted

    this.benefitService.EditOccurence(this.occurenceToUpdated._id,this.registerForm.value).subscribe(
      (data) => {
       this.getOccurences()
  
      })
      }
  onSubmitStatut(){
    this.benefitService.EditOccurence(this.occurenceToUpdated._id,this.occurenceToUpdated).subscribe(
      (data) => {
       this.getOccurences()
  
      })
  }
  updateDateStatus(occurence){
    for(let occ of this.listOccurrences){
      if(occ.toUpdatedStatus === true){
        occ.toUpdatedStatus = false
      }
    }
    occurence.toUpdatedStatus = true
    this.occurenceToUpdated = occurence;
  }
  updateDate(occurence){
    for(let occ of this.listOccurrences){
      if(occ.toUpdated === true){
        occ.toUpdated = false
      }
    }
    occurence.toUpdated = true
    this.occurenceToUpdated = occurence;
    const [year, month, day] = this.occurenceToUpdated.date.split('-').map(Number);
    const initialDate = new Date(Date.UTC(year, month - 1, day));
    this.dateConverted = initialDate.toISOString().split('T')[0];
  }
  
  
  cancel(occurence){
    occurence.toUpdated = false
    occurence.toUpdatedStatus = false
  }

  goToDetailsWorker(worker){
    this.router.navigate(["admin/ouvriers/details/"+worker._id]);
  }



   convertToDayName(listDates) {
    const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    const result = [];
  
    listDates.forEach((num) => {
      const index = parseInt(num, 10) - 1;
      result.push(days[index]);
    });
  
    return result;
  }

  changeEtatDuration(){
    this.etatDuration = 1
  }

  updateDurationbenefit(durationNew){
   this.benefit.duration = durationNew
   if(!durationNew){
    durationNew = " "
    this.benefit.duration = durationNew
   }
  //  this.benefitService.updateDurationBenefit(this.benefit._id,this.benefit).subscribe(
  //   (data) => {
  //    console.log("data",data)
  //    this.etatDuration = 0
  //   })

  }
  onInputBlur(event: any) {
    // Ajoutez votre logique de traitement ici
      this.benefitService.updateDurationBenefit(this.benefit._id,this.benefit).subscribe(
       (data) => {
        this.etatDuration = 0
       })
  }
  goToUpdateBenifit(){
    this.router.navigate(["admin/prestations/modification", this.id]);
  }

}

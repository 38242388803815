import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { ClientService } from 'src/app/_services/client.service';
import { SiteService } from 'src/app/_services/site.service';

@Component({
  selector: 'app-details-client',
  templateUrl: './details-client.component.html',
  styleUrls: ['./details-client.component.css','../../../../_shared/list.css']
})
export class DetailsClientComponent implements OnInit {

  id
  client
  etablissements
  contacts
  sites :any
  criteria: SortCriteria;
  decend = false
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  col5 = false
  col6 = false
  
    constructor(
      private clientService :ClientService,
      private route :ActivatedRoute,
      private router : Router,
      private siteService :SiteService

      ) { }
    
    
      ngOnInit(): void {
        this.id = this.route.snapshot.params["id"];
       this.clientService.getClientById(this.id).subscribe(data => {
          this.client = data;
         
       })
       this.getEtablissementsByclient()
       this.getContactsByclient()
       this.getSitesByclient()
  }

  getEtablissementsByclient(){
    this.clientService.getEtablissementsByClient(this.id).subscribe(data => {
      this.etablissements = data;
     
   })
  }
  getContactsByclient(){
    this.clientService.getContactsByClient(this.id).subscribe(data => {
      this.contacts = data;    
   })
  }
   getSitesByclient(){
    this.siteService.listSitesByClient(this.id).subscribe(data => {
      this.sites = data;
     
   })
   }
   goToUpdateClient()
   {
    this.router.navigate(["admin/clients/modification", this.id]);
   }
   sort(column,decend,col)
   {
    
  
  switch (column) {
    
    case "column1":
      if(this.col1 == false){
      this.col1 = true
      this.col2 = false
      this.col3 = false
      this.col4 = false
      this.col5 = false
      this.col6 = false
      }
      else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column2":  
    if(this.col2 == false){
      this.col1 = false
      this.col2 = true
      this.col3 = false
      this.col4 = false
      this.col5 = false
      this.col6 = false
    }
    else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column3":
      if(this.col3 == false){  
      this.col1 = false
      this.col2 = false
      this.col3 = true
      this.col4 = false
      this.col5 = false
      this.col6 = false  
      }
      else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "adress":   
    if(this.col4 == false){   
      this.col1 = false
      this.col2 = false
      this.col3 = false
      this.col4 = true
      this.col5 = false
      this.col6 = false  
    }
    else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column5":  
    if(this.col5 == false){   
      this.col1 = false
      this.col2 = false
      this.col3 = false
      this.col4 = false
      this.col5 = true
      this.col6 = false
    }
    else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    case "column6":
      if(this.col6 == false){   
      this.col1 = false
      this.col2 = false
      this.col3 = false
      this.col4 = false
      this.col5 = false
      this.col6 = true
      }
      else
      {
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false
      }
      break
    default :
        this.col1 = false
        this.col2 = false
        this.col3 = false
        this.col4 = false
        this.col5 = false
        this.col6 = false   
        break   

  }

    if(column == "column6"){
    if(decend == false){
    this.criteria = {
      property: column ,
      descending: decend
    };
    this.decend = true
    }
    else{
      this.criteria = {
        property: column ,
        descending: decend
      };
      this.decend = false
    }
  }
  else
  {
   
      if(decend == false){
      this.criteria = {
        property: column ,
        descending: true
      };
      this.decend = true
      }
      else{
        this.criteria = {
          property: column ,
          descending: false
        };
        this.decend = false
      }
  
   }
}
  goToDeailsEtablissement(id){
    this.router.navigate(["admin/etablissements/details", id]);

  }
  getAddressParts(address) {
    const parts = address.split('/n');
    const street = parts.slice(0, parts.length - 3).join('/n');
    const number = parts[parts.length - 3];
    const postalCode = parts[parts.length - 2];
    const city = parts[parts.length - 1];
    return { street, number, postalCode, city };
  }
}

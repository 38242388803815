import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypebenefitService } from 'src/app/_services/typebenefit.service';
import { PopupAddTypeBenefitComponent } from '../../popupAdd/popup-add-type-benefit/popup-add-type-benefit.component';
import { Router } from '@angular/router';
import { PopupUpdateTypeBenefitComponent } from '../../popupAdd/popup-update-type-benefit/popup-update-type-benefit.component';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-list-types-benfits',
  templateUrl: './list-types-benfits.component.html',
  styleUrls: ['./list-types-benfits.component.css','../../../../_shared/list.css']
})
export class ListTypesBenfitsComponent implements OnInit {
  typesBenefits :any
  sizeContent :any
  recentResultsTypesBenefits
  type: string;

  constructor(private typeBenefitService :TypebenefitService,private router: Router,
    private modalService: NgbModal,private sharedService :SharedService
    ) { }

  ngOnInit(): void {
    this.sharedService.submitEvent$.subscribe(() => {
      this.getTypes(); // ou toute autre action que vous souhaitez effectuer
    });
    this.getTypes()
  }


  getTypes() {
    this.typeBenefitService.getTypesBenefits().subscribe((e) => {
      this.typesBenefits = e
      this.recentResultsTypesBenefits =  this.typesBenefits
      this.sizeContent=this.typesBenefits.length
    });
  }

  addType(){
    let modalRef
    modalRef =this.modalService.open(PopupAddTypeBenefitComponent);
  }

  goToListBenifits(){
    this.router.navigate(["admin/prestations/"]);
  }
  updateType(id){
    console.log("id",id)
    let modalRef
    modalRef =this.modalService.open(PopupUpdateTypeBenefitComponent);
    modalRef.componentInstance.idType = id;

  }

  search()
  {
    if(this.type != "" )
     {
      this.typesBenefits = this.recentResultsTypesBenefits
      this.typesBenefits = this.typesBenefits.filter(res => {
        return res.type.toLocaleLowerCase().match(this.type.toLocaleLowerCase()) 
       
      });
      
    
     }
     else if(this.type = "" )
     {
      this.typesBenefits = this.recentResultsTypesBenefits
  
     }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private _documentURL=`${environment.baseUrl}/documents`
  constructor(private http: HttpClient) { }

     getDocuments() :Observable <any> {
      return this.http.get(`${this._documentURL}`);
      }

      archive_restoreDocument(id :any){
        return this.http.get(`${this._documentURL}/archive-restoreDocument/${id}`);
      }
      getDocumentsArchiveds() :Observable <any> {
        return this.http.get(`${this._documentURL}/list_documents/archived`);
        }


  
        
}

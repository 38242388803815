<div class="modal-header">
    <h4 class="modal-title">Créer un ouvrier</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
    <div>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
            
             
            <div class="form-row">
              <div class="form-group col">
                <label>Prénom</label>
                <input type="text" formControlName="lastName"  class="form-control input-custom" 
                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Champ obligatoire.</div>
                </div>
      
            </div>
              <div class="form-group col">
                  <label>Nom</label>
                  <input type="text" formControlName="firstName" class="form-control input-custom"  />
                
              </div>
         
          </div>
              <div class="form-row">
                  <div class="form-group col">
                      <label>Prestataire</label>
                      <ng-select [items]="ServiceProvider"
                      bindLabel="name"
                      multiple =true
                      formControlName="ServiceProvider"
                      class="form-control input-custom"
                      [closeOnSelect]="true"
                      [ngClass]="{ 'is-invalid': submitted && f.ServiceProvider.errors }"

                      >
                 </ng-select>
                      <div *ngIf="submitted && f.ServiceProvider.errors" class="invalid-feedback">
                          <div *ngIf="f.ServiceProvider.errors.required">Champ obligatoire.</div>
                      </div>
                  </div>
                  <div class="form-group col">
                    <label>Téléphone</label>
                    <input type="text" formControlName="phone" class="form-control input-custom" 
                     [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Champ obligatoire.</div>
                    </div>
                  
                </div>
              </div>
  
              <div class="form-row">
                <div class="form-group col">
                  <label>Adresse mail</label>
                  <input type="text" formControlName="email" class="form-control input-custom" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
                      <div *ngIf="f.email.errors.email">Merci de saisir une adresse mail valide.</div>
                  </div>
              
            </div>
        
                <div class="form-group col">
                    <label>Chantier</label>
                    <ng-select [items]="worksite"
                    bindLabel="name"
                    multiple =true
                    formControlName="worksite"
                    class="form-control input-custom"
                    [closeOnSelect]="true"
                    >
               </ng-select>
                  
                </div>
            </div>
  
            <div class="form-row">
              <div class="form-group col-12">
                  <label>Commentaire</label>
                  <textarea  type="text" formControlName="comment" class="form-control input-custom"  > </textarea>
               
              </div>
            
          </div>
       
             
              <div style="padding-top: 3%;" class="text-center">
                  
                  <button routerLink="/admin/contacts" class="button_list btn btn-secondary mr-4" type="reset">Annuler</button>
                  <button class="button_list btn btn-primary ">Sauvegarder</button>
              </div>
          </form>
      </div>
  </div>
  

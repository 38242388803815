import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ContactService } from 'src/app/_services/contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
@Component({
  selector: 'app-list-contact',
  templateUrl: './list-contact.component.html',
  styleUrls: ['../../../_shared/list.css','./list-contact.component.css']
})
export class ListContactComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  contacts :any ;
  contactArchived :any;
  copyListContacts;
  prenomToFiltred:any;
  nomToFiltred:any;
  telephoneToFiltred:any;
  emailToFiltred:any;

  constructor( private router: Router,private contactService :ContactService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.getContacts()
    localStorage.removeItem("lastPageClientVisited")
    localStorage.removeItem("lastPageBenefitVisited")
    localStorage.removeItem("lastPageDocumentVisited")
    localStorage.removeItem("lastPageEtablissementVisited")
    localStorage.removeItem("lastPageSiteVisited")
    localStorage.removeItem("lastPageWorkerVisited")
    localStorage.removeItem("lastPageSubContractorVisited")
    localStorage.removeItem("lastPageSuppafacVisited")
    localStorage.removeItem("lastPageGlassVisited")



    if(localStorage.getItem("lastPageContactVisited") && 
    localStorage.getItem("lastPageContactVisited") != undefined && 
    localStorage.getItem("lastPageContactVisited") != "")
   this.page = Number(localStorage.getItem("lastPageContactVisited"))

  }
  create() {
    this.router.navigate(["admin/contacts/ajout"]);
  }

 

  getContacts() {
    this.contactService.getContacts().subscribe((e) => {
      this.contacts =e.reverse();
      this.copyListContacts = this.contacts;
    });
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.contacts=this.contacts
    localStorage.setItem("lastPageContactVisited",this.page.toString())

  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getContacts()
  }
  getContact(contact) {
    this.contactArchived = contact;
  }
  archiveContact(contact: any) {
    contact = this.contactArchived;
    let index = this.contacts.findIndex(con => con._id === contact._id);
    let lastDigit = index % 10;
     if (lastDigit === 0) {
      this.page--;
      localStorage.setItem("lastPageContactVisited",this.page.toString())
    }
    this.contactService
      .archive_restoreContact(contact._id)
      .subscribe((e) => {
        this.iziToast.show({
          message: "Archivage avec succès",
          messageColor: "#343A40",
          progressBarColor: "#212529",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#CED4DA",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        // this.getContacts()
        // this.page = 1
        this.router.navigate(["admin/contacts/archives"]);
      });
  }

  details(id) {
    const url = `admin/contacts/details/${id}`;
    window.open(url, '_blank');
  }
  update(id) {
    this.router.navigate(["admin/contacts/modification", id]);
  }
  filter() {
    this.contacts = this.copyListContacts;
    let filteredContacts = [...this.copyListContacts];

    // Convert filter criteria to lowercase
    const prenomFilterLower = this.prenomToFiltred ? this.prenomToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const emailFilterLower = this.emailToFiltred ? this.emailToFiltred.toLowerCase() : '';
    const telephoneFilterLower = this.telephoneToFiltred ? this.telephoneToFiltred : '';

    filteredContacts = this.contacts.filter(item => {
            const nomFilter = nomFilterLower ? (item.lastName ? item.lastName.toLowerCase().includes(nomFilterLower) : false) : true;
            const prenomFilter = prenomFilterLower ? (item.firstName ? item.firstName.toLowerCase().includes(prenomFilterLower) : false) : true;
            const emailFilter = emailFilterLower ? (item.email ? item.email.toLowerCase().includes(emailFilterLower) : false) : true;
            const telephoneFilter = telephoneFilterLower ? ((item.phone ? item.phone.toLowerCase() : '').replace(/\s/g, '')).includes(telephoneFilterLower) : true;

            return nomFilter && prenomFilter && emailFilter && telephoneFilter;
    });

    this.contacts = filteredContacts;

    // Reset pagination
    this.page = 1;
}



  resetFilters() {
    this.nomToFiltred = ""; 
    this.prenomToFiltred = ""; 
    this.emailToFiltred = ""; 
    this.telephoneToFiltred = "";
    
    this.filter();
 }
 exportToExcel(contacts: any[]): void {
  const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
  const data = contacts.map(contact => {
    return {
      'PRÉNOM': contact.lastName,
      'NOM': contact.firstName,
      'ADRESSE MAIL': contact.email,
      'TÉLÉPHONE': contact.phone,
    };
  });
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const columnWidths = [
    { wpx: 120 },
    { wpx: 200 }, 
    { wpx: 200 }, 
    { wpx: 120 },
  ];
  ws['!cols'] = columnWidths;
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Contacts');
  const filename = `${currentDate}_contacts.xls`;
  XLSX.writeFile(wb, filename );
}
sort(column, descend,col) {
  switch (column) {
    case "lastName":
      if (this.col1 === false) {
        this.col1 = true;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    case "firstName":
      if (this.col2 === false) {
        this.col1 = false;
        this.col2 = true;
        this.col3 = false;
        this.col4 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    case "email":
      if (this.col3 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = true;
        this.col4 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    case "phone":
      if (this.col4 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = true;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    default:
      this.col1 = false;
      this.col2 = false;
      this.col3 = false;
      this.col4 = false;
      break;
  }

  // if (column === "column4") {
  //   if (descend === false) {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = true;
  //   } else {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = false;
  //   }
  // } else {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: true
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: false
      };
      this.descend = false;
    }
  }
//}

}

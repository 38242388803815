import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BenefitService } from 'src/app/_services/benefit.service';

const MONTHS  = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class Month {
  constructor(
    public name: string,
    public days: Day[]
  ) {}
}

class Day {
  constructor(
    public dow: number,
    public holiday: boolean
  ) {}
}


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css','../../../../_shared/list.css']
})
export class CalendarComponent implements OnInit {
  // redDates = ['2024-01-03','2023-10-15', '2023-10-20', '2023-10-25'];
  year: number;
  months: Month[];
  dow = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  id
  occurrences: any;
  redDates: any;

  constructor (private route :ActivatedRoute,private benefitService :BenefitService) {
    this.setYear(2023);
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
    this.benefitService.getOccurrences_calendar_ByBenefit(this.id).subscribe(data => {
      this.occurrences = data;
      this.redDates = this.occurrences.map(item => item.date);
           })

  }

  // Change to a new year and update calendar view
  setYear(year: number) {
    this.year = year;
    this.months = MONTHS.map( m => {
      // Get starting day of week of month
      var month = new Date(`${m} 1 ${this.year}`);
      var dow = month.getDay();
      dow = (dow + 6) % 7;

      // Get list of days in this month
      month.setMonth(month.getMonth() + 1);
      month.setDate(month.getDate() - 1);
      var days = Array.from(Array(month.getDate()).keys())
      
      // Each month has name and list of days
      return new Month(
        m, days.map( d => {
          // Each day randomly has holiday for test data
          return new Day(
            (dow + d) % 7,
            Math.random() >= 0.5
        )}
      ));
    })
  }

  // We cannot embed attributes inside a calc() so we have to reroute it to this function
  getPadding(month: Month) {
    return {
      'flex-basis': `calc(100% * (${month.days[0].dow} / 7))`
    };
  }

  clickDay(month: Month, d: number) {
    var day = month.days[d];
    console.log(`You clicked ${this.dow[day.dow]} ${month.name} ${d+1}, ${this.year} (${day.holiday ? 'Holiday' : 'Workday'})`)
  }
  // Add a function to determine the color for a specific date
  getCssClassForDate(month: Month, dayIndex: number): string {
    const clickedDate = new Date(this.year, MONTHS.indexOf(month.name), dayIndex + 1);
  
    if (!Array.isArray(this.redDates)) {
      // Handle the case where this.redDates is not an array
      return month.days[dayIndex].holiday ? 'red' : 'black';
    }
  
    // Convert redDates to an array of date strings in the format 'yyyy-MM-dd'
    const redDateStrings = this.redDates.map(date => {
      const dateObj = new Date(date);
      dateObj.setHours(0, 0, 0, 0); // Set time to midnight
      return dateObj.toISOString().slice(0, 10);
    });
  
    const clickedDateMidnight = new Date(clickedDate);
    clickedDateMidnight.setHours(0, 0, 0, 0); // Set time to midnight
    const clickedDateString = clickedDateMidnight.toISOString().slice(0, 10);
  
    if (redDateStrings.includes(clickedDateString)) {
      return 'highlighted-date';
    } else {
      return month.days[dayIndex].holiday ? 'red' : 'black';
    }
  }
}
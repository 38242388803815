import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SuppafacService {

 private _suppafacURL=`${environment.baseUrl}/suppafacs`
 constructor(private http: HttpClient) { }

  
 uploadFiles(files,key):Observable <any> {
  return this.http.post(`${this._suppafacURL}/upload/file/${key}`,files);
      }

    getSuppafacs(): Observable<any> {
      return this.http.get<any>(this._suppafacURL);
    }

     createSuppafac(suppafac: any):Observable<Object> {
       return this.http.post(`${this._suppafacURL}`,suppafac);
     }
     
     deleteSuppafac(id : string)
     {
       return this.http.delete(`${this._suppafacURL}/${id}`);
     } 

    getSuppafacById(id : string):Observable<any>
     {
       return this.http.get<any>(`${this._suppafacURL}/${id}`);
     } 
     
     editSuppafac(id:string,suppafac:any):Observable<Object> {
       return this.http.put(`${this._suppafacURL}/${id}`,suppafac);
     }

    archive_restoreSuppafac(id :any){
      return this.http.get(`${this._suppafacURL}/archive-restoreSuppafac/${id}`);
    }

    getSuppafacsArchiveds() :Observable <any> {
      return this.http.get(`${this._suppafacURL}/list_suppafacs/archived`);
      }

    getListsSuppafacsBySite(id : string):Observable<any>
     {
       return this.http.get<any>(`${this._suppafacURL}/listsSuppafacsBySite/${id}`);
     } 


        // get list documents in suppafac 
        listNamesFiles(id : string):Observable<any>
        {
          return this.http.get<any>(`${this._suppafacURL}/files/listNames/${id}`);
        } 


}

<!-- main app container -->
<app-navbar></app-navbar>
<header class="bg-surface  pt-6">
  <div style="float: right;padding-right: 9%;" class="col-lg-5 col-md-6 col-sm-12">
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item"> 
        <a class="color-blue" style="cursor: default;" 
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li class="breadcrumb-item color-blue">
        <a class="color-blue" routerLink="../../">Prestataires</a>
      </li>
      <li class="breadcrumb-item active color-blue " style="cursor: default;">Modifier un prestataire</li>
    </ul>
  </div>
  <br>
  <br>
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%;">
      <!-- <div class="col-lg-5 col-md-6 col-sm-12">
        <ul class="breadcrumb float-md-right">
          <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
              Backoffice</a>
          </li>
          <li class="breadcrumb-item"><a routerLink="../">Articles</a></li>
          <li class="breadcrumb-item active">Ajouter</li>
        </ul>
      </div> -->
      <div class="row align-items-center">
          <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0 ">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1" >
            Modifier un prestataire
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>

          <!-- Actions -->
          <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mt-4 text-sm-end">
          <div class="mx-n1">
            <a
              (click)="create()"
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
            
              <span class="button_list" style="color: #fff">Créer un client</span>
            </a>
            <a
              
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
            
              <span class="button_list" style="color: #fff">Export XLS</span>
            </a>
          </div>
        </div> -->
      </div>
    
     
    </div>
  </div>
</header>
<br>
<div  class="card m-3-cust">
  
  <div class="card-body ml-5">
    <form #f0="ngForm" [formGroup]="registerForm" *ngIf="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
    <div class="form-row">
      <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <label>Nom de la société <b style="color: red;"> * </b></label>
          <input type="text" formControlName="companyName" class="form-control input-custom" [(ngModel)]="sub_contrator.companyName"
          [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
          <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
              <div *ngIf="f.companyName.errors.required">Champ obligatoire.</div>
          </div>
       
      </div>

      <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label>TVA</label>
        <input type="text" formControlName="tva" [(ngModel)]="sub_contrator.tva"  class="form-control input-custom"  />
      
    </div>
  </div>

  <div class="form-row">
    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label>Rue</label>
        <input type="text" formControlName="address"  [(ngModel)]="sub_contrator.address" class="form-control input-custom"  />
     
    </div>
    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <label>Numéro</label>
      <input type="text" formControlName="number"  [(ngModel)]="sub_contrator.number" class="form-control input-custom"  />
    
  </div>
</div>

<div class="form-row">
  <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <label>Code postal</label>
      <ng-select [items]="codes_postales"
          bindLabel="postal_code"
          formControlName="postalCode"
          class="form-control input-custom"
          [(ngModel)]="sub_contrator.postalCode"
          [closeOnSelect]="true"
          (change)="getCity()"
          >
          </ng-select>
   
  </div>
  <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
    <label>Ville</label>
    <input *ngIf="changeCity == false" disabled type="text"  formControlName="city" [(ngModel)]="sub_contrator.city" class="form-control input-custom"  />
    <input *ngIf="changeCity == true" disabled type="text"  formControlName="city" [(ngModel)]="this.city_codePostal" class="form-control input-custom"  />
  
</div>
</div>

    <div class="form-row">
      <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label>Contact(s) </label>
        <ng-select [items]="contacts"
              bindLabel="firstName_lastName"
              [(ngModel)]="contactsbysubContractor"
              formControlName="contact"
              class="form-control input-custom"
              [closeOnSelect]="true"
              multiple = true
              (change)="getContactByThisSubContractors()"
              >
              </ng-select>
          

     
    </div>
     
    <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
      <label>Contact principal</label>
      <ng-select [items]="listContactsByThisSubContractor"
            bindLabel="firstName_lastName"
            [(ngModel)]="contactPrincipal_sub_contrator"
            formControlName="contactMain"
            class="form-control"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.contactMain.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && f.contactMain.errors" class="invalid-feedback">
              <div *ngIf="f.contactMain.errors.required">Champ obligatoire.</div>
            </div>

    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label>Numéro de téléphone</label>
        <br>
        <ngx-intl-tel-input
        [cssClass]="'form-control input-custom-phone'"
        formControlName="phone"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="true"
        [searchCountryFlag]="true"
        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="contryIsoSelected"
        [(ngModel)]="sub_contrator.phone"  
        [maxLength]="15"
        [phoneValidation]="true"
        name="phone"
       
      ></ngx-intl-tel-input>
      <div *ngIf="registerForm.value.phone && !f0.form.controls['phone'].invalid == false" style="color: red;">
        Numéro de téléphone non validé <i class="bi bi-x"></i>
    </div>
    <div *ngIf=" registerForm.value.phone && !f0.form.controls['phone'].invalid == true" style="color: green;">
      Numéro de téléphone  validé <i class="bi bi-check"></i>
       </div>

     
    </div>
    <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <label>Adresse mail</label>
      <input type="text" formControlName="email" class="form-control input-custom"   [(ngModel)]="sub_contrator.email"  
       [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
          <div *ngIf="f.email.errors.email">Merci de saisir une adresse mail valide.</div>
      </div>
  
</div>
    </div>
    <div class="form-row">
   
      <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label>Taux horaire(€/h)</label>
        <input type="number"  formControlName="tauxTime" min="0" onkeypress="return /[0-9.]/i.test(event.key)" pattern="[0-9]*\.?[0-9]*"
         class="form-control input-custom"  [(ngModel)]="sub_contrator.tauxTime"   />
     </div>
      <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <label>Ouvriers</label>
        <ng-select [items]="workers"
        formControlName="worker"
        [(ngModel)]="workersbysubContractor"
        bindLabel="firstName_lastName"
        multiple =true
        class="form-control input-custom"
        [closeOnSelect]="true"
        >
        </ng-select>
     </div>
      </div>




    

      <div class="form-group row" class="center">
        <label>Documents</label>
        <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <p style="color: #636363;">Déposer un fichier ici </p> 
              
              <a (click)="openFileSelector()"><i class="bi bi-file-earmark-arrow-down i-file"></i>
              </a>
            </ng-template>
        </ngx-file-drop>
        <div class="upload-table">
            <table class="table">
                <thead>
                    <tr>
                        <th>Fichier :</th>
                    </tr>
                </thead>
             
                <!-- <h2>fff  {{listFiles | json }}</h2> -->
                <tbody *ngIf="listFiles" class="upload-name-style">
                    <tr *ngFor="let item of listFiles; let i=index">
                        <td><strong>{{ item }}</strong></td>
                        <!-- <td><i (click)="deleteFileFromserver(myUUID,item)" style="color: #0e309f;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i></td> -->
                      
                        <td>
                        <i  *ngIf="InitListeFiles.includes(item.toString())"   (click)="openFileInNewWindow(sub_contrator.key,item)" style="color: #0e309f;cursor: pointer;" title="Visualiser" class="bi bi-eye"></i>
                        <i  *ngIf="!InitListeFiles.includes(item.toString())" style="color: #0e309f;cursor: not-allowed;"  class="bi bi-eye-slash"></i>
                        <i (click)="deleteFileFromListFiles(item)" style="color: #0e309f;cursor: pointer;padding-left: 5%" title="Supprimer" class="bi bi-trash"></i>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
      </div>


 
       
        <div style="padding-top: 3%;" class="text-center">
            
            <button routerLink="/admin/prestataires" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
            <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
        </div>
    </form>
  </div>
</div>
<br>



import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { WorkerService } from 'src/app/_services/worker.service';
import codesContries from 'src/app/codes_countries.json';


@Component({
  selector: 'app-update-worker',
  templateUrl: './update-worker.component.html',
  styleUrls: ['./update-worker.component.css','../../../../_shared/add_update.css']
})
export class UpdateWorkerComponent implements OnInit {

  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  registerForm: FormGroup;
  submitted = false;
  subContractorsByWorker :any
  worksite = [
    {  name: 'Chantier 1' },
    {  name: 'Chantier 2' },
    {  name: 'Chantier 3' },
   
  ]
  id
  worker
  contryIsoSelected :any
  subContractors : any 
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route : ActivatedRoute,
    private workerService :WorkerService,
    private subcontractorService :SubContractorService,
    public iziToast: Ng2IzitoastService) { }


  ngOnInit() {
    this.id = this.route.snapshot.params["id"];
    this.workerService.getWorkerById(this.id).subscribe(data => {
       this.worker = data;
      
       if(this.worker && this.worker.phone){
         let prefix = this.worker.phone.split(' ')
         if (prefix && prefix.length>0){
           let myCountry=codesContries.find(x=>x.dial_code === prefix[0])
           if(myCountry){
             this.contryIsoSelected = CountryISO[myCountry.name]
           }
           
         }
       }
       this.subContractorsByWorker =this.worker.subContractor

 
    this.registerForm = this.formBuilder.group({
        // title: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        subContractor :[],
        phone : ['', Validators.required],
        email:['', Validators.email],
        worksite: ['',],
        comment: ['', Validators.maxLength(200)], 
    },);
    this.getListSubContractors()
  })
  }
getListSubContractors() {
  this.subcontractorService.getList_subContractor().subscribe((e) => {
    this.subContractors = e;
  });
}
get f() { return this.registerForm.controls; }

onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    // if(this.registerForm.value.subContractor){
    //   this.registerForm.value.subContractor =  this.registerForm.value.subContractor._id
    // }
  
    this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber 
  
    if(this.registerForm.value.subContractor)
    this.registerForm.value.subContractor = this.registerForm.value.subContractor.map(item => item._id);
  
    this.workerService.EditWorker(this.id,this.registerForm.value).subscribe(
      (data) => {
       
        
        this.iziToast.show({
          message:'Modification avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
           this.router.navigate(['/admin/ouvriers' ])
          })
  
}

onReset() {
    // this.submitted = false;
    // this.registerForm.reset();

}



}

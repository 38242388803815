
<div class="mainbar">
 
  <div class="left">
  </div>
  <div *ngIf="user" class="right" style="padding-top: 7px;">
    <div class="profile mybox" id="">
      <h5 style="color: gray;font-weight: 500; " class="name">{{user.firstName}} <i class="fa fa-angle-down"></i></h5>

      <img class="pic" src="https://www.w3schools.com/w3css/img_avatar3.png" style="margin-left: 1.25rem;"/>

      <ul>
        <!-- <li><i class="fa fa-user"></i> Profil </li>
        <li><i class="fa fa-cog"></i> Config</li> -->
        <li class="separator"></li>
        <li (click)="logout()"><i class="bi bi-box-arrow-right"></i> Se déconnecter</li>
      </ul>

    </div>
  </div>
</div>




  
  
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ClientService } from 'src/app/_services/client.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import codesContries from 'src/app/codes_countries.json';


@Component({
  selector: 'app-update-contact',
  templateUrl: './update-contact.component.html',
  styleUrls: ['./update-contact.component.css','../../../../_shared/add_update.css']
})
export class UpdateContactComponent implements OnInit {
  yourForm: FormGroup; // Assurez-vous d'avoir initialisé correctement votre formulaire

  @ViewChild('companySelect', { static: false }) companySelect;
  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

id
contact
registerForm: FormGroup;
submitted = false;
idClient_subContractor 
gendres = ['Mr','Mme']
clients :any 
sub_contractors :any
clients_subContractors :any 
languages = ["FR","NL","EN"]
value: any;
contryIsoSelected:any =CountryISO.Belgium
listClientsByContact :any
listSubContractorsByContact : any
constructor(private formBuilder: FormBuilder,
  private router: Router,
  private contactService :ContactService,
  public iziToast: Ng2IzitoastService,
  private route : ActivatedRoute,
  private clientService :ClientService,
  private subContractorService :SubContractorService,
  private fb: FormBuilder) {
    this.yourForm = this.fb.group({
      companyClient: []
    });

    // Écoutez les changements dans le formulaire
    this.yourForm.get('companyClient').valueChanges.subscribe((selectedItems) => {
      // Ajustez la hauteur du champ de sélection
      if (this.companySelect) {
        this.companySelect.opened = true;
        this.companySelect.close();
        this.companySelect.opened = false;
      }
    });
   }


  ngOnInit(): void {
    this.id = this.route.snapshot.params["id"];
   this.contactService.getContactById(this.id).subscribe(data => {
      this.contact = data;
      
      if(this.contact && this.contact.phone){
        let prefix = this.contact.phone.split(' ')
        if (prefix && prefix.length>0){
          let myCountry=codesContries.find(x=>x.dial_code === prefix[0])
          if(myCountry){
            this.contryIsoSelected = CountryISO[myCountry.name]
          }
          
        }
      }

      this.listClientsByContact =this.contact.companyClient
      this.listSubContractorsByContact =this.contact.companySubContractor
     
   
  

  this.registerForm = this.formBuilder.group({
    gendre: [''],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    companyClient: [],
    companySubContractor: [],
    phone: ['',],
    email: ['', [Validators.required, Validators.email]],
    language: ['',],
    comment: ['', Validators.maxLength(200)], },);
})
this.getClients_subContractors()
this.getClients()
this.getSubContractors()
}

getClients_subContractors(){
this.contactService.getList_clients_subContractors().subscribe((e) => {
this.clients_subContractors = e;
});

}

getClients() {
  this.clientService.getClients().subscribe((e) => {
    this.clients = e;
  });
}

getSubContractors() {
  this.subContractorService.getList_subContractor().subscribe((e) => {
    this.sub_contractors = e;
  });
}
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }
  if(this.registerForm.value.company){
    this.registerForm.value.company =  this.registerForm.value.company._id
  }

  if(this.registerForm.value.phone){
    this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber 
  }

 this.contactService.EditContact(this.id,this.registerForm.value).subscribe(
  (data) => {
   
    
    this.iziToast.show({
      message:'Modification avec succès',
      messageColor:'#386641',
      progressBarColor:'#6a994e',
      icon:'bi-check-circle',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#dde5b6',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
       });
       this.router.navigate(['/admin/contacts' ])
      })
}
}

<div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
  <!-- Vertical Navbar -->
  <nav class="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical" style="background-color: #0E309F">
      <div class="container-fluid">
          <!-- Toggler -->
          <button class="navbar-toggler ms-n2" (click)="changenavbarclass()" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Brand -->
          <a class="navbar-brand py-lg-5 mb-lg-4 px-lg-12 me-1" routerLink="/admin/chantiers/suppafacs">
              <img src="assets/fullcleaning-logo.png" alt="..." height="100%">
          </a>
          <div class="navbar-user d-lg-none">
              <!-- Dropdown -->
              <div class="dropdown">
                  <!-- Toggle -->
                  <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="avatar-parent-child">
                          <span class="avatar-child avatar-badge bg-success"></span>
                      </div>
                  </a>
                  <!-- Menu -->
              </div>
          </div>
          <!-- Collapse -->
          <div [ngClass]="navbarclass" id="sidebarCollapse">
              <!-- Navigation -->
              <ul class="navbar-nav">
                  <!-- <li class="nav-item">
                      <a class="nav-link li-menu-left" routerLink="/admin">
                          <i class="bi bi-bar-chart-fill"></i> Tableau de bord
                      </a>
                  </li> -->
                  <li class="nav-item">
                    <a class="nav-link li-menu-left" routerLink="chantiers/suppafacs" [ngClass]="{ 'active': curentUrl === 'suppafacs' }" >
                      <i class="bi bi-calculator" [ngClass]="{ 'active': curentUrl === 'suppafacs' }" ></i>Suppafacs
                    </a>
                </li>
                  <li class="nav-item" >
                    <a class="nav-link li-menu-left" routerLink="etablissements" [ngClass]="{ 'active': curentUrl === 'etablissements' }">
                      <i class="bi bi-bricks" [ngClass]="{ 'active': curentUrl === 'etablissements' }"></i>
                      Établissements
                    </a>
                </li>
               
                  <li class="nav-item hover-link" >
                      <a style="cursor: pointer;" class="nav-link li-menu-left" (click)="gotoListSites()" [ngClass]="{ 'active': curentUrl === 'chantiers' }" >
                        <i class="bi bi-building" [ngClass]="{ 'active': curentUrl === 'chantiers' }"></i>Chantiers
                      </a>
                  </li>
                  <li class="nav-item" >
                    <a class="nav-link li-menu-left" routerLink="prestations" [ngClass]="{ 'active': curentUrl === 'prestations' }" >
                      <i class="bi bi-card-checklist" [ngClass]="{ 'active': curentUrl === 'prestations' }"></i>
                      Prestations
                    </a>
                </li>
                <li class="nav-item" >
                  <a class="nav-link li-menu-left" routerLink="chantiers/vitres" [ngClass]="{ 'active': curentUrl === 'vitres' }"  >
                    <i class="bi bi-border-all" [ngClass]="{ 'active': curentUrl === 'vitres' }"></i>
                    Vitres
                  </a>
              </li>
                 
                  <li class="nav-item" >
                    <a class="nav-link li-menu-left" routerLink="clients" [ngClass]="{ 'active': curentUrl === 'clients' }" >
                      <i class="bi bi-people-fill" [ngClass]="{ 'active': curentUrl === 'clients' }"></i>
                      Clients
                    </a>
                </li>

                  <li class="nav-item" >
                      <a class="nav-link li-menu-left" routerLink="contacts" [ngClass]="{ 'active': curentUrl === 'contacts' }">
                        <i class="bi bi-file-person-fill" [ngClass]="{ 'active': curentUrl === 'contacts' }"></i>
                        Contacts
                      </a>
                  </li>
                  <li class="nav-item" >
                    <a class="nav-link li-menu-left" routerLink="prestataires" [ngClass]="{ 'active': curentUrl === 'prestataires' }">
                      <i class="bi bi-briefcase-fill" [ngClass]="{ 'active': curentUrl === 'prestataires' }"></i>
                      Prestataires
                    </a>
                </li>
                <li class="nav-item" >
                  <a class="nav-link li-menu-left" routerLink="ouvriers" [ngClass]="{ 'active': curentUrl === 'ouvriers' }">
                    <i class="bi bi-diagram-3" [ngClass]="{ 'active': curentUrl === 'ouvriers' }"></i>
                    Ouvriers
                  </a>
              </li>
          
                <li class="nav-item" >
                    <a class="nav-link li-menu-left"  routerLink="documents" [ngClass]="{ 'active': curentUrl === 'documents' }">
                      <i class="bi bi-file-earmark-text-fill" [ngClass]="{ 'active': curentUrl === 'documents' }"></i>
                      Documents
                    </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link li-menu-left"  routerLink="agenda" >
                    <i class="bi bi-calendar-week-fill"></i>
                    Calendrier
                  </a>
              </li> -->
              
                 
                 
              </ul>
          </div>
      </div>
  </nav>
  <!-- Main content -->
  
  <div class="h-screen flex-grow-1 overflow-y-lg-auto">
    <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-clip-rotate-pulse"
  >
    <p style="font-size: 20px; color: white">Veuillez patienter ...</p>
  </ngx-spinner>
    <router-outlet></router-outlet>
 </div>
 
</div>


import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashbordComponent } from "./admin/templ-admin/dashbord/dashbord.component";

import { TemplAdminComponent } from "./admin/templ-admin/templ-admin.component";
import { LoginComponent } from "./components/login/login.component";
import { AdminGuard } from "./_guards/admin-guard.guard";
import { AuthGuard } from "./_guards/auth.guard";
import { LoginGuard } from "./_guards/login.guard";
import { ResetPassowrdComponent } from "./components/reset-passowrd/reset-passowrd.component";
import { UpdatePassowrdComponent } from "./components/update-passowrd/update-passowrd.component";
import { ListContactComponent } from "./admin/templ-admin/list-contact/list-contact.component";
import { AddContactComponent } from "./admin/templ-admin/list-contact/add-contact/add-contact.component";
import { ListClientsComponent } from "./admin/templ-admin/list-clients/list-clients.component";
import { AddClientComponent } from "./admin/templ-admin/list-clients/add-client/add-client.component";
import { ListSubContractorsComponent } from "./admin/templ-admin/list-sub-contractors/list-sub-contractors.component";
import { AddSubContractorComponentComponent } from "./admin/templ-admin/list-sub-contractors/add-sub-contractor-component/add-sub-contractor-component.component";
import { ListWorkersComponent } from "./admin/templ-admin/list-workers/list-workers.component";
import { AddWorkerComponent } from "./admin/templ-admin/list-workers/add-worker/add-worker.component";
import { ListSitesComponent } from "./admin/templ-admin/list-sites/list-sites.component";
import { AddSiteComponent } from "./admin/templ-admin/list-sites/add-site/add-site.component";
import { ListGlassComponent } from "./admin/templ-admin/list-sites/list-glass/list-glass.component";
import { AddGlassComponent } from "./admin/templ-admin/list-sites/list-glass/add-glass/add-glass.component";
import { ListSuppafacsComponent } from "./admin/templ-admin/list-sites/list-suppafacs/list-suppafacs.component";
import { AddSuppafacComponent } from "./admin/templ-admin/list-sites/list-suppafacs/add-suppafac/add-suppafac.component";
import { ListEtablissementComponent } from "./admin/templ-admin/list-etablissement/list-etablissement.component";
import { AddEtablissementComponent } from "./admin/templ-admin/list-etablissement/add-etablissement/add-etablissement.component";
import { DetailsEtablissementComponent } from "./admin/templ-admin/list-etablissement/details-etablissement/details-etablissement.component";
import { UpdateEtablissementComponent } from "./admin/templ-admin/list-etablissement/update-etablissement/update-etablissement.component";
import { UpdateContactComponent } from "./admin/templ-admin/list-contact/update-contact/update-contact.component";
import { DetailsContactComponent } from "./admin/templ-admin/list-contact/details-contact/details-contact.component";
import { DetailsClientComponent } from "./admin/templ-admin/list-clients/details-client/details-client.component";
import { UpdateClientComponent } from "./admin/templ-admin/list-clients/update-client/update-client.component";
import { DetailsWorkerComponent } from "./admin/templ-admin/list-workers/details-worker/details-worker.component";
import { UpdateWorkerComponent } from "./admin/templ-admin/list-workers/update-worker/update-worker.component";
import { UpdateSubContractorComponent } from "./admin/templ-admin/list-sub-contractors/update-sub-contractor/update-sub-contractor.component";
import { ListDocumentsComponent } from "./admin/templ-admin/list-documents/list-documents.component";
import { DetailsSubContractorComponent } from "./admin/templ-admin/list-sub-contractors/details-sub-contractor/details-sub-contractor.component";
import { DetailsSiteComponent } from "./admin/templ-admin/list-sites/details-site/details-site.component";
import { UpdateSiteComponent } from "./admin/templ-admin/list-sites/update-site/update-site.component";
import { ListBenefitsComponent } from "./admin/templ-admin/list-benefits/list-benefits.component";
import { AddBenefitComponent } from "./admin/templ-admin/list-benefits/add-benefit/add-benefit.component";
import { ListoccurencesComponent } from "./admin/templ-admin/listoccurences/listoccurences.component";
import { ListDocumentsArchivedComponent } from "./admin/templ-admin/list-documents/list-documents-archived/list-documents-archived.component";
import { ListWorkersArchivedComponent } from "./admin/templ-admin/list-workers/list-workers-archived/list-workers-archived.component";
import { ListSubContractorsArchivedComponent } from "./admin/templ-admin/list-sub-contractors/list-sub-contractors-archived/list-sub-contractors-archived.component";
import { ListContactsArchivedComponent } from "./admin/templ-admin/list-contact/list-contacts-archived/list-contacts-archived.component";
import { ListClientsArchivedComponent } from "./admin/templ-admin/list-clients/list-clients-archived/list-clients-archived.component";
import { ListEtablissementArchivedComponent } from "./admin/templ-admin/list-etablissement/list-etablissement-archived/list-etablissement-archived.component";
import { CalendarComponent } from "./admin/templ-admin/list-benefits/calendar/calendar.component";
import { DetailsBenefitComponent } from "./admin/templ-admin/list-benefits/details-benefit/details-benefit.component";
import { DetailsSuppafacComponent } from "./admin/templ-admin/list-sites/list-suppafacs/details-suppafac/details-suppafac.component";
import { ListSuppafacsArchivedComponent } from "./admin/templ-admin/list-sites/list-suppafacs/list-suppafacs-archived/list-suppafacs-archived.component";
import { UpdateSuppafacComponent } from "./admin/templ-admin/list-sites/list-suppafacs/update-suppafac/update-suppafac.component";
import { DetailsGlassComponent } from "./admin/templ-admin/list-sites/list-glass/details-glass/details-glass.component";
import { UpdateBenefitComponent } from "./admin/templ-admin/list-benefits/update-benefit/update-benefit.component";
import { UpdateGlassComponent } from "./admin/templ-admin/list-sites/list-glass/update-glass/update-glass.component";
import { ListTypesBenfitsComponent } from "./admin/templ-admin/list-benefits/list-types-benfits/list-types-benfits.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/connexion",
    pathMatch: "full",
    canActivate: [LoginGuard],
  },
  { path: "connexion", component: LoginComponent, canActivate: [LoginGuard] },
  {
    path: "mot-de-passe-oublie",
    component: ResetPassowrdComponent,
    canActivate: [LoginGuard],
  },
  {
    path: "reinitialisation/:token",
    component: UpdatePassowrdComponent,
  },
  {
    path: "admin",
    component: TemplAdminComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: [
       { path: "", component: ListSuppafacsComponent },
      { path: "contacts", component: ListContactComponent },
      { path: "contacts/ajout", component: AddContactComponent },
      { path: "contacts/modification/:id", component: UpdateContactComponent },
      { path: "contacts/details/:id", component: DetailsContactComponent },
      { path: "clients", component: ListClientsComponent },
      { path: "clients/ajout", component: AddClientComponent },
      { path: "clients/details/:id", component: DetailsClientComponent },
      { path: "clients/modification/:id", component: UpdateClientComponent },
      { path: "prestataires", component: ListSubContractorsComponent },
      {
        path: "prestataires/ajout",
        component: AddSubContractorComponentComponent,
      },
      {
        path: "prestataires/modification/:id",
        component: UpdateSubContractorComponent,
      },
      {
        path: "prestataires/details/:id",
        component: DetailsSubContractorComponent,
      },
      { path: "ouvriers", component: ListWorkersComponent },
      { path: "ouvriers/ajout", component: AddWorkerComponent },
      { path: "ouvriers/details/:id", component: DetailsWorkerComponent },
      { path: "ouvriers/modification/:id", component: UpdateWorkerComponent },
      { path: "chantiers", component: ListSitesComponent },
      { path: "chantiers/ajout", component: AddSiteComponent },
      { path: "chantiers/details/:id", component: DetailsSiteComponent },
      { path: "chantiers/modification/:id", component: UpdateSiteComponent },
      { path: "chantiers/vitres", component: ListGlassComponent },
      { path: "chantiers/vitres/ajout", component: AddGlassComponent },
      { path: "chantiers/vitres/modification/:id",component:UpdateGlassComponent},
      { path: "chantiers/vitres/details/:id", component: DetailsGlassComponent },
      { path: "chantiers/suppafacs", component: ListSuppafacsComponent },
      { path: "chantiers/suppafacs/archives", component: ListSuppafacsArchivedComponent },
      { path: "chantiers/suppafacs/ajout", component: AddSuppafacComponent },
      { path: "chantiers/suppafacs/details/:id", component: DetailsSuppafacComponent },
      { path: "chantiers/suppafacs/modification/:id", component: UpdateSuppafacComponent },
      { path: "etablissements", component: ListEtablissementComponent },
      { path: "etablissements/ajout", component: AddEtablissementComponent },
      {
        path: "etablissements/details/:id",
        component: DetailsEtablissementComponent,
      },
      {
        path: "etablissements/modification/:id",
        component: UpdateEtablissementComponent,
      },
      { path: "documents", component: ListDocumentsComponent },
      { path: "prestations", component: ListBenefitsComponent },
      { path: "prestations/ajout", component: AddBenefitComponent },
      { path: "prestations/details/:id", component: DetailsBenefitComponent },
      { path: "prestations/calendrier/:id", component: CalendarComponent },
      { path: "prestations/modification/:id", component: UpdateBenefitComponent },
      { path: "prestations/types", component: ListTypesBenfitsComponent },
      { path: "occurrences/:id", component: ListoccurencesComponent },
      { path: "documents/archives", component: ListDocumentsArchivedComponent },
      { path: "ouvriers/archives", component: ListWorkersArchivedComponent },
      {
        path: "prestataires/archives",
        component: ListSubContractorsArchivedComponent,
      },
      { path: "contacts/archives", component: ListContactsArchivedComponent },
      { path: "clients/archives", component: ListClientsArchivedComponent },
      {
        path: "etablissements/archives",
        component: ListEtablissementArchivedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

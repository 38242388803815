import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubContractorService {

  private _subContractorURL=`${environment.baseUrl}/sub-contractor`
  constructor(private http: HttpClient) { }

  uploadFiles(files,key):Observable <any> {
    return this.http.post(`${this._subContractorURL}/upload/file/${key}`,files);
        }

        create_subContractor(subContractorURL: any):Observable<Object> {
          return this.http.post(`${this._subContractorURL}`,subContractorURL);
        }

    getList_subContractor() :Observable <any> {
      return this.http.get(`${this._subContractorURL}`);
      }
       // updated -- organisé
     getSubContractorsStorm() :Observable <any> {
      return this.http.get(`${this._subContractorURL}/listStormSubContractors`);
      }


      archive_restoreSubContractor(id :any){
        return this.http.get(`${this._subContractorURL}/archive-restoreSubContractor/${id}`);
          }   
          getSubContractorArchiveds() :Observable <any> {
            return this.http.get(`${this._subContractorURL}/list_subContractor/archived`);
            }

      getSubContratcorById(id :any){
        return this.http.get(`${this._subContractorURL}/${id}`);
          }       



          getContactsBySub_contractor(id : string):Observable<any>
          {
            return this.http.get<any>(`${this._subContractorURL}/contacts/bysubContractor/${id}`);
          } 
          getWorkersBySub_contractor(id : string):Observable<any>
          {
            return this.http.get<any>(`${this._subContractorURL}/workers/bysubContractor/${id}`);
          } 
          
          EditSubContractor(id:string,sub_contractor:any):Observable<Object> {
            return this.http.put(`${this._subContractorURL}/${id}`,sub_contractor);
          }


          // get list documents in subcontrator 
          listNamesFiles(id : string):Observable<any>
          {
            return this.http.get<any>(`${this._subContractorURL}/files/listNames/${id}`);
          } 

          // get list documents in subcontrator 
          deleteFileFromListFilesINServer(key : string,fileName : String):Observable<any>
          {
            return this.http.delete<any>(`${this._subContractorURL}/delete-file/${key}/${fileName}`);
          }
          
        
}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import codes from 'src/app/BE_Postal_codes.json';
import { PopupAddClientComponent } from '../../popupAdd/popup-add-client/popup-add-client.component';
import { PopupAddContactComponent } from '../../popupAdd/popup-add-contact/popup-add-contact.component';
import { PopupAddWorkerComponent } from '../../popupAdd/popup-add-worker/popup-add-worker.component';
import { PopupAddSubContractorComponent } from '../../popupAdd/popup-add-sub-contractor/popup-add-sub-contractor.component';
import { PopupAddBenefitComponent } from '../../popupAdd/popup-add-benefit/popup-add-benefit.component';

@Component({
  selector: 'app-popup-add-site',
  templateUrl: './popup-add-site.component.html',
  styleUrls: ['./popup-add-site.component.css']
})
export class PopupAddSiteComponent implements OnInit {

  public files: NgxFileDropEntry[] = [];
  registerForm: FormGroup;
  submitted = false;
  frequencerecuperateFromList
  listNamesServices : any
  listNamesWorkers : any
  
  codes_postales = codes 
  listMonths =["01","02","03","04","05","06","07","08","09","10","11","12",]

  contact = [
    {  name: 'Exemple 1' },
    {  name: 'Exemple 2' },
    {  name: 'Exemple 3' },
    {  name: 'Exemple 4' },
  ]

  clients = [
    {  name: 'Client 1' },
    {  name: 'Client 2' },
    {  name: 'Client 3' },
    {  name: 'Client 4' },
  ]
  workers = [
    {  name: 'Ouvrier 1' },
    {  name: 'Ouvrier 2' },
    {  name: 'Ouvrier 3' },
   
  ]
  listestablishment = [
    {  name: 'Etablissement  1' },
    {  name: 'Etablissement  2' },
    {  name: 'Etablissement  3' },
   
  ]
  listSiteManager = [
    {  name: 'Solenn' },
    {  name: 'Karen' },
    {  name: 'Valérie' },
  ]
  services =  [
    {  name: 'prestation 1' , frequence : '' ,siteDeclaration :false ,declarationDone :false,ServiceProvider :{  name: '' } , workers :[]},
    {  name: 'prestation 2'  , frequence : '' ,siteDeclaration :false , declarationDone :false,ServiceProvider :{  name: '' } ,workers :[]},
    {  name: 'prestation 3'  , frequence : '' ,siteDeclaration :false , declarationDone :false,ServiceProvider :{  name: '' }, workers :[]},
  ]

  ServiceProvider =  [
    {  name: 'Prestataire 1' },
    {  name: 'Prestataire 2' },
    {  name: 'Prestataire 3' },
  ]
  
  frequences =["hebdomadaire","mensuelle","trimestrielle","quadrimestrielle","semestrielle ou annuelle"]
  billingFrequency =["12","41","46"]
  statuts = ["En cours","Terminé","One shot"]
  city: { country_code: string; postal_code: string; place_name: string; admin_name1: string; admin_code1: string; admin_name2: string; admin_code2: string; admin_name3: string; admin_code3: string; latitude: number; longitude: number; accuracy: number; coordinates: { lon: number; lat: number; }; };
  city_codePostal: string;



  SiteDeclaration = false 
  closeResult: string;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    public modal: NgbActiveModal) { }


  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      establishment : ['', ],
      name: ['', ],
      siteNumber: ['', ],
      // street : ['', ],
      // number : ['', ],
      adress:this.formBuilder.array([]),
      postalCode : ['', ],
      city : ['', ],
      siteFloor : ['', ],
      siteManager : ['', ],
      client : ['', ],
      contact: ['', ],
      startdate :['', ],
      service : ['', ],
      // frequence : ['', ],
      // listMonths :['',], // liées au type de fréquence
      // note : ['', ],
      // durationService :['', ],
      // ServiceProvider :['', ],
      //billingFrequency:['', ],
      statut : ['', ],
      archive :['',],
      
      

      // acceptTerms: [false, Validators.requiredTrue]
  },);
  this.adress().push(this.formBuilder.group({
    street: "",
    number :""
    
  }));

}
adress() : FormArray {
  return this.registerForm.get("adress") as FormArray}
// convenience getter for easy access to form fields

addQuantity() {
  this.adress().push(this.newadress());
}
 
removeQuantity(i:number) {
  this.adress().removeAt(i);
}
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }

  // display form values on success
  //
  
  
  //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
 
  this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}

public dropped(files: NgxFileDropEntry[]) {
  this.files = files;
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file

        /**
        // You could upload it like this:
        const formData = new FormData()
        formData.append('logo', file, relativePath)

        // Headers
        const headers = new HttpHeaders({
          'security-token': 'mytoken'
        })

        this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
        .subscribe(data => {
          // Sanitized logo returned from backend
        })
        **/

      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
}

public fileOver(event){
}

public fileLeave(event){
}


getCity()
{
  if(this.registerForm.value.postalCode != null)
  {
  this.city = codes.find(el => el.postal_code === this.registerForm.value.postalCode.postal_code);
// => {name: "Albania", code: "AL"}
      this.registerForm.value.city = this.city
      this.city_codePostal = this.city["place_name"];
      this.registerForm.value.postalCode = this.city["postal_code"]
  }
  else{
    this.city_codePostal = ""
  }

}

getFreguence(frequence,name)
{
 
  for(let i=0 ;i<this.services.length;i++){
    if(this.services[i].name == name){
      this.services[i].frequence = frequence
    }
  }
  this.frequencerecuperateFromList = frequence
}

setListNamesServices()
{
  this.listNamesServices = this.registerForm.value.service
}


getEtatSiteDeclaration(etat,name)
{
  for(let i=0 ;i<this.services.length;i++){
    if(this.services[i].name == name){
      this.services[i].siteDeclaration = etat
      break
    }
  }
}

getStatutDeclarationDone(statut,name)
{
  const foundObject = this.services.find(item => item.name === name);

  foundObject["declarationDone"] = statut

}

recuperatePrestataire(prestataite,name)
{
  const element = this.services.find(obj => obj.name === name);
  if(prestataite != undefined){
  element.ServiceProvider['name'] = prestataite['name']
  }
  else{
    element.ServiceProvider['name'] = ''
  }

}

setListNamesWorkers(ouvrier , prestation)
{
  const element = this.services.find(obj => obj.name === prestation);
  if(ouvrier != undefined){
  element.workers.push(ouvrier)
  this.listNamesWorkers = element.workers
  }
  else{
    element.ServiceProvider['name'] = ''
  }
}

addClient(){
this.modalService.open(PopupAddClientComponent);
}
addContact(){
  this.modalService.open(PopupAddContactComponent);
  }
addWorker(){
    this.modalService.open(PopupAddWorkerComponent);
    }  
addSubContractor(){
  this.modalService.open(PopupAddSubContractorComponent);
  }   
addBenefit(){
    this.modalService.open(PopupAddBenefitComponent);
    }   
  
open(content) {
  this.modalService
    .open(content, { ariaLabelledBy: "modal-basic-title" })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return "by pressing ESC";
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return "by clicking on a backdrop";
  } else {
    return `with: ${reason}`;
  }
}

newadress(): FormGroup {
  return this.formBuilder.group({
    street : "",
    number : ""
    
  })
}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { SharedService } from 'src/app/_services/shared.service';
import { SuppafacService } from 'src/app/_services/suppafac.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-list-suppafacs',
  templateUrl: './list-suppafacs.component.html',
  styleUrls: ['./list-suppafacs.component.css','../../../../_shared/list.css']
})
export class ListSuppafacsComponent implements OnInit {
  descend =false
  listSuppafacsArchives = []
  copyListSuppafac :any
  criteria: SortCriteria;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  suppafacs :any
  decend = false
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  col5 = false
  col6 = false


  listeSites = []
  statuts =[]
  listeContact =[]
  ListeManager =[]
  suppafacArchived: any;
  dateToFiltred: any;
  titreToFiltred:any;
  sitesToFiltred:any;
  contactToFiltred:any;
  statutToFiltred:any;

  showDateRange: boolean = false;
  startDateToFiltred: string;
  endDateToFiltred: string;

  constructor( private router: Router 
    ,private sharedService :SharedService
    ,private suppafacService :SuppafacService 
    , public iziToast: Ng2IzitoastService) { 
    
    
  }

  ngOnInit(): void {
      this.getSupaafacs() 
      localStorage.removeItem("lastPageContactVisited")
      localStorage.removeItem("lastPageClientVisited")
      localStorage.removeItem("lastPageBenefitVisited")
      localStorage.removeItem("lastPageDocumentVisited")
      localStorage.removeItem("lastPageEtablissementVisited")
      localStorage.removeItem("lastPageSiteVisited")
      localStorage.removeItem("lastPageSubContractorVisited")
      localStorage.removeItem("lastPageWorkerVisited")
      localStorage.removeItem("lastPageGlassVisited")


      if(localStorage.getItem("lastPageSuppafacVisited") && 
      localStorage.getItem("lastPageSuppafacVisited") != undefined && 
      localStorage.getItem("lastPageSuppafacVisited") != "")
      this.page = Number(localStorage.getItem("lastPageSuppafacVisited"))   
  }
  getSupaafacs() {
    this.suppafacService.getSuppafacs().subscribe((e) => {
      this.suppafacs = e;
      this.copyListSuppafac= this.suppafacs
      this.listeSites =   Array.from(new Set(this.suppafacs.map(item => item.site)));
      this.listeContact =   Array.from(new Set(this.suppafacs.map(item => item.contact)));
      this.statuts =  Array.from(new Set(this.suppafacs.map(item => item.statut)));
      this.ListeManager = Array.from(new Set(this.suppafacs.map(item => item.manager)));
    });
  }
  create() {
    this.router.navigate(["admin/chantiers/suppafacs/ajout"]);
  }
  details(id){
    const url = `admin/chantiers/suppafacs/details/${id}`;
    window.open(url, '_blank');
  }
  update(id){
    this.router.navigate(["admin/chantiers/suppafacs/modification", id]);
  }


  onTableDataChange(event: any) {
    this.page = event;
    this.suppafacs = this.suppafacs;
    localStorage.setItem("lastPageSuppafacVisited",this.page.toString())
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getSupaafacs();
  }

  sort(column,descend,col)
  {


   
 switch (column) {
   
   case "date":
     if(this.col1 == false){
     this.col1 = true
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = false
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "title":  
   if(this.col2 == false){
     this.col1 = false
     this.col2 = true
     this.col3 = true
     this.col4 = false
     this.col5 = false
     this.col6 = false
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "site":
     if(this.col3 == false){  
     this.col1 = false
     this.col2 = false
     this.col3 = true
     this.col4 = false
     this.col5 = false
     this.col6 = false
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
     case "contact":   
     if(this.col4 == false){   
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = true
       this.col5 = false
       this.col6 = false

       
     }
     else
       {
         this.col1 = false
         this.col2 = false
         this.col3 = false
         this.col4 = false
         this.col5 = false
         this.col6 = false

       }
       break
   case "statut":   
   if(this.col5 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = true
     this.col6 = false

   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false

     }
     break
     case "manager":   
     if(this.col5 == false){   
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = true
  
     }
     else
       {
         this.col1 = false
         this.col2 = false
         this.col3 = false
         this.col4 = false
         this.col5 = false
         this.col6 = false
  
       }
       break
   default :
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
       break   

 }

 if (descend === false) {
  this.criteria = {
    property: column,
    descending: true
  };
  this.descend = true;
} else {
  this.criteria = {
    property: column,
    descending: false
  };
  this.descend = false;
}
}
getSuppafac(suppafac) {
  this.suppafacArchived = suppafac;
}

exportToExcel(suppafacs: any[]): void {
  const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
  const data = suppafacs.map(supp => {
    let formattedDate = '';
    if (!isNaN(Date.parse(supp.date))) {
      formattedDate = new Date(supp.date).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    }
    return {
      'DATE': formattedDate,
      'CHANTIER': supp.site,
      'TITRE SUPPAFAC ': supp.title,
      'CONTACT':supp.contact,
      'STATUT': supp.statut,
      'GÉRÉ PAR': supp.manager
    };
  });
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
  const columnWidths = [
    { wpx: 120 }, // Date
    { wpx: 200 }, // Chantier
    { wpx: 200 }, // Titre suppafac
    { wpx: 150 }, // Contact
    { wpx: 100 }, // Statut
    { wpx: 200 }, // Gerer par
  ];

  // Apply column widths
  ws['!cols'] = columnWidths;
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Suppafac');
  const filename = `${currentDate}_suppafacs.xls`;
  XLSX.writeFile(wb, filename );
}


archiveSuppafac(suppafac :any)
{
  suppafac = this.suppafacArchived;
    let index = this.suppafacs.findIndex(supp => supp._id === suppafac._id);
    let lastDigit = index % 10;
     if (lastDigit === 0) {
      this.page--;
      localStorage.setItem("lastPageSuppafacVisited",this.page.toString())
    }
    this.suppafacService.archive_restoreSuppafac(suppafac._id)
      .subscribe((e) => {
        this.iziToast.show({
          message: "Archivage avec succès",
          messageColor: "#343A40",
          progressBarColor: "#212529",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#CED4DA",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.router.navigate(["admin/chantiers/suppafacs/archives"]);

      });
}

setVariableArchive(){
  this.sharedService.setVariable("archive");
}

setVariableNotArchive(){
  this.sharedService.setVariable("NotArchive");
}


gotoListArchivedSites() //gotoListArchivedSites
{
  this.router.navigate(['/admin/chantiers' ])
  this.setVariableArchive()
}

gotoListSites() //gotoListNotArchivedSites
{
  this.router.navigate(['/admin/chantiers' ])
  this.setVariableNotArchive()
}
filterByStatut(event){
  this.statutToFiltred = event
}
filterBySites(event){
  this.sitesToFiltred = event
}
filterByContact(event){
  this.contactToFiltred = event
}

// filter() {
//   this.suppafacs = this.copyListSuppafac;
//   let filteredSuppafacs = [...this.copyListSuppafac];
//   const dateFilterLower = this.dateToFiltred ? this.dateToFiltred.toLowerCase() : '';
//   const titreFilterLower = this.titreToFiltred ? this.titreToFiltred.toLowerCase() : '';
//   const sitesFilterLower = this.sitesToFiltred ? this.sitesToFiltred.toLowerCase() :'' ;
//   const contactFilterLower = this.contactToFiltred ? this.contactToFiltred.toLowerCase() : '';
//   const statutFilterLower = this.statutToFiltred ? this.statutToFiltred.toLowerCase() : '';
//   filteredSuppafacs = this.suppafacs.filter(item => {
//     //const dateFilter = dateFilterLower ? new Date(item.date) >= new Date(dateFilterLower) : true;
//     const dateFilter = dateFilterLower ? new Date(item.date).getTime() === new Date(dateFilterLower).getTime() : true;
//     const titreFilter = titreFilterLower ? item.title.toLowerCase().includes(titreFilterLower) : true;
//     const sitesFilter = sitesFilterLower ? item.site.toLowerCase() === sitesFilterLower : true;
//     const contactFilter = contactFilterLower ? item.contact.toLowerCase() === contactFilterLower : true;
//     const statutFilter = statutFilterLower ? item.statut.toLowerCase() === statutFilterLower : true;


//     return   dateFilter && titreFilter && sitesFilter && contactFilter && statutFilter;
//   });
//    this.suppafacs = filteredSuppafacs;

//    // Reset pagination
//    this.page = 1;
// }
filter(){
  this.suppafacs = this.copyListSuppafac;
  let filteredSuppafacs = [...this.copyListSuppafac];
  const dateFilterLower = this.dateToFiltred ? new Date(this.dateToFiltred.toLowerCase()) : null;
  const titreFilterLower = this.titreToFiltred ? this.titreToFiltred.toLowerCase() : '';
  const sitesFilterLower = this.sitesToFiltred ? this.sitesToFiltred.toLowerCase() :'' ;
  const contactFilterLower = this.contactToFiltred ? this.contactToFiltred.toLowerCase() : '';
  const statutFilterLower = this.statutToFiltred ? this.statutToFiltred.toLowerCase() : '';
  const dateDebutFilter = this.startDateToFiltred ? new Date(this.startDateToFiltred.toLowerCase()) : null;
  const dateFinFilter = this.endDateToFiltred ? new Date(this.endDateToFiltred.toLowerCase()) : null;
  filteredSuppafacs = this.suppafacs.filter(item => {
    const dateFilter = dateFilterLower ? new Date(item.date).getTime() === dateFilterLower.getTime() : true;
    const titreFilter = titreFilterLower ? item.title.toLowerCase().includes(titreFilterLower) : true;
    const sitesFilter = sitesFilterLower ? item.site.toLowerCase() === sitesFilterLower : true;
    const contactFilter = contactFilterLower ? item.contact.toLowerCase() === contactFilterLower : true;
    const statutFilter = statutFilterLower ? item.statut.toLowerCase() === statutFilterLower : true;
    const dateDebutCheck = dateDebutFilter ? new Date(item.date).getTime() >= dateDebutFilter.getTime() : true;
    const dateFinCheck = dateFinFilter ? new Date(item.date).getTime() <= dateFinFilter.getTime() : true;
    return dateFilter && titreFilter && sitesFilter && contactFilter && statutFilter && dateDebutCheck && dateFinCheck;
  });
  this.suppafacs = filteredSuppafacs;
  // Reset pagination
  this.page = 1;
}
resetFilters() {
  this.dateToFiltred = ""; 
  this.titreToFiltred = "";
  this.sitesToFiltred = undefined;
  this.contactToFiltred = undefined; 
  this.statutToFiltred = undefined; 
  this.startDateToFiltred = ""; 
  this.endDateToFiltred = ""; 

 this.filter();
}
toggleDateRange() {
  this.showDateRange = !this.showDateRange;
  // Réinitialiser les dates
  // if (!this.showDateRange) {
  //     this.endDateToFiltred = '';
  //     this.endDateToFiltred = '';
  //     this.filter();
  // }
}
}

<app-navbar></app-navbar>
<br>
<header class="bg-surface  pt-6">
  <div style="float: right;padding-right: 9%;" class="col-lg-5 col-md-6 col-sm-12">
    <ul class="breadcrumb float-md-right">
      <li class="breadcrumb-item">
        <a class="color-blue" style="cursor: default;"
          ><i class="zmdi zmdi-home"></i> Accueil</a
        >
      </li>
      <li class="breadcrumb-item active color-blue " style="cursor: default;">Vitres</li>
    </ul>
  </div>
  <br>
  <br>
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%;">
      <div class="row align-items-center">
          <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0 ">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1" >
              Liste des vitres
          </h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>

          <!-- Actions -->
          <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mt-4 text-sm-end">
          <div class="mx-n1">
            <a 
              (click)="create()"
              class="btn d-inline-flex btn-sm btn-primary mx-1"
            >
            
              <span class="button_list" style="color: #fff">Créer une prestation vitres</span>
            </a>
            <a *ngIf="benefits && benefits.length > 0"
              
              class="btn d-inline-flex btn-sm btn-primary mx-1"
              (click)="exportToExcel(benefits)"
            >
            
              <span class="button_list" style="color: #fff">Export XLS</span>
            </a>
            <a *ngIf="benefits && benefits.length == 0" style="cursor : not-allowed;"
            class="btn d-inline-flex btn-sm btn-disabled mx-1">
            <span class="button_list">Export XLS</span>
         </a>
          </div>
        </div>
      </div>
      <!-- Nav -->
      <ul class="nav nav-tabs mt-4 overflow-x border-0">
        <!-- <li class="nav-item">
          <a
            (click)="getInstallers()"
            [ngClass]="{ current0: allInstallers }"
            class="nav-link font-regular"
            >{{ "instalatteur_subtitle_1" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <a
            (click)="getInstallersWithPremnuim()"
            [ngClass]="{ current0: premnuim }"
            class="nav-link font-regular"
            >{{ "instalatteur_subtitle_2" | translate }}</a
          >
        </li> -->
      </ul>
    </div>
  </div>
</header>

<main class="py-6 bg-surface-secondary">
  <div class="container-fluid" style="width: 86%;">
    <br>
    <div class="card shadow border-0 mb-7">
      <div class="card-header">
        <div class="row">
          <div class="row col-xl-11 col-lg-11 col-md-12 col-sm-12 col-12">
            <div class="form-outline col-xl-2 col-4-cust col-lg-6 col-md-6 col-sm-6">
              <label class="form-label" for="form1">Chantier</label>
              <ng-select [items]="ListChantier" (change)="filter()" class="form-control input-custom"
                [closeOnSelect]="true" [(ngModel)]="chantierToFiltred">
              </ng-select>
            </div>
            <div class="form-outline col-xl-2 col-4-cust col-lg-6 col-md-6 col-sm-6">
              <label class="form-label" for="form1">Type de prestation</label>
              <!-- <input type="search" id="form1" [(ngModel)]="nomToFiltred" (input)="filter()" class="form-control" /> -->
              <ng-select [items]="typesBenefit"  (change)="filter()" class="form-control input-custom"
                [closeOnSelect]="true" [(ngModel)]="nomToFiltred">
              </ng-select>
            </div>
            <div class="form-outline col-xl-2 col-4-cust col-lg-6 col-md-6 col-sm-6">
              <label class="form-label" for="form1">Fréquence</label>
              <ng-select [items]="ListFrequence" (change)="filterByFrequence($event)" class="form-control input-custom"
                [closeOnSelect]="true" [(ngModel)]="frequenceToFiltred" (change)="filter()">
              </ng-select>
            </div>
            <div class="form-outline col-xl-2 col-4-cust col-lg-6 col-md-6 col-sm-6">
              <label class="form-label" for="form1">Date de début</label>
              <input type="date" id="form1" [(ngModel)]="ddToFiltred" class="form-control" (input)="filter()" (change)="verifStartDate($event.target.value)" />
            </div>
            <div class="form-outline col-xl-2 col-4-cust col-lg-6 col-md-6 col-sm-6">
              <label class="form-label" for="form1">Date de fin</label>
              <input type="date" id="form1" [(ngModel)]="dfToFiltred" class="form-control" (input)="filter()" (change)="verifEndDate($event.target.value)"/>
            </div>
            <div class="form-outline col-xl-2 col-4-cust col-lg-6 col-md-6 col-sm-6">
              <label class="form-label" for="form1">Prestataire</label>
              <ng-select [items]="ListSubContractor" (change)="filter()" class="form-control input-custom"
                [closeOnSelect]="true" [(ngModel)]="sub_contratorToFiltred">
              </ng-select>
            </div>
          </div>
          <div class="col-sm-1 col-2">
            <label class="form-label"></label>
            <div class="d-flex justify-content-between">
              <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                  <style>svg{fill:#ffffff}</style>
                  <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;">
              <th style="cursor: pointer;" (click)="sort('site',descend,col1)" scope="col">
                chantier
                <i *ngIf="col1 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col1 == true" class="bi bi-chevron-up"></i>
              </th>
              <th style="cursor: pointer;" (click)="sort('type',descend,col2)" scope="col">
                Type de prestation
                <i *ngIf="col2 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col2 == true" class="bi bi-chevron-up"></i>
              </th>
              <th style="cursor: pointer;" (click)="sort('frequence',descend,col3)" scope="col">
                Fréquence
                <i *ngIf="col3 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col3 == true" class="bi bi-chevron-up"></i>
              </th>
              <th style="cursor: pointer;" (click)="sort('startDate',descend,col4)" scope="col">
                date de début
                <i *ngIf="col4 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col4 == true" class="bi bi-chevron-up"></i>
                
              </th>
              <th style="cursor: pointer;" (click)="sort('endDate',descend,col5)" scope="col">
                date de fin
                <i *ngIf="col5 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col5 == true" class="bi bi-chevron-up"></i>
              </th>
              <th style="cursor: pointer;" (click)="sort('subContractor',descend,col6)" scope="col">
                Prestataire
                <i *ngIf="col6 == false" class="bi bi-chevron-down"></i>
                <i *ngIf="col6 == true" class="bi bi-chevron-up"></i>
              </th>
           <!---->
              <th scope="col">actions</th>
            </tr>
          </thead>
          <tbody *ngFor="let i of benefits |sort: criteria | paginate
            : {
                itemsPerPage: tableSize,
                currentPage: page,
                totalItems: count
              };let k =index">
            <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;">
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.site}}</td>
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.type}}</td>
              <td *ngIf="i.frequence == 'Hebdomadaire' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Blue">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Mensuelle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Pink">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Trimestrielle 1-4-7-10' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Green">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Trimestrielle 2-5-8-11' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Peach">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Trimestrielle 3-6-9-12' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Lavender">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Quadrimestrielle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-SkyBlue">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Semestrielle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Salmon">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Annuelle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Grey">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Bi-annuelle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Black">{{i.frequence}}</span></td>

              <td style="cursor: pointer;" (click)="details(i._id)">{{i.startDate|date:'dd/MM/yyyy'}}</td>
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.endDate|date:'dd/MM/yyyy'}}</td>
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.subContractor}}</td>
              <!-- <td>
                <button *ngIf="!(i.frequence == 'Mensuelle' || i.frequence == 'Hebdomadaire')" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                  <i style="color: #0e309f;" class="fa fa-sort-numeric-asc" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualiser les récurrences" (click)="getBenefit(i)"></i>
              </button>
              </td> -->
              <td>
                <button
                class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2">
                <i
                  class="bi bi-pencil-square"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Modifier"
                  (click)="update(i._id)"
                
                ></i>
                </button>
              </td>
            </tr>
            <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;">
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.site}}</td>
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.type}}</td>
              <td *ngIf="i.frequence == 'Hebdomadaire' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Blue">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Mensuelle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Pink">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Trimestrielle 1-4-7-10' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Green">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Trimestrielle 2-5-8-11' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Peach">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Trimestrielle 3-6-9-12' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Lavender">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Quadrimestrielle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-SkyBlue">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Semestrielle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Salmon">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Annuelle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Grey">{{i.frequence}}</span></td>
              <td *ngIf="i.frequence == 'Bi-annuelle' " style="cursor: pointer;" (click)="details(i._id)"><span class="badge badge-Black">{{i.frequence}}</span></td>
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.startDate|date:'dd/MM/yyyy'}}</td>
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.endDate|date:'dd/MM/yyyy'}}</td>
              <td style="cursor: pointer;" (click)="details(i._id)">{{i.subContractor}}</td>
              <!-- <td>
                <button *ngIf="!(i.frequence == 'Mensuelle' || i.frequence == 'Hebdomadaire')" class="btn btn-sm btn-square btn-neutral text-danger-hover">
                  <i style="color: #0e309f;" class="fa fa-sort-numeric-asc" aria-hidden="true" data-toggle="tooltip" data-placement="bottom" title="Visualiser les récurrences" (click)="getBenefit(i)"></i>
              </button>
              
              </td> -->
              <td>
                <button
                class="btn btn-sm btn-square btn-neutral text-danger-hover mr-2">
                <i
                  class="bi bi-pencil-square"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Modifier"
                  (click)="update(i._id)"
                
                ></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
<div *ngIf=" benefits && benefits.length > 10 " class="card-footer border-0 py-5">
  <div class="d-flex justify-content-start">
    <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant"
      (pageChange)="onTableDataChange($event)">
    </pagination-controls>
  </div>
</div>
<div *ngIf="benefits && benefits.length == 0" class="table-responsive" style="text-align: center">
  <p style="padding-bottom: 45px; padding-top: 35px ">
    Aucune prestation vitres n'est trouvée
  </p>
</div>
<br>

</div>
</div>
</div>
</main>


  <ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-pulse"
>
  <p style="font-size: 20px; color: white">Veuillez patienter ...</p>
</ngx-spinner>



<!-- main app container -->
<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
  <ul class="breadcrumb float-md-right">
    <li class="breadcrumb-item"> 
      <a class="color-blue" style="cursor: default;" 
        ><i class="zmdi zmdi-home"></i> Accueil</a
      >
    </li>
    <li class="breadcrumb-item color-blue">
      <a class="color-blue" routerLink="../../">Chantiers
      </a>
    </li>
    <li class="breadcrumb-item active color-blue " style="cursor: default;">Modifier un chantier</li>
  </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" style="width: 92%">
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <h1 class="h2 mb-0 ls-tight color-blue title-1">Modifier un chantier</h1>
        </div>
        <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>

      </div>
    </div>
  </div>
</header>
<br />
<div class="card m-3-cust"  *ngIf="site">
  <div class="card-body ml-5">
    <form
      [formGroup]="registerForm"
      (ngSubmit)="onSubmit()"
      class="margin-right-50"
    >
      <div class="form-row">
        <div class="form-group col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label>Établissement</label>
          <ng-select
            [items]="listesEtablishment"
            [(ngModel)]="site.etablissement"
            bindLabel="name"
            formControlName="etablissement"
            class="form-control input-custom custom-ng-select"
            [closeOnSelect]="true"
            (change)="getNewIdEtablissement()"
            
          >
          <!--
             [ngClass]="{ 'is-invalid': submitted && f.contactMain.errors }"
              >
              </ng-select>
              <div *ngIf="submitted && f.contactMain.errors" class="invalid-feedback">
                <div *ngIf="f.contactMain.errors.required">Champ obligatoire.</div>
              </div>
          -->
          </ng-select>
        </div>
        <div class="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <label>Nom du chantier <b style="color: red;"> *</b></label>
          <input
            type="text"
            formControlName="name"
            [(ngModel)]="site.name"
            class="form-control input-custom"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
          />
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Champ obligatoire.</div>
          </div>
        </div>

        <div class="form-group col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <label> Numéro du chantier <b style="color: red;"> * </b></label>
          <input
            type="text"
            disabled
            formControlName="numberSite"
            [(ngModel)]="site.numberSite"
            class="form-control input-custom"
            [ngClass]="{ 'is-invalid': submitted && f.numberSite.errors }"
            />
            <div *ngIf="submitted && f.numberSite.errors" class="invalid-feedback">
              <div *ngIf="f.numberSite.errors.required">Champ obligatoire.</div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12" formArrayName="adress">
            <label>Adresse <b style="color: red;"> * </b>
              <i *ngIf="this.registerForm.value.adress[0].street  &&  this.registerForm.value.adress[0].number && this.registerForm.value.adress[0].postalCode" 
              style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addQuantity()"></i>
            </label>
            <div *ngFor="let quantity of adress().controls; let i=index" class="row" [formGroupName]="i">
              <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 margin-custom padding-custom" >
                <label *ngIf="i > 0">
                <i style="cursor: pointer;" class="bi bi-trash" (click)="removeQuantity(i)"></i>
                </label>  
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11">
               <label>Rue</label>
                  <input type="text" formControlName="street" class="form-control">
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label>Numéro</label>  
                  <input type="text" formControlName="number" min="0"  class="form-control">
                </div>
    
                <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 margin-custom padding-custom" >
                  <label *ngIf="i > 1000">
                  <i style="cursor: pointer;" class="bi bi-trash"></i>
                  </label>  
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11">
                  <label>Code postal</label>
                  <ng-select
                  [items]="codes_postales"
                  bindLabel="codePostal_placeName"
                  formControlName="postalCode"
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  (change)="getCity(i)"
                >
                </ng-select>
                   </div>
                   <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                     <label>Ville</label>  
                     <input 
                     disabled
                     type="text"
                     formControlName="city"
                    [value]="this.registerForm.value.adress[i].postalCode.place_name"
                     class="form-control input-custom"
                   />
                   </div>
                  <br>
          </div>
    
    </div>
    </div>

    <div class="form-row">
      <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <label>Type <b style="color: red;"> *</b></label>
        <ng-select
          [items]="typesSites"
          formControlName="type"
          [(ngModel)]="site.type"
          class="form-control input-custom"
          [closeOnSelect]="true"
          [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
          >
          </ng-select>
          <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
            <div *ngIf="f.type.errors.required">Champ obligatoire.</div>
          </div>
      </div>
    </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label>Étage du chantier </label>
          <input
            type="text"
            formControlName="floor"
            [(ngModel)]="site.floor"
            class="form-control input-custom"
          />
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label>Responsable<b style="color: red;"> * </b></label>
          <ng-select
            [items]="admins"
            [(ngModel)]="site.manager"
            bindLabel="firstName"
            formControlName="manager"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.manager.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && f.manager.errors" class="invalid-feedback">
              <div *ngIf="f.manager.errors.required">Champ obligatoire.</div>
            </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label
            >Client<b style="color: red;"> * </b>
          </label>
          <ng-select
            (click)="getClients()"
            (change)="getNewIdClient()"
            [items]="clients"
            [(ngModel)]="site.client"
            bindLabel="companyName"
            formControlName="client"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.client.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && f.client.errors" class="invalid-feedback">
              <div *ngIf="f.client.errors.required">Champ obligatoire.</div>
            </div>
        </div>
        <div *ngIf="this.registerForm.value.client" class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label
            >Contact(s)
          </label>
          <ng-select
            (click)="getContacts(this.registerForm.value.client._id)"
          
            [items]="contacts"
            [(ngModel)]="contactsIntialToSite"
            bindLabel="firstName_lastName"
            formControlName="contact"
            class="form-control input-custom"
            [closeOnSelect]="true"
            multiple="true"
          >
          </ng-select>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label>Date de début </label>
          <input
            type="date"
            formControlName="startDate"
            [(ngModel)]="site.startDate"
            class="form-control input-custom"
          />
        </div>
      
      </div>

    

      <div class="form-group row" class="center">
        <label>Documents</label>
        <ngx-file-drop  dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
            <ng-template  ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <p style="color: #636363;">Déposer vos fichiers ici </p> 
          
              <a (click)="openFileSelector()"><i class="bi bi-file-earmark-arrow-down i-file"></i>
               
              </a>
            </ng-template>
     
            <p style="color: #636363;">les types des fichiers doivent étre pdf,png,jpg,jpeg,txt </p> 
        </ngx-file-drop>
        <div class="upload-table">
            <table class="table">
                <thead>
                    <tr>
                        <th>Fichier :</th>
                    </tr>
                </thead>
                <!-- <h2>fff  {{listFiles | json }}</h2> -->
                <tbody *ngIf="listFiles" class="upload-name-style">
                  <tr *ngFor="let item of listFiles; let i=index">
                      <td><strong>{{ item }}</strong></td>
                      <!-- <td><i (click)="deleteFileFromserver(myUUID,item)" style="color: #0e309f;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i></td> -->
                    
                      <td>
                      <i  *ngIf="InitListeFiles.includes(item.toString())"   (click)="openFileInNewWindow(site.key,item)" style="color: #0e309f;cursor: pointer;" title="Visualiser" class="bi bi-eye"></i>
                      <i  *ngIf="!InitListeFiles.includes(item.toString())" style="color: #0e309f;cursor: not-allowed;"  class="bi bi-eye-slash"></i>
                      <i (click)="deleteFileFromListFiles(item)" style="color: #0e309f;cursor: pointer;padding-left: 5%" title="Supprimer" class="bi bi-trash"></i>
                      </td>

                  </tr>
              </tbody>
            </table>
        </div>
      </div>
      <br>
      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label>Statut <b style="color: red;"> *</b></label>
          <ng-select
            [items]="statuts"
            formControlName="statut"
            [(ngModel)]="site.statut"
            class="form-control input-custom"
            [closeOnSelect]="true"
            [ngClass]="{ 'is-invalid': submitted && f.statut.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && f.statut.errors" class="invalid-feedback">
              <div *ngIf="f.statut.errors.required">Champ obligatoire.</div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label>Note</label>
          <angular-editor
          formControlName="note"
          [(ngModel)]="site.note"
          [config]="editorConfig"
        ></angular-editor>
        </div>
    </div> 

      <div style="padding-top: 3%" class="text-center">
        <button
          routerLink="/admin/chantiers"
          class="btn btn-secondary mr-4 button_list mb-2"
          type="reset"
        >
          Annuler
        </button>
        <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
      </div>
    </form>
  </div>
</div>
<br>

<!-- credits -->

<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
  <ul class="breadcrumb float-md-right">
    <li class="breadcrumb-item"> 
      <a class="color-blue" style="cursor: default;" 
        ><i class="zmdi zmdi-home"></i> Accueil</a
      >
    </li>
    <li class="breadcrumb-item color-blue">
      <a class="color-blue" routerLink="../../">Prestations
      </a>
    </li>
    <li *ngIf="benefit" class="breadcrumb-item active color-blue " style="cursor: default;">{{benefit.name.type}}</li>
  </ul>
  </div>
  <br />
  <br />

</header>
<!-- Student Profile -->
<div class="student-profile py-4">
  <div *ngIf="benefit" class="container">
    <div class="col-sm-11 col-12 mb-4 mb-sm-0">
      <h1 class="h2 mb-0 ls-tight color-blue title-1" style="color: #62B9CB;"> {{benefit.name.type}}</h1>
      <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails d'une prestation</h1>
    </div>
    <div class="h2 mb-0 ls-tight text-sm-end">
      <a (click)="goToUpdateBenifit()" class="btn d-inline-flex btn-sm btn-primary mx-1">
        <span class="button_list" style="color: #fff">Modifier</span>
      </a>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="benefit" class="card shadow-sm">
          <div class="card-header bg-transparent">
            <h3 class="mb-0"><i class="bi bi-info-circle-fill"></i> Informations générales</h3>
          </div>
          <div class="card-body row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Type de prestation :</strong>
              <p class="mb-3 title-info">
                <span class="aucun-text" *ngIf="!benefit.name">Aucun</span>
                {{benefit.name.type}}
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Chantier :</strong>
              <p class="mb-3 title-info">
                <span class="aucun-text" *ngIf="!benefit.site.name">Aucun</span>
                {{benefit.site.name}}
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Fréquence :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.frequence">Aucun</span>
                  <span *ngIf="benefit.frequence == 'Hebdomadaire' " class="badge badge-Blue">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Mensuelle' " class="badge badge-Pink">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Trimestrielle 1-4-7-10' " class="badge badge-Green">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Trimestrielle 2-5-8-11' " class="badge badge-Peach">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Trimestrielle 3-6-9-12' " class="badge badge-Lavender">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Quadrimestrielle' " class="badge badge-SkyBlue">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Semestrielle' " class="badge badge-Salmon">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Annuelle' " class="badge badge-Grey">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Bi-annuelle' " class="badge badge-Black">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Quotidienne' " class="badge badge-Yellow">{{benefit.frequence}}</span>
                  <span *ngIf="benefit.frequence == 'Tous les 15 jours' " class="badge badge-PickBlue">{{benefit.frequence}}</span>

                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Durée :</strong><i class="bi bi-pencil" (click)="changeEtatDuration()" title="modifier la durée"
                 style="cursor: pointer;margin-left: 5%;"></i>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="(!benefit.duration || benefit.duration == ' ' ) && etatDuration == 0 ">Aucun</span>
                  <input class="form-control" *ngIf="(benefit.duration && benefit.duration != ' ') && etatDuration == 0 " [(ngModel)]="benefit.duration" disabled>
                  <input *ngIf="etatDuration == 1 " type="text" (blur)="onInputBlur($event)" [(ngModel)]="benefit.duration" (input)="updateDurationbenefit(benefit.duration)" class="form-control" >
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Date de début :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.startDate">Aucun</span>
                  {{benefit.startDate |date:'dd/MM/yyyy' }}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Date de fin :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.endDate">Aucun</span>
                  {{benefit.endDate |date:'dd/MM/yyyy'}}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Prestataire :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!benefit.subContractor.companyName">Aucun</span>
                  {{benefit.subContractor.companyName}}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Contrat de prestataire :</strong>
                <br>
                <i *ngIf="benefit.contratSubContracor == true" style="color: #28A745;" class="bi bi-circle-fill"></i> 
                <i *ngIf="benefit.contratSubContracor == false" style="color: #DC3545;" class="bi bi-circle-fill"></i> 
            </div>
          </div>
          <div class="card-body row">
            <div class="col-12">
                <strong class="pr-1 title-info">Note :</strong>
                  <p class="mb-3 title-info"> <span class="aucun-text" *ngIf="!benefit.note">Aucun</span>
                  {{benefit.note}}</p>
            </div>
            <div class="mt-4">
              <div class="d-flex justify-content-end">
                <div class="mr-3">
                  <i style="color: #28A745;" class="bi bi-circle-fill"></i> Oui
                </div>
                <div class="mr-3">
                  <i style="color: #DC3545;" class="bi bi-circle-fill"></i> Non 
                </div>
              </div>
            </div>
           </div>
      
        </div>
      </div>
    </div>
    <br>
    <div>
       <div *ngIf="benefit" class="card shadow-sm">
         <div class="card-header bg-transparent border-0">
           <h3 class="mb-0"><i class="bi bi-diagram-3 pr-1"></i> Liste des ouvriers de {{benefit.name.type}}</h3>
         </div>
         <div class="card-body row">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                  <th scope="col">Prénom </th>
                  <th scope="col">Nom </th>
                  <th scope="col">Téléphone</th>
                  </tr>
              </thead>
              <tbody *ngFor="let i of benefit.worker; let k =index">
                <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" (click)="goToDetailsWorker(i)">
                  <td> 
                   <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                   {{i.lastName }}</td>
                  <td>
                   <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                   {{i.firstName}}</td>
                  <td>
                   <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                   {{i.phone}}
                  </td>
                </tr>
                <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;" (click)="goToDetailsWorker(i)">
                  <td> 
                    <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                    {{i.lastName }}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                    {{i.firstName}}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                    {{i.phone}}
                   </td>
                </tr>
               
            
              </tbody>
            </table>
          </div>

           <div
           *ngIf="benefit.worker && benefit.worker.length == 0"
           class="table-responsive"
           style="text-align: center"
           >
           <p style="padding-bottom: 45px; padding-top: 35px ">
             Aucun ouvrier n'est affecté
           </p>
           </div>
         
         </div>
       </div>
 
     </div>

     <br>
     <div *ngIf="benefit && !(benefit.frequence == 'Mensuelle' || benefit.frequence == 'Hebdomadaire' 
     || benefit.frequence == 'Quotidienne'  || benefit.frequence == 'Tous les 15 jours') ">
        <div  class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="fa fa-sort-numeric-asc pr-1"></i> Liste des récurrences de {{benefit.name.type}}</h3>
          </div>
          <div class="card-body row">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                    <th scope="col">PRESTATION </th>
                    <th scope="col">DATE </th>
                    <th scope="col">TYPE DE JOUR</th>
                    <th scope="col">STATUT</th>
                    </tr>
                </thead>
                <tbody *ngFor="let i of listOccurrences ;let k =index">
                  <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" >
                    <td>{{i.name}}</td>
                    <td *ngIf="!i.toUpdated" style="cursor: pointer;" (click)="updateDate(i)"><p title="Cliquer pour modifier">{{i.date|date:'dd/MM/yyyy'}}</p></td> 
                    <td *ngIf="i.toUpdated">
                      <div class="row">
                        <div class="col-8"> <input type="date" [(ngModel)]="dateConverted" class="form-control input-custom"></div>
                        <div class="col-1"> 
                          <button
                          class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i
                          style="font-size: 28px;"
                          class="bi bi-check"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          (click)="onSubmit()"
                         
                        ></i></button>
                      </div>
                      <div class="col-1"> 
                        <button
                        class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i
                        style="font-size: 28px;"
                        class="bi bi-x"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        (click)="cancel(i)"
                       
                      ></i></button>
                    </div>
                      </div>
                    </td>   
                    <td *ngIf="i.type == 'default' " ><span class="badge badge-pill badge-success">Jour valide</span></td>
                    <td *ngIf="i.type == 'Weekend' "><span class="badge badge-danger">Weekend</span></td>
                    <td *ngIf="i.type == 'Holiday' "><span class="badge badge-danger">Jour ferié</span></td>
                    <td *ngIf="!i.toUpdatedStatus" style="cursor: pointer;" (click)="updateDateStatus(i)">
                      <p *ngIf="i.status == 'Planifiée' " title="Cliquer pour modifier"><i class="bi bi-circle-fill planned-color" ></i>
                      </p>
                      <p *ngIf="i.status == 'Effectuée' " title="Cliquer pour modifier"><i class="bi bi-circle-fill performed-color"></i>
                      </p>
                      <p *ngIf="i.status == 'Annulée' " title="Cliquer pour modifier"><i class="bi bi-circle-fill canceled-color"></i>
                      </p>
                      <p *ngIf="i.status == 'Modifiée' "title="Cliquer pour modifier"><i class="bi bi-circle-fill modified-color"></i>
                      </p>
                    </td>
                    <td *ngIf="i.toUpdatedStatus">
                      <div class="row">
                        <div class="col-8"> 
                        <ng-select
                        [items]="statuts"
                        [(ngModel)]="i.status"
                        class="form-control input-custom"
                        [closeOnSelect]="true"                    >
                        </ng-select>
                      </div>
                        <div class="col-1"> 
                          <button
                          class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i
                          style="font-size: 28px;"
                          class="bi bi-check"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          (click)="onSubmitStatut()"
                         
                        ></i></button>
                      </div>
                      <div class="col-1"> 
                        <button
                        class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i
                        style="font-size: 28px;"
                        class="bi bi-x"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        (click)="cancel(i)"
                       
                      ></i></button>
                    </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;">
                    <td>{{i.name}}</td>
                    <td *ngIf="!i.toUpdated" style="cursor: pointer;" (click)="updateDate(i)"><p title="Cliquer pour modifier">{{i.date|date:'dd/MM/yyyy'}}</p></td> 
                    <td *ngIf="i.toUpdated">
                      <div class="row">
                        <div class="col-8"> <input type="date" [(ngModel)]="dateConverted" class="form-control input-custom"></div>
                        <div class="col-1"> 
                          <button
                          class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i
                          style="font-size: 28px;"
                          class="bi bi-check"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          (click)="onSubmit()"
                         
                        ></i></button>
                      </div>
                      <div class="col-1"> 
                        <button
                        class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i
                         style="font-size: 28px;"
                        class="bi bi-x"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        (click)="cancel(i)"
                       
                      ></i></button>
                    </div>
                      </div>
                    </td>   
                    <td *ngIf="i.type == 'default' " ><span class="badge badge-pill badge-success">Jour valide</span></td> 
                    <td *ngIf="i.type == 'Weekend' "><span class="badge badge-danger">Weekend</span></td>
                    <td *ngIf="i.type == 'Holiday' "><span class="badge badge-danger">Jour ferié</span></td>
                    <td *ngIf="!i.toUpdatedStatus" style="cursor: pointer;" (click)="updateDateStatus(i)">
                      <p *ngIf="i.status == 'Planifiée' " title="Cliquer pour modifier"><i class="bi bi-circle-fill planned-color" ></i>
                      </p>
                      <p *ngIf="i.status == 'Effectuée' " title="Cliquer pour modifier"><i class="bi bi-circle-fill performed-color"></i>
                      </p>
                      <p *ngIf="i.status == 'Annulée' " title="Cliquer pour modifier"><i class="bi bi-circle-fill canceled-color"></i>
                      </p>
                      <p *ngIf="i.status == 'Modifiée' "title="Cliquer pour modifier"><i class="bi bi-circle-fill modified-color"></i>
                      </p>
                    </td>
                    <td *ngIf="i.toUpdatedStatus">
                      <div class="row">
                        <div class="col-8"> 
                          <ng-select
                          [items]="statuts"
                          [(ngModel)]="i.status"
                          class="form-control input-custom"
                          [closeOnSelect]="true"                    >
                          </ng-select>
                      </div>
                        <div class="col-1"> 
                          <button
                          class="btn btn-sm btn-square btn-neutral text-danger-hover">
                          <i
                          style="font-size: 28px;"
                          class="bi bi-check"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          (click)="onSubmitStatut()"
                         
                        ></i></button>
                      </div>
                      <div class="col-1"> 
                        <button
                        class="btn btn-sm btn-square btn-neutral text-danger-hover">
                        <i
                        style="font-size: 28px;"
                        class="bi bi-x"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        (click)="cancel(i)"
                       
                      ></i></button>
                    </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngIf="listOccurrences && listOccurrences.length > 0" class="mt-4">
                <div class="d-flex justify-content-end">
                  <div class="mr-3">
                    <i class="bi bi-circle-fill planned-color"></i> Planifiée
                  </div>
                  <div class="mr-3">
                    <i class="bi bi-circle-fill performed-color"></i> Effectuée
                  </div>
                  <div class="mr-3">
                    <i class="bi bi-circle-fill modified-color"></i> Modifiée
                  </div>
                  <div class="mr-3">
                    <i class="bi bi-circle-fill canceled-color"></i> Annulée
                  </div>
                </div>
              </div>
              <div
              *ngIf="listOccurrences && listOccurrences.length == 0"
              class="table-responsive"
              style="text-align: center"
              >
              <p style="padding-bottom: 45px; padding-top: 35px ">
                Aucune récurrence n'est trouvée
              </p>
              </div>
          
          </div>
        </div>
  
      </div>
      <div *ngIf="benefit && (benefit.frequence == 'Hebdomadaire' || benefit.frequence == 'Quotidienne') " class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0"><i class="bi bi-calendar2-week pr-1"></i> Liste des jours d'interventions de {{benefit.name.type}}</h3>
        </div>
        <div class="card-body row">
          <table class="table">
    
            <tbody *ngFor="let i of benefit.listDates; let k =index">
              <tr *ngIf="k%2 != 0" style="background-color: white; border: hidden;">
                <td>{{i}}</td>
              </tr>
              <tr *ngIf="k%2 == 0" style="background-color: #e9ebff; border-bottom: hidden;">
               <td>{{i}}</td>
              </tr>
            </tbody>
          </table>        
        </div>
      </div>
          <div *ngIf="benefit && (benefit.frequence == 'Mensuelle' || benefit.frequence == 'Tous les 15 jours' ) " class="card shadow-sm">
            <div class="card-header bg-transparent">
              <h3 class="mb-0"><i class="bi bi-calendar2-check"></i> Jour de l'intervention de {{benefit.name.type}}</h3>
            </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
            <p *ngIf="benefit.frequence == 'Mensuelle'" style="color: #0e309f;" class="mb-3 title-info">
              Le {{benefit.listDates[0]}} de chaque mois 
            </p>
            <p *ngIf="benefit.frequence == 'Tous les 15 jours'" style="color: #0e309f;" class="mb-3 title-info">
              Chaque quinze jours
            </p>
          </div>
          </div>
        </div>
        <br>
      </div>      

   
  

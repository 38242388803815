<!-- main app container -->
<app-navbar></app-navbar>
  <header class="bg-surface  pt-6">
    <div style="float: right;padding-right: 9%;" class="col-lg-5 col-md-6 col-sm-12">
      <ul class="breadcrumb float-md-right">
        <li class="breadcrumb-item"> 
          <a class="color-blue" style="cursor: default;" 
            ><i class="zmdi zmdi-home"></i> Accueil</a
          >
        </li>
        <li class="breadcrumb-item color-blue">
          <a class="color-blue" routerLink="../">Clients
          </a>
        </li>
        <li class="breadcrumb-item active color-blue " style="cursor: default;">Créer un client</li>
      </ul>
    </div>
    <br>
    <br>
    <div class="container-fluid">
      <div class="mb-npx" style="width: 92%;">
        <!-- <div class="col-lg-5 col-md-6 col-sm-12">
          <ul class="breadcrumb float-md-right">
            <li class="breadcrumb-item"><a routerLink="../../"><i class="zmdi zmdi-home"></i>
                Backoffice</a>
            </li>
            <li class="breadcrumb-item"><a routerLink="../">Articles</a></li>
            <li class="breadcrumb-item active">Ajouter</li>
          </ul>
        </div> -->
        <div class="row align-items-center">
            <div class="col-1"></div>
          <div class="col-sm-11 col-12 mb-4 mb-sm-0 ">
            <!-- Title -->
            <h1 class="h2 mb-0 ls-tight color-blue title-1" >
                Créer un client
            </h1>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>
  
            <!-- Actions -->
            <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 mt-4 text-sm-end">
            <div class="mx-n1">
              <a
                (click)="create()"
                class="btn d-inline-flex btn-sm btn-primary mx-1"
              >
              
                <span class="button_list" style="color: #fff">Créer un client</span>
              </a>
              <a
                
                class="btn d-inline-flex btn-sm btn-primary mx-1"
              >
              
                <span class="button_list" style="color: #fff">Export XLS</span>
              </a>
            </div>
          </div> -->
        </div>
      
       
      </div>
    </div>
  </header>
  <br>
<div class="card m-3-cust">
    
    <div class="card-body ml-5">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
          <div class="form-row">
              <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Nom de la société<b style="color: red;"> *</b></label>
                  <input type="text" formControlName="companyName" class="form-control input-custom" 
                  [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
                  <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                      <div *ngIf="f.companyName.errors.required">Champ obligatoire.</div>
                  </div>
               
              </div>

              <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-8 col-12">
                <label>TVA</label>
                <input type="text" formControlName="tva"  class="form-control input-custom"  />
              
            </div>
            <div class="form-group col-xl-1 col-lg-1 col-md-6 col-sm-4 col-12">
              <label>Autoliquidation</label><br>
              <ui-switch
              formControlName="autoliquidation"
              
              size="small"
              
              
            >
            </ui-switch>
            
          </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
                <label>Pays</label>
                <ng-select [items]="list_contries"
                formControlName="country"
                class="form-control input-custom"
                [closeOnSelect]="true"
                >
                </ng-select>
         
        </div>
             
            </div>
          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Rue</label>
                <input type="text" formControlName="street" class="form-control input-custom"  />
             
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <label>Numéro</label>
              <input type="text" formControlName="number" class="form-control input-custom"  />
    
            
          </div>
        </div>

        <div *ngIf="registerForm.value.country && registerForm.value.country == 'Belgique'" class="form-row">
          <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <label>Code postal</label>
              <ng-select [items]="codes_postales"
                  bindLabel="postal_code"
                  formControlName="postalCode"
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  (change)="getCity()"
                  >
                  </ng-select>
           
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <label>Ville</label>
            <input disabled type="text"  formControlName="city" [(ngModel)]="this.city_codePostal" class="form-control input-custom"  />
          
        </div>
      </div>

      <div *ngIf="registerForm.value.country != 'Belgique'" class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <label>Code postal</label>
                <input  type="text"  formControlName="postalCode"  class="form-control input-custom"  />
         
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Ville</label>
          <input type="text"  formControlName="city"  class="form-control input-custom"  />
        
      </div>
    </div>

      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <label>Contact(s)
              <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addContact()"></i>
            </label>
            <ng-select [items]="contacts"
                  (click)="getContactsNotAffected()"
                  bindLabel="firstName_lastName"
                  bindValue="_id"                  
                  formControlName="contact"
                  class="form-control"
                  [closeOnSelect]="true"
                  multiple = true
                  (change)="getContactByThisClient()"
                  >
                  </ng-select>
        </div>
      <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <label>Contact principal</label>
        <ng-select [items]="listContactsByThisClient"
              bindLabel="firstName_lastName"
              [(ngModel)]="chooseContactMainFromlistContactsByThisClient"
              formControlName="contactMain"
              class="form-control"
              [closeOnSelect]="true"
              [ngClass]="{ 'is-invalid': submitted && f.contactMain.errors }"
              (change)="setContactMain($event)"
              >
              </ng-select>
              <div *ngIf="submitted && f.contactMain.errors" class="invalid-feedback">
                <div *ngIf="f.contactMain.errors.required">Champ obligatoire.</div>
              </div>

     
    </div>
        <!-- <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Chantier(s)</label>
          <ng-select [items]="worksite"
          bindLabel="name"
          formControlName="constructionSite"
          class="form-control input-custom"
          [closeOnSelect]="true"
          [multiple]="true"
          >
          </ng-select>
        
      </div> -->
    </div>
    <div class="form-row">
      <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <label > Etablissement(s) <i style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addEtablissement()"></i>
         </label>
        <ng-select
             (click)="getEtablissement()"
             [items]="etablissements"
              bindLabel="name"
              bindValue="_id"
              formControlName="etablissement"
              class="form-control"
              [closeOnSelect]="true"
              multiple = true
              >
              </ng-select>
              

     
    </div>
      <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Adresse mail de facturation</label>
          <input type="text" formControlName="email" class="form-control input-custom" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
              <div *ngIf="f.email.errors.email">Merci de saisir une adresse mail valide.</div>
          </div>
       
      </div>
      </div>


      

<!--         
      <div><strong>Is input valid:</strong> <pre>{{ !f.form.controls['phone'].invalid }}</pre></div>
    <div><strong>Is input touched:</strong> <pre>{{ f.form.controls['phone'].touched }}</pre></div>
    <div><strong>Is form valid:</strong> <pre>{{ f.form.valid }}</pre></div>
    <div><strong>Form value:</strong> <pre>{{ f.form.value | json }}</pre></div>
    <div><strong>Form validation errors:</strong> <pre>{{ f.form.controls['phone'].errors | json }}</pre></div> -->
   
   
         
          <div style="padding-top: 3%;" class="text-center">
              
              <button routerLink="/admin/clients" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
              <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
          </div>
      </form>
    </div>
</div>
<br>

<!-- credits -->

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ContactService } from 'src/app/_services/contact.service';
import { SiteService } from 'src/app/_services/site.service';
import { SuppafacService } from 'src/app/_services/suppafac.service';
import { UserService } from 'src/app/_services/user.service';
import { WorkerService } from 'src/app/_services/worker.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import * as  uuid from 'uuid';



@Component({
  selector: 'app-update-suppafac',
  templateUrl: './update-suppafac.component.html',
  styleUrls: ['./update-suppafac.component.css','../../../../../_shared/add_update.css']
})
export class UpdateSuppafacComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  listFiles = []
  InitListeFiles = []
  filesToSaved = []
  BaseUrl_suppafac = environment.baseUrl.split('/api')[0] + '/media/suppafacs/'; 





  id
  suppafac
  
  sites =[]
  admins =[]
  contacts :any = []
  workers :any =[]
  statuts =["A faire","Planifié","En cours","Clôturé"]
  registerForm: FormGroup;
  submitted = false;
  dateSyst = new  Date()
  firstDate :any
  datesSuppafacsRecuperate :any
  contactPrincipal_suppafac :any
  workersWithFullName :any
  d1 :string

   //editor text 
   editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    width: "100%",
    minHeight: "5rem",
    placeholder: "",
    translate: "yes",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      ["insertImage", "insertVideo","insertHorizontalRule","removeFormat","toggleEditorMode"],

    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    
    
  };
  myUUID: any;
  
  constructor(private formBuilder: FormBuilder,
    private suppafacService :SuppafacService,
    private siteService :SiteService,
    private userService :UserService,
    private workerService :WorkerService,
    public iziToast: Ng2IzitoastService,
    private route :ActivatedRoute,
    private router :Router,
    private contactSevice :ContactService
    ) { }


  ngOnInit() {
   
    this.d1 = this.dateSyst.toISOString().substr(0, 10);

     
   const year = this.dateSyst.getFullYear();
   const month = String(this.dateSyst.getMonth() + 1).padStart(2, '0'); // Adding 1 and padding with 0 if necessary
   const day = String(this.dateSyst.getDate()).padStart(2, '0');
   const formattedDate = `${year}-${month}-${day}`;
   this.firstDate = formattedDate

    this.registerForm = this.formBuilder.group({
        site: ['', Validators.required ],
        title: ['', Validators.required ],
        description : ['', Validators.required ],
        manager : ['', Validators.required ],
        planfie :  [false],
        dates: this.formBuilder.array([]), // Initialize the form array for dates
        statut :['', Validators.required],
        contact :['', Validators.required],
        worker :[],
        document :['',],
        duration :['',], 
        key :['',]
      
    },);
    this.id = this.route.snapshot.params["id"];
    this.suppafacService.getSuppafacById(this.id).subscribe(data => {
       this.suppafac = data; 
       this.myUUID = this.suppafac.key


       // add field firstName_lastname in contact object
       this.contactPrincipal_suppafac = this.suppafac.contact
       if(this.suppafac && this.suppafac.contact){
        this.contactPrincipal_suppafac.firstName_lastName = this.suppafac.contact["lastName"]+" "+this.suppafac.contact["firstName"]
           }

           if(this.suppafac && this.suppafac.worker){
           for (let worker of this.suppafac.worker){
            worker.firstName_lastName = worker["lastName"]+" "+ worker["firstName"]
           }
          }
     
       this.datesSuppafacsRecuperate = this.suppafac.dates
       this.suppafac.site.column3 = this.suppafac.site.name;
       if(this.suppafac.dates.length>0){
       this.firstDate = this.suppafac.dates[0].datePlanfie
       }
      for (let i of this.datesSuppafacsRecuperate) {
        this.dateForms.push(this.formBuilder.group({
          datePlanfie: i.datePlanfie,
        }));
      }
      if(!this.suppafac.planfie){
      this.addDateField()
      }
    })
    this.addDateField();
    this.getSites()
    this.getUsers()
    this.getWorkers()
    this.getContacts()
    this.getlistDocumentsSites()
}
get dateForms() {
  return this.registerForm.get('dates') as FormArray;
}
addDateField() {
  if(this.dateForms.value[1]){
  if(!this.dateForms.value[1].datePlanfie){
    const year = this.dateSyst.getFullYear();
    const month = String(this.dateSyst.getMonth() + 1).padStart(2, '0'); // Adding 1 and padding with 0 if necessary
    const day = String(this.dateSyst.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    this.firstDate = formattedDate
    this.dateForms.value[1].datePlanfie = this.firstDate
  }
  }
  let dateFormGroup;
  if (this.registerForm.value.planfie) {
      dateFormGroup = this.formBuilder.group({
          datePlanfie: ['', Validators.required],
      });
  } else {
      dateFormGroup = this.formBuilder.group({
          datePlanfie: [''],
      });
  }
  this.dateForms.push(dateFormGroup);
}

removeQuantity(i: number) {
  this.dateForms.removeAt(i);
}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }


getSites() {
  this.siteService.getSites().subscribe((e) => {
    this.sites = e;
  });
}
getUsers() {
  this.userService.getListUsers().subscribe((e) => {
    this.admins = e;
  });
}
getWorkers() {
  this.workerService.getWorkerNotAffected().subscribe((e) => {
    this.workers = e;
  });
}
getContacts()
{

    this.contactSevice.getContacts().subscribe(data => {
      this.contacts = data;
   })
}

getlistDocumentsSites(){
  this.suppafacService.listNamesFiles(this.id).subscribe(data => {
  this.listFiles = data;
  if( this.listFiles.length>0){
    for(let item of this.listFiles)
    this.InitListeFiles.push(item)
  }
  },
  (error =>{
  }))
  }

onSubmit() {
  this.submitted = true;
  if (this.registerForm.invalid) {
      return;
  }
  this.registerForm.value.key = this.myUUID
  if (this.filesToSaved.length != 0) {
    for (let file of this.filesToSaved) {
        const originalFileName = file.name;
        let fileExtension = this.getFileExtension(originalFileName);
        fileExtension = '.' + fileExtension;
        let index = this.listFiles.length;
        const parts = originalFileName.split('.');
        const newFileName = this.registerForm.value.title + '_' + parts[0] + '_' + index + '.' + parts[1];
        const newFile: File = new File([file], encodeURIComponent(newFileName), { type: file.type });
        const formData: FormData = new FormData();
        formData.append('file', newFile);
        if(!this.myUUID){
          this.myUUID = uuid.v4();
        }
        this.suppafacService.uploadFiles(formData, this.myUUID).subscribe(e => {
        })

        this.listFiles.push(newFileName)
    }
    let listConverteds = this.listFiles.filter(element => element.includes(this.registerForm.value.title));
    this.registerForm.value.document = listConverteds
  }
  if(!this.registerForm.value.planfie){
    this.registerForm.value.dates = []
  }
  else
  {
  this.registerForm.value.dates.shift();
  }
  this.registerForm.value.site =this.registerForm.value.site._id
  this.registerForm.value.manager = this.registerForm.value.manager._id
  this.registerForm.value.contact =this.registerForm.value.contact._id
  if(this.registerForm.value.worker){
    this.registerForm.value.worker=this.registerForm.value.worker.map(item => item._id);
    this.registerForm.value.key = this.myUUID
  }

  // if(!this.myUUID){
  //    = uuid.v4();
  // }
  let listConverteds = this.listFiles.filter(element => element.includes(this.registerForm.value.title));
  this.registerForm.value.document = listConverteds
  this.suppafacService.editSuppafac(this.id,this.registerForm.value).subscribe(
    (data) => {
      this.iziToast.show({
        message:'Modification avec succès',
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
      this.router.navigate(['/admin/chantiers/suppafacs' ])
    })

}


public dropped(files: NgxFileDropEntry[]) {
 
  this.files = files;
  const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
  const validExtensions = ['png', 'jpg' ,'jpeg','pdf','txt'];

  if (validExtensions.includes(fileExtension)) {
  this.listFiles.push(this.files[0].relativePath)
  }
  else
  {
      const targetRelativePath = this.files[0].relativePath;
      const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);

      if (targetIndex !== -1) {
        files.splice(targetIndex, 1);
      } 
    this.iziToast.show({
      message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
      messageColor:'#800f2f',
      titleColor:'#800f2f',
      progressBarColor:'#c9184a',
      icon:'bi-exclamation-diamond',
      imageWidth:45,
      position:'topRight',
      timeout:5000,
      backgroundColor:'#ff8fa3',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      overlay:true,
      overlayClose:true,
      
    });
    return;
  }
  for (const droppedFile of files) {

    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // Here you can access the real file
        if(file.size < 5242880){
        this.filesToSaved.push(file)
        }
        else
        {
          const targetRelativePath = this.files[0].relativePath;
          const targetIndex = this.listFiles.findIndex(entry => entry === targetRelativePath);
          if (targetIndex !== -1) {
            this.listFiles.splice(targetIndex, 1);
          } 
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor:'#800f2f',
            titleColor:'#800f2f',
            progressBarColor:'#c9184a',
            icon:'bi-exclamation-diamond',
            imageWidth:45,
            position:'topRight',
            timeout:5000,
            backgroundColor:'#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay:true,
            overlayClose:true,
            
          });
          return;
        }
        if(this.filesToSaved.length > 10){
          this.listFiles.splice(this.filesToSaved.length-1 , 1);
          this.filesToSaved.splice(this.filesToSaved.length-1 , 1);
          this.iziToast.show({
            message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            icon: 'bi-exclamation-diamond',
            imageWidth: 45,
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          return;
        }
      });
    } else {
      // It was a directory (empty directories are added, otherwise only files)
      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    }
  }
}

public fileOver(event){
}

public fileLeave(event){
}
deleteFileFromListFiles(file){
  const indexToRemove = this.listFiles.findIndex(obj => obj === file);
  if (indexToRemove !== -1) {
    this.listFiles.splice(indexToRemove, 1);
    this.filesToSaved.splice(indexToRemove, 1);
  }
   }

   openFileInNewWindow(key: string,filename) {
    let url :any
    url =this.  BaseUrl_suppafac+key+"/"+filename
    window.open(url, '_blank');
  }
  getFileExtension(fileName: string): string {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { WorkerService } from 'src/app/_services/worker.service';
@Component({
  selector: 'app-list-workers-archived',
  templateUrl: './list-workers-archived.component.html',
  styleUrls: ['../../../../_shared/list.css','./list-workers-archived.component.css']
})
export class ListWorkersArchivedComponent implements OnInit {

  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  workers : any
  workerArchived :any 
  copyListWorkers;
  prenomToFiltred: any;
  nomToFiltred: any;
  emailToFiltred: any;
  telephoneToFiltred: any;
  constructor( private router: Router,
    private workerService :WorkerService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService

    ) { }

  ngOnInit(): void {
    this.getWorkers()
  }

  getWorker(worker) {
    this.workerArchived = worker;
  }
  getWorkers() {
    this.prenomToFiltred = undefined
    this.nomToFiltred = undefined
    this.emailToFiltred =undefined
    this.telephoneToFiltred = undefined
  
    this.workerArchived = true
    this.page = 1

    this.workerService.getWorkersArchiveds().subscribe((e) => {

      this.workers = e.reverse();;
      this.copyListWorkers = this.workers
    });
  }

  filter() {
    this.workers = this.copyListWorkers;
    let filteredWorkers = [...this.copyListWorkers];
    // Convert filter criteria to lowercase
    const prenomFilterLower = this.prenomToFiltred ? this.prenomToFiltred.toLowerCase() : '';
    const nomFilterLower = this.nomToFiltred ? this.nomToFiltred.toLowerCase() : '';
    const emailFilterLower = this.emailToFiltred ? this.emailToFiltred.toLowerCase() : '';
    const telephoneFilterLower = this.telephoneToFiltred ? this.telephoneToFiltred: '';
    filteredWorkers = this.workers.filter(item => {
      const nomFilter = nomFilterLower ? item.lastName.toLowerCase().includes(nomFilterLower) : true;
      const prenomFilter = prenomFilterLower ? item.firstName.toLowerCase().includes(prenomFilterLower) : true;
      const emailFilter = emailFilterLower ? item.email.toLowerCase().includes(emailFilterLower) : true;
      const telephoneFilter = telephoneFilterLower ? ((item.phone.toLowerCase()).replace(/\s/g, '')).includes(telephoneFilterLower) : true;
  
      return nomFilter && prenomFilter && emailFilter && telephoneFilter ;
    });
    this.workers = filteredWorkers;

    // Reset pagination
    this.page = 1;
  }

  resetFilters() {
    this.nomToFiltred = ""; 
    this.prenomToFiltred = ""; 
    this.emailToFiltred = ""; 
    this.telephoneToFiltred = "";
    
    this.filter();
 }
 sort(column, descend,col) {
  switch (column) {
    case "lastName":
      if (this.col1 === false) {
        this.col1 = true;
        this.col2 = false;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "firstName":
      if (this.col2 === false) {
        this.col1 = false;
        this.col2 = true;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "phone":
      if (this.col3 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = true;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    default:
      this.col1 = false;
      this.col2 = false;
      this.col3 = false;
      break;
  }

  // if (column === "column4") {
  //   if (descend === false) {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = true;
  //   } else {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = false;
  //   }
  // } else {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: true
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: false
      };
      this.descend = false;
    }
  }
details(id) {
  this.router.navigate(["admin/ouvriers/details", id]);
}
restoreWorkers(worker: any) {
  worker = this.workerArchived;
  this.workerService
    .archive_restoreWorker(worker._id)
    .subscribe((e) => {
      this.iziToast.show({
        message: "Désarchivage avec succès",
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      // this.getWorkers()
      // this.page = 1
      this.router.navigate(["admin/ouvriers"]);
    });
}
onTableDataChange(event: any) {
  this.page = event;
  this.workers=this.workers;
}
onTableSizeChange(event: any): void {
  this.tableSize = event.target.value;
  this.page = 1;
  this.getWorkers();
}
}

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2IzitoastService } from 'ng2-izitoast';
import codes from "src/app/BE_Postal_codes.json";
import { ClientService } from 'src/app/_services/client.service';
import { EtablissementService } from 'src/app/_services/etablissement.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-popup-add-etablissement',
  templateUrl: './popup-add-etablissement.component.html',
  styleUrls: ['./popup-add-etablissement.component.css']
})
export class PopupAddEtablissementComponent implements OnInit {


    clients: any;
  admins :any
  clients_copy: any = [];
  registerForm: FormGroup;
  submitted = false;
  listNamesClients: any = [];
  codes_postales = codes;
  city: any;
  city_codePostal: string;
  list_managers = ["Solenn", "Karen", "Valérie"];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private etbalissementService: EtablissementService,
    private clientService: ClientService,
    private userService: UserService,
    public iziToast: Ng2IzitoastService,
    private modalService: NgbModal,
    public modal: NgbActiveModal

  ) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
      adress: this.formBuilder.array([]),
      manager: ["", Validators.required],
      client: [""],
      site: [""],
    });
    this.adress().push(
      this.formBuilder.group({
        street: "",
        number: "",
        postalCode: "",
        city: "",
      })
    );
    this.getClients();
    this.getUsers();
  }
  getClients() {
    this.clientService.getClients().subscribe((e) => {
      this.clients = e;
    });
  }

  getUsers() {
    this.userService.getListAdmin().subscribe((e) => {
      this.admins = e;
    });
  }

  adress(): FormArray {
    return this.registerForm.get("adress") as FormArray;
  }

  addQuantity() {
    this.adress().push(this.newadress());
  }
  removeQuantity(i: number) {
    this.adress().removeAt(i);
  }
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    if (!this.registerForm.value.adress[0].street) {
      this.iziToast.show({
        message: "Veuillez vérifier les données de la première adresse",
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
      });

      return false;
    }
    if (!this.registerForm.value.adress[0].postalCode) {
      this.iziToast.show({
        message: "Veuillez vérifier les données de la première adresse",
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
      });

      return false;
    }
    if (!this.registerForm.value.adress[0].number) {
      this.iziToast.show({
        message: "Veuillez vérifier les données de la première adresse",
        messageColor: "#800f2f",
        titleColor: "#800f2f",
        progressBarColor: "#c9184a",
        icon: "bi-exclamation-diamond",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#ff8fa3",
      });

      return false;
    }
    for (let i = 0; i < this.registerForm.value.adress.length; i++) {
      let city = "";
      city = this.registerForm.value.adress[i].postalCode.place_name;
      this.registerForm.value.adress[i].postalCode =
        this.registerForm.value.adress[i].postalCode["postal_code"];
      this.registerForm.value.adress[i].city = city;
      if(!this.registerForm.value.adress[i].city && this.registerForm.value.adress[i].city == "" || this.registerForm.value.adress[i].city == null){
      this.registerForm.value.adress[i].city = ""
      }
      if(!this.registerForm.value.adress[i].postalCode && this.registerForm.value.adress[i].postalCode == "" || this.registerForm.value.adress[i].postalCode == null){
      this.registerForm.value.adress[i].postalCode = ""
      }
    }
    // if (!this.registerForm.value.client) {
    //   this.registerForm.value.client = ["000000000000000000000000"];
    // }

    let clientSiteSelected = [];
if(this.clients_copy && this.clients_copy.length >0){
    for (let client of this.clients_copy) {
      if (client) {
        let arrayTest = [];
        arrayTest = client.siteSelected;
        for (let site of arrayTest) {
          clientSiteSelected.push(site._id);
        }
      }
    }
  }
    this.registerForm.value.site = clientSiteSelected;
    this.etbalissementService
      .createEtablissements(this.registerForm.value)
      .subscribe((data) => {
        this.modal.dismiss();

      });
  }

  getCity(i) {
    let city;
    city = codes.find(
      (el) =>
        el.postal_code ===
        this.registerForm.value.adress[i].postalCode.postal_code
    );
  }

  newadress(): FormGroup {
    return this.formBuilder.group({
      street: "",
      number: "",
      postalCode: "",
      city: "",
    });
  }


  addClient(){
    this.modalService.open(PopupAddEtablissementComponent);
    }




    onReset() {
      this.modal.dismiss();
    
    }
  }


import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ContactService } from 'src/app/_services/contact.service';
import { SiteService } from 'src/app/_services/site.service';
import { SuppafacService } from 'src/app/_services/suppafac.service';
import { UserService } from 'src/app/_services/user.service';
import { WorkerService } from 'src/app/_services/worker.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as  uuid from 'uuid';


@Component({
  selector: 'app-add-suppafac',
  templateUrl: './add-suppafac.component.html',
  styleUrls: ['./add-suppafac.component.css','../../../../../_shared/add_update.css']
})
export class AddSuppafacComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];

  sites =[]
  admins =[]
  contacts :any = []
  workers :any =[]
  statuts =["A faire","Planifié","En cours","Clôturé"]
  registerForm: FormGroup;
  submitted = false;
  dateSyst = new  Date()
  firstDate :any
  d1: string;

   //editor text 
   editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    width: "100%",
    minHeight: "5rem",
    placeholder: "",
    translate: "yes",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [
      ["insertImage", "insertVideo","insertHorizontalRule","removeFormat","toggleEditorMode"],

    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    
    
  };

  filesToSaved = []
  listFiles = []
  myUUID = uuid.v4();



  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private suppafacService :SuppafacService,
    private siteService :SiteService,
    private userService :UserService,
    private workerService :WorkerService,
    private contactService :ContactService,
    public iziToast: Ng2IzitoastService
    ) { }


  ngOnInit() {
   const year = this.dateSyst.getFullYear();
   const month = String(this.dateSyst.getMonth() + 1).padStart(2, '0'); // Adding 1 and padding with 0 if necessary
   const day = String(this.dateSyst.getDate()).padStart(2, '0');
   const formattedDate = `${year}-${month}-${day}`;
   this.firstDate = formattedDate

    this.registerForm = this.formBuilder.group({
        site: ['', Validators.required ],
        title: ['', Validators.required ],
        description : ['', Validators.required ],
        manager : ['', Validators.required ],
        planfie :  [false],
        dates: this.formBuilder.array([]), // Initialize the form array for dates
        statut :['', Validators.required],
        contact :['',Validators.required],
        worker :[],
        document :[],
        // worker :['',Validators.required],
        duration :['', ],     
        key :[','],  
    },);
    this.d1 = this.dateSyst.toISOString().substr(0, 10);

    this.addDateField();
    this.getSites()
    this.getUsers()
    this.getContacts()
    this.getWorkers()
}
get dateForms() {
  return this.registerForm.get('dates') as FormArray;
}
addDateField() {
  if(this.dateForms.value[0]){
  if(!this.dateForms.value[0].datePlanfie){
    const year = this.dateSyst.getFullYear();
    const month = String(this.dateSyst.getMonth() + 1).padStart(2, '0'); // Adding 1 and padding with 0 if necessary
    const day = String(this.dateSyst.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    this.firstDate = formattedDate
    this.dateForms.value[0].datePlanfie = this.firstDate
  }
  }
  let dateFormGroup;
  if (this.registerForm.value.planfie) {
      dateFormGroup = this.formBuilder.group({
          datePlanfie: ['', Validators.required],
      });
  } else {
      dateFormGroup = this.formBuilder.group({
          datePlanfie: [''],
      });
  }
  this.dateForms.push(dateFormGroup);
}

removeQuantity(i: number) {
  this.dateForms.removeAt(i);
}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }


getSites() {
  this.siteService.getSites().subscribe((e) => {
    this.sites = e;
  });
}
getUsers() {
  this.userService.getListUsers().subscribe((e) => {
    this.admins = e;
  });
}
getWorkers() {
  this.workerService.getWorkerNotAffected().subscribe((e) => {
    this.workers = e;
  });
}


getContacts()
{
 
    this.contactService.getContacts().subscribe(data => {
      this.contacts = data;
     
   })
  
}

onSubmit() {
  this.submitted = true;
  let filesList =[]

  if (this.registerForm.invalid) {
      return;
  }
    // sauvegarde files
    if(this.filesToSaved.length != 0)
    {
      for(let file of this.filesToSaved)
      {
         const originalFileName = file.name;
         let fileExtension = this.getFileExtension(originalFileName);
         fileExtension ='.'+fileExtension
          const timestamp = Date.now().toString().slice(-2); // Get the last two digits of the timestamp
          let index =this.filesToSaved.indexOf(file) 
          index =index+1
          var parts = originalFileName.split('.');
         const newFileName = this.registerForm.value.title+'_'+parts[0]+'_'+index+'.'+parts[1]
         const newFile: File = new File([file], encodeURIComponent(newFileName), {type: file.type});
         const formData: FormData = new FormData();
         formData.append('file', newFile);
        this.suppafacService.uploadFiles(formData,this.myUUID).subscribe(e =>{
        })
        
        filesList.push(newFileName)
      }
      this.registerForm.value.document = filesList
      
    }
   
    // sauvegarde chnatier
    this.registerForm.value.key = this.myUUID
  this.suppafacService.createSuppafac(this.registerForm.value).subscribe(
    (data) => {
      this.iziToast.show({
        message:'Ajout avec succès',
        messageColor:'#386641',
        progressBarColor:'#6a994e',
        icon:'bi-check-circle',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
         });
      this.router.navigate(['/admin/chantiers/suppafacs' ])
    })



  }

  public dropped(files: NgxFileDropEntry[]) {
 
    this.files = files;
    const fileExtension = this.files[0].relativePath.split('.').pop().toLowerCase();
    const validExtensions = ['png', 'jpg' ,'jpeg','pdf','txt'];
  
    if (validExtensions.includes(fileExtension)) {
    this.listFiles.push(this.files[0].relativePath)
    }
    else
    {
        const targetRelativePath = this.files[0].relativePath;
        const targetIndex = files.findIndex(entry => entry.relativePath === targetRelativePath);
  
        if (targetIndex !== -1) {
          files.splice(targetIndex, 1);
        } 
      this.iziToast.show({
        message: 'Le fichier doit être de type png, jpg, jpeg, pdf ou txt .',
        messageColor:'#800f2f',
        titleColor:'#800f2f',
        progressBarColor:'#c9184a',
        icon:'bi-exclamation-diamond',
        imageWidth:45,
        position:'topRight',
        timeout:5000,
        backgroundColor:'#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay:true,
        overlayClose:true,
        
      });
      return;
    }
    for (const droppedFile of files) {
  
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
  
          // Here you can access the real file
          if(file.size < 5242880){
          this.filesToSaved.push(file)
          }
          else
          {
            const targetRelativePath = this.files[0].relativePath;
            const targetIndex = this.listFiles.findIndex(entry => entry === targetRelativePath);
            if (targetIndex !== -1) {
              this.listFiles.splice(targetIndex, 1);
            } 
            this.iziToast.show({
              message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
              messageColor:'#800f2f',
              titleColor:'#800f2f',
              progressBarColor:'#c9184a',
              icon:'bi-exclamation-diamond',
              imageWidth:45,
              position:'topRight',
              timeout:5000,
              backgroundColor:'#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay:true,
              overlayClose:true,
              
            });
            return;
          }
          //if( this.filesToSaved)
          if(this.filesToSaved.length > 10){
            this.listFiles.splice(this.filesToSaved.length-1 , 1);
            this.filesToSaved.splice(this.filesToSaved.length-1 , 1);
            this.iziToast.show({
              message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
              messageColor: '#800f2f',
              titleColor: '#800f2f',
              progressBarColor: '#c9184a',
              icon: 'bi-exclamation-diamond',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay: true,
              overlayClose: true,
            });
            return;
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  
  public fileOver(event){
  }
  
  public fileLeave(event){
  }
  //remove file
  deleteFileFromListFiles(file){
    const indexToRemove = this.listFiles.findIndex(obj => obj === file);
    if (indexToRemove !== -1) {
      this.listFiles.splice(indexToRemove, 1);
      this.filesToSaved.splice(indexToRemove, 1);
    }
     }
     getFileExtension(fileName: string): string {
      return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    }

}

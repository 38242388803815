import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { DocumentService } from 'src/app/_services/document.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-list-documents-archived',
  templateUrl: './list-documents-archived.component.html',
  styleUrls: ['../../../../_shared/list.css','./list-documents-archived.component.css']
})
export class ListDocumentsArchivedComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  criteria: SortCriteria;
  descend = false;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  documents :any
  documentArchived :any
  BaseUrl_subContractor = environment.baseUrl.split('/api')[0] + '/media/sub_contrator/';
  BaseUrl_site = environment.baseUrl.split('/api')[0] + '/media/sites/';
  slash="/";
  sitesToFiltred: any;
  subCotractorsToFiltred:any;
  listSites = [];
  copyListDocument :any
  listSubContractor = [];
  DocumentNameToFiltred :any
  FileToFiltred :any
  constructor( private router: Router,private documentService :DocumentService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService,
    private renderer: Renderer2) { }

  ngOnInit(): void {
    this.getDocuments()
  }
  getDocument(document) {
    this.documentArchived = document;
  }
  getDocuments() {
    // this.DocumentNameToFiltred = undefined
    // this.FileToFiltred = undefined
    // this.sitesToFiltred =undefined
    // this.subCotractorsToFiltred = undefined
  
    // this.documentArchived = true
    // this.page = 1

    this.documentService.getDocumentsArchiveds().subscribe((e) => {

      this.documents = e;
      this.copyListDocument = this.documents
      this.listSubContractor =  Array.from(new Set(this.documents.filter(item => item.subContractor !== "").map(item => item.subContractor)));
      this.listSites =  Array.from(new Set(this.documents.filter(item => item.site !== "").map(item => item.site)))
      
    });
  }

  filterBySousTraitant(event){
    this.subCotractorsToFiltred =event
      }
      filterByChantier(event){
        this.sitesToFiltred = event
      }
      filter() {
        this.documents = this.copyListDocument;
        let filteredDocuments = [...this.copyListDocument];
        
        const documentNameToFiltredLower = this.DocumentNameToFiltred ? this.DocumentNameToFiltred.toLowerCase() : '';
        const fileToFiltredLower = this.FileToFiltred ? this.FileToFiltred.toLowerCase() : '';
        const sitesToFiltredLower = this.sitesToFiltred ? this.sitesToFiltred.toLowerCase() : '';
        const subContractorsToFiltredLower = this.subCotractorsToFiltred ? this.subCotractorsToFiltred.toLowerCase() : '';
      
        filteredDocuments = this.documents.filter(item => {
          const documentFilter = documentNameToFiltredLower ? item.name.toLowerCase().includes(documentNameToFiltredLower) : true;
          const documentFileFilter = fileToFiltredLower ? item.file.toLowerCase().includes(fileToFiltredLower) : true;
          const siteFilter = sitesToFiltredLower ? item.site.toLowerCase() === sitesToFiltredLower : true;
          const subContractorFilter = subContractorsToFiltredLower ? item.subContractor.toLowerCase() === subContractorsToFiltredLower : true;
          return documentFilter && documentFileFilter && siteFilter && subContractorFilter;
        });
        this.documents = filteredDocuments;
        this.page=1
      }
        
      resetFilters() {
         this.DocumentNameToFiltred = ""; 
         this.FileToFiltred = ""; 
         this.sitesToFiltred = undefined; 
         this.subCotractorsToFiltred = undefined; 
        
         this.filter();
    
      }

      sort(column, descend,col) {
        switch (column) {
          case "name":
            if (this.col1 === false) {
              this.col1 = true;
              this.col2 = false;
              this.col3 = false;
              this.col4 = false;
            } else {
              this.col1 = false;
              this.col2 = false;
              this.col3 = false;
              this.col4 = false;
            }
            break;
          case "file":
            if (this.col2 === false) {
              this.col1 = false;
              this.col2 = true;
              this.col3 = false;
              this.col4 = false;
            } else {
              this.col1 = false;
              this.col2 = false;
              this.col3 = false;
              this.col4 = false;
            }
            break;
          case "subContractor":
            if (this.col3 === false) {
              this.col1 = false;
              this.col2 = false;
              this.col3 = true;
              this.col4 = false;
            } else {
              this.col1 = false;
              this.col2 = false;
              this.col3 = false;
              this.col4 = false;
            }
            break;
          case "site":
            if (this.col4 === false) {
              this.col1 = false;
              this.col2 = false;
              this.col3 = false;
              this.col4 = true;
            } else {
              this.col1 = false;
              this.col2 = false;
              this.col3 = false;
              this.col4 = false;
            }
            break;
          default:
            this.col1 = false;
            this.col2 = false;
            this.col3 = false;
            this.col4 = false;
            break;
        }
      
        // if (column === "column4") {
        //   if (descend === false) {
        //     this.criteria = {
        //       property: column,
        //       descending: descend
        //     };
        //     this.descend = true;
        //   } else {
        //     this.criteria = {
        //       property: column,
        //       descending: descend
        //     };
        //     this.descend = false;
        //   }
        // } else {
          if (descend === false) {
            this.criteria = {
              property: column,
              descending: true
            };
            this.descend = true;
          } else {
            this.criteria = {
              property: column,
              descending: false
            };
            this.descend = false;
          }
        }
      openFileInNewWindow(sub_contractor,key: string,filename) {
        let url :any
        if(sub_contractor){
        url =this.BaseUrl_subContractor+key+"/"+filename
        }
        else
        {
          url =this.BaseUrl_site+key+"/"+filename
        }
        window.open(url, '_blank');
      }
      onTableDataChange(event: any) {
        this.page = event;
        //this.page = 1;
        // this.getDocuments()
        this.documents = this.documents
      }
      onTableSizeChange(event: any): void {
        this.tableSize = event.target.value;
        this.page = 1;
        this.getDocuments()
      }
      restoreDocument(document: any) {
        document = this.documentArchived;
        this.documentService
          .archive_restoreDocument(document._id)
          .subscribe((e) => {
            this.iziToast.show({
              message: "Désarchivage avec succès",
              messageColor: "#386641",
              progressBarColor: "#6a994e",
              icon: "bi-check-circle",
              imageWidth: 45,
              position: "topRight",
              timeout: 5000,
              backgroundColor: "#dde5b6",
              transitionIn: "flipInX",
              transitionOut: "flipOutX",
            });
            this.router.navigate(['/admin/documents' ])
          });
      }
}

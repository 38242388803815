<div class="modal-header">
    <h4 class="modal-title">Modifier une récurrence</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
       
    <div >
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
            
              <div class="form-row">
                <div class="form-group col-12">
                    <label>Prestation </label>
                    <input type="text"  [(ngModel)]="name" formControlName="name" disabled class="form-control input-custom" />
                </div>
                  <div class="form-group col-12">
                      <label>Date </label>
                      <input type="date"  [(ngModel)]="dateConverted" formControlName="date" class="form-control input-custom" 
                     />
                  </div>
    
                 
                
              </div>
             
        
              <div style="padding-top: 3%;" class="text-center">
                  
                  <button (click)="onReset()" class="btn btn-secondary mr-4 button_list" type="reset">Annuler</button>
                  <button  class="btn btn-primary button_list">Sauvegarder</button>
              </div>
          </form>
      </div>
  </div>



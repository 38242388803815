import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';

@Component({
  selector: 'app-list-sub-contractors-archived',
  templateUrl: './list-sub-contractors-archived.component.html',
  styleUrls: ['../../../../_shared/list.css','./list-sub-contractors-archived.component.css']
})
export class ListSubContractorsArchivedComponent implements OnInit {

  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  subContractors
  subContractorArchived:any
  copyListSubConstractor;
  listCompany:any;
  listContactPerson:any;
  companyNameToFiltred:any;
  tvaToFiltred:any;
  contactPersonToFiltred:any;
  telephoneToFiltred:any;
  constructor( private router: Router,private subContractorService :SubContractorService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.getListSubContractors()
  }
  getListSubContractors() {
    this.companyNameToFiltred = undefined
    this.tvaToFiltred = undefined
    this.contactPersonToFiltred =undefined
    this.telephoneToFiltred = undefined
  
    this.subContractorArchived = true
    this.page = 1

    this.subContractorService.getSubContractorArchiveds().subscribe((e) => {

      this.subContractors = e.reverse();
      this.copyListSubConstractor = this.subContractors
      this.listCompany = Array.from(new Set(this.subContractors.map(item => item.companyName)));
      this.listContactPerson =  Array.from(new Set(this.subContractors.filter(item => item.contactMain !== "").map(item => item.contactMain)));
    });
  }
  details(id){
    this.router.navigate(["admin/prestataires/details", id]);
  }
  getSubContractor(subContractor) {
    this.subContractorArchived = subContractor;
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.subContractors = this.subContractors;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getListSubContractors;
  }
  filterByCompany(event) {
    this.companyNameToFiltred = event
  }
  filterByContactPerson(event) {
    this.contactPersonToFiltred = event
  }
  filter() {
    this.subContractors = this.copyListSubConstractor;
    let filteredSubConstractor = [...this.copyListSubConstractor];
    // Convert filter criteria to lowercase
    const companyNameFilterLower = this.companyNameToFiltred ? this.companyNameToFiltred.toLowerCase() : '';
    const tvaFilterLower = this.tvaToFiltred ? this.tvaToFiltred.toLowerCase() : '';
    const contactPersonFilterLower = this.contactPersonToFiltred ? this.contactPersonToFiltred.toLowerCase() : '';
    const telephoneFlterLower = this.telephoneToFiltred ? this.telephoneToFiltred.toLowerCase() : '';
    // if (companyNameFilterLower || tvaFilterLower || contactPersonFilterLower || telephoneFlterLower) {
    //   this.spinnerService.show();
  
    //   setTimeout(() => {
    //     this.spinnerService.hide();
    //   }, 1000); // 1 second
    // }
  
    filteredSubConstractor = this.subContractors.filter(item => {
      const companyNameFilter = companyNameFilterLower ? item.companyName.toLowerCase() === companyNameFilterLower : true;
      const contactPersonFilter = contactPersonFilterLower ? item.contactMain.toLowerCase() === contactPersonFilterLower : true;
      
  
      // Case-insensitive name search for a single character
      const tvaFilter = tvaFilterLower ? item.tva.toLowerCase().includes(tvaFilterLower) : true;
      const telephoneFilter = telephoneFlterLower ? ((item.phone.toLowerCase()).replace(/\s/g, '')).includes(telephoneFlterLower) : true;
  
      return companyNameFilter && tvaFilter && contactPersonFilter && telephoneFilter ;
    });
    this.subContractors = filteredSubConstractor;

    // Reset pagination
    this.page = 1;
  }

  resetFilters() {
    this.tvaToFiltred = ""; 
    this.telephoneToFiltred= ""; 
    this.companyNameToFiltred = undefined; 
    this.contactPersonToFiltred = undefined;
    
    this.filter();
 }
 sort(column, descend,col) {
  switch (column) {
    case "companyName":
      if (this.col1 === false) {
        this.col1 = true;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    case "tva":
      if (this.col2 === false) {
        this.col1 = false;
        this.col2 = true;
        this.col3 = false;
        this.col4 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    case "contactMain":
      if (this.col3 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = true;
        this.col4 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    case "phone":
      if (this.col4 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = true;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
      }
      break;
    default:
      this.col1 = false;
      this.col2 = false;
      this.col3 = false;
      this.col4 = false;
      break;
  }

  // if (column === "column4") {
  //   if (descend === false) {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = true;
  //   } else {
  //     this.criteria = {
  //       property: column,
  //       descending: descend
  //     };
  //     this.descend = false;
  //   }
  // } else {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: true
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: false
      };
      this.descend = false;
    }
  }
restoreSubContractor(sub_contractor: any) {
  sub_contractor = this.subContractorArchived;
  this.subContractorService
    .archive_restoreSubContractor(sub_contractor._id)
    .subscribe((e) => {
      this.iziToast.show({
        message: "Désarchivage avec succès",
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      // this.getListSubContractors()
      // this.page = 1
      this.router.navigate(["admin/prestataires"]);
    });
}
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-add-benefit',
  templateUrl: './popup-add-benefit.component.html',
  styleUrls: ['./popup-add-benefit.component.css']
})
export class PopupAddBenefitComponent implements OnInit {

  city :any 
  registerForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder,
    public modal: NgbActiveModal
    ) { }


  ngOnInit() {
   
// => {name: "Albania", code: "AL"}
    this.registerForm = this.formBuilder.group({
        name: ['',],
        

        // acceptTerms: [false, Validators.requiredTrue]
    },);
}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  // stop here if form is invalid
  if (this.registerForm.invalid) {
      return;
  }

  // display form values on success
  //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
  this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}


}



import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { SharedService } from 'src/app/_services/shared.service';
import { SuppafacService } from 'src/app/_services/suppafac.service';

@Component({
  selector: 'app-list-suppafacs-archived',
  templateUrl: './list-suppafacs-archived.component.html',
  styleUrls: ['./list-suppafacs-archived.component.css','../../../../../_shared/list.css']
})
export class ListSuppafacsArchivedComponent implements OnInit {
  descend = false;

  listSuppafacsArchives = []
  criteria: SortCriteria;
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  suppafacs :any
  decend = false
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  col5 = false
  col6 = false


  listeSites :any

  statuts :any
  ListeManager =[]
  listeContact :any
  suppafacArchived: any;
  dateToFiltred: any;
  titreToFiltred:any;
  sitesToFiltred:any;
  contactToFiltred:any;
  statutToFiltred:any;
  copyListSuppafac: any;

  constructor( private router: Router 
    ,private sharedService :SharedService
    ,private suppafacService :SuppafacService 
    , public iziToast: Ng2IzitoastService) { 
    
    
  }

  ngOnInit(): void {
this.getSupaafacs()    
  }
  getSupaafacs() {
    const isArchived = true; // Update this based on whether it's the archived component or not
    this.suppafacService.getSuppafacsArchiveds().subscribe((e) => {
      this.suppafacs = e;
      this.copyListSuppafac= this.suppafacs
      this.listeSites =   Array.from(new Set(this.suppafacs.map(item => item.site)));
      this.listeContact =   Array.from(new Set(this.suppafacs.map(item => item.contact)));
      this.statuts =  Array.from(new Set(this.suppafacs.map(item => item.statut)));
      this.ListeManager = Array.from(new Set(this.suppafacs.map(item => item.manager)));
    });
  }
  create() {
    this.router.navigate(["admin/chantiers/suppafacs/ajout"]);
  }
  details(id){
    this.router.navigate(["admin/chantiers/suppafacs/details", id]);
  }


  onTableDataChange(event: any) {
    this.page = event;
    this.suppafacs;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.suppafacs;
  }

  sort(column,descend,col)
  {


   
 switch (column) {
   
   case "date":
     if(this.col1 == false){
     this.col1 = true
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = false
     this.col6 = false
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "title":  
   if(this.col2 == false){
     this.col1 = false
     this.col2 = true
     this.col3 = true
     this.col4 = false
     this.col5 = false
     this.col6 = false
   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
   case "site":
     if(this.col3 == false){  
     this.col1 = false
     this.col2 = false
     this.col3 = true
     this.col4 = false
     this.col5 = false
     this.col6 = false
     }
     else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
     }
     break
     case "contact":   
     if(this.col4 == false){   
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = true
       this.col5 = false
       this.col6 = false

       
     }
     else
       {
         this.col1 = false
         this.col2 = false
         this.col3 = false
         this.col4 = false
         this.col5 = false
         this.col6 = false

       }
       break
   case "statut":   
   if(this.col5 == false){   
     this.col1 = false
     this.col2 = false
     this.col3 = false
     this.col4 = false
     this.col5 = true
     this.col6 = false

   }
   else
     {
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false

     }
     break
     case "manager":   
     if(this.col5 == false){   
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = true
  
     }
     else
       {
         this.col1 = false
         this.col2 = false
         this.col3 = false
         this.col4 = false
         this.col5 = false
         this.col6 = false
  
       }
       break
   default :
       this.col1 = false
       this.col2 = false
       this.col3 = false
       this.col4 = false
       this.col5 = false
       this.col6 = false
       break   

 }

 if (descend === false) {
  this.criteria = {
    property: column,
    descending: true
  };
  this.descend = true;
} else {
  this.criteria = {
    property: column,
    descending: false
  };
  this.descend = false;
}
}

getSuppafac(suppafac) {
  this.suppafacArchived = suppafac;
}

archiveSuppafac(suppafac :any)
{
  suppafac = this.suppafacArchived;  
    this.suppafacService.archive_restoreSuppafac(suppafac._id)
      .subscribe((e) => {
        this.iziToast.show({
          message: "Désarchivage avec succès",
          messageColor: "#343A40",
          progressBarColor: "#212529",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#CED4DA",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        this.router.navigate(["admin/chantiers/suppafacs"]);

      });
}

setVariableArchive(){
  this.sharedService.setVariable("archive");
}

setVariableNotArchive(){
  this.sharedService.setVariable("NotArchive");
}


gotoListArchivedSites() //gotoListArchivedSites
{
  this.router.navigate(['/admin/chantiers' ])
  this.setVariableArchive()
}

gotoListSites() //gotoListNotArchivedSites
{
  this.router.navigate(['/admin/chantiers' ])
  this.setVariableNotArchive()
}
filter() {
  this.suppafacs = this.copyListSuppafac;
  let filteredSuppafacs = [...this.copyListSuppafac];
  const dateFilterLower = this.dateToFiltred ? this.dateToFiltred.toLowerCase() : '';
  const titreFilterLower = this.titreToFiltred ? this.titreToFiltred.toLowerCase() : '';
  const sitesFilterLower = this.sitesToFiltred ? this.sitesToFiltred.toLowerCase() :'' ;
  const contactFilterLower = this.contactToFiltred ? this.contactToFiltred.toLowerCase() : '';
  const statutFilterLower = this.statutToFiltred ? this.statutToFiltred.toLowerCase() : '';
  filteredSuppafacs = this.suppafacs.filter(item => {
    const dateFilter = dateFilterLower ? new Date(item.date).getTime() === new Date(dateFilterLower).getTime() : true;
    //const dateFilter = dateFilterLower ? new Date(item.date) >= new Date(dateFilterLower) : true;
    const titreFilter = titreFilterLower ? item.title.toLowerCase().includes(titreFilterLower) : true;
    const sitesFilter = sitesFilterLower ? item.site.toLowerCase() === sitesFilterLower : true;
    const contactFilter = contactFilterLower ? item.contact.toLowerCase() === contactFilterLower : true;
    const statutFilter = statutFilterLower ? item.statut.toLowerCase() === statutFilterLower : true;


    return   dateFilter && titreFilter && sitesFilter && contactFilter && statutFilter;
  });
   this.suppafacs = filteredSuppafacs;

   // Reset pagination
   this.page = 1;
}
resetFilters() {
  this.dateToFiltred = ""; 
  this.titreToFiltred = "";
  this.sitesToFiltred = undefined;
  this.contactToFiltred = undefined; 
  this.statutToFiltred = undefined; 

 this.filter();
}

}

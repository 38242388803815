<app-navbar></app-navbar>
<header class="bg-surface pt-6">
  <div
    style="float: right; padding-right: 9%"
    class="col-lg-5 col-md-6 col-sm-12"
  >
  <ul class="breadcrumb float-md-right">
    <li class="breadcrumb-item"> 
      <a class="color-blue" style="cursor: default;" 
        ><i class="zmdi zmdi-home"></i> Accueil</a
      >
    </li>
    <li class="breadcrumb-item color-blue">
      <a class="color-blue" routerLink="../../">Prestataires</a>
    </li>
    <li *ngIf="sub_contrator" class="breadcrumb-item active color-blue " style="cursor: default;">{{sub_contrator.companyName}}</li>
  </ul>
  </div>
  <br />
  <br />
  <div class="container-fluid">
    <div class="mb-npx" >
      <div class="row align-items-center">
        <div class="col-1"></div>
        <div class="col-sm-11 col-12 mb-4 mb-sm-0">
          <!-- Title -->
          <!-- <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails du sous-traitant</h1> -->
        </div>
      </div>
    </div>
  </div>
</header>
<br>
<div class="student-profile py-4">
  <div *ngIf="sub_contrator" class="container">
    <div class="col-sm-11 col-12 mb-4 mb-sm-0">
      <!-- Title -->
      <h1 class="h2 mb-0 ls-tight color-blue title-1" style="color: #62B9CB;">{{sub_contrator.companyName}}</h1>
      <h1 class="h2 mb-0 ls-tight color-blue title-1">Détails du prestataire</h1>
      <br>
    </div>
    <div class="h2 mb-0 ls-tight text-sm-end">
      <a (click)="goToUpdateSubContractor()" class="btn d-inline-flex btn-sm btn-primary mx-1">
        <span class="button_list" style="color: #fff">Modifier</span>
      </a>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="sub_contrator" class="card shadow-sm">
          <div class="card-header bg-transparent">
            <h3 class="mb-0"><i class="bi bi-info-circle-fill"></i> Informations générales</h3>
          </div>
          <div class="card-body row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Nom :</strong>
              <p class="mb-3 title-info">{{sub_contrator.companyName}}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">TVA :</strong>
              <p class="mb-3 title-info" >
                <span class="aucun-text" *ngIf="!sub_contrator?.tva">Aucun</span>
                {{sub_contrator.tva}}
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Adresse :</strong>
              <p *ngIf="!sub_contrator.address && !sub_contrator.number && !sub_contrator.postalCode && !sub_contrator.city " class="mb-3 title-info">
                <span class="aucun-text" >Aucun</span></p>
                <p *ngIf="sub_contrator.address && sub_contrator.number"  class="mb3 title-info">{{ sub_contrator.address }}, {{ sub_contrator.number }}</p>
                <p *ngIf="sub_contrator.postalCode && sub_contrator.city" class="mb3 title-info">{{ sub_contrator.postalCode }} {{ sub_contrator.city }}</p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <strong class="pr-1 title-info">Contact principal :</strong>
              <p *ngIf="sub_contrator.contactMain" class="mb-0 title-info">{{sub_contrator.contactMain.firstName}} {{sub_contrator.contactMain.lastName}}</p>
              <p *ngIf="!sub_contrator.contactMain" class="mb-0 title-info">
                <span class="aucun-text">Aucun</span>
              </p>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Adresse mail de facturation :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!sub_contrator?.email">Aucun</span>
                  {{sub_contrator.email}}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Téléphone :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!sub_contrator?.phone">Aucun</span>
                  {{sub_contrator.phone}}
                </p>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                <strong class="pr-1 title-info">Taux horaire(€/h) :</strong>
                <p class="mb-3 title-info">
                  <span class="aucun-text" *ngIf="!sub_contrator?.tauxTime">Aucun</span>
                  {{sub_contrator.tauxTime}}
                </p>
              </div>
             
            <br>
            <br>
          </div>
        </div>
      </div>
     
    </div>
    <br>
      <div>
        <div  class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="bi bi-file-person-fill pr-1"></i>Liste des contacts de {{sub_contrator.companyName}}</h3>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                    <th scope="col">PRÉNOM</th>
                    <th scope="col">NOM </th>
                    <th scope="col">ADRESSE MAIL </th>
                    <th scope="col">TÉLÉPHONE</th>
                  </tr>
                </thead>
                <tbody *ngFor="let i of contacts  ;let k =index">
                  <tr (click)="goToDetailsContact(i._id)"  *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;cursor: pointer;">
                    <td>{{i.lastName}}</td>
                    <td>{{i.firstName}}</td>
                    <td>{{i.email}}</td>
                    <td>
                      <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                      {{i.phone}}
                    </td>
                  </tr>
                  <tr (click)="goToDetailsContact(i._id)"  *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;cursor: pointer;">
                    <td>{{i.lastName}}</td>
                    <td>{{i.firstName}}</td>
                    <td>{{i.email}}</td>
                    <td>
                      <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                      {{i.phone}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
            *ngIf="contacts && contacts.length == 0"
            class="table-responsive"
            style="text-align: center"
            >
            <p style="padding-bottom: 45px; padding-top: 35px ">
              Aucun contact n'est trouvé
            </p>
            </div>
          </div>
        </div>
      </div>
   
      <br>

       <div>
       <div  class="card shadow-sm">
         <div class="card-header bg-transparent border-0">
           <h3 class="mb-0"><i class="bi bi-diagram-3 pr-1"></i>Liste des ouvriers de {{sub_contrator.companyName}}</h3>
         </div>
         <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                  <th scope="col">PRÉNOM</th>
                  <th scope="col">NOM </th>
                  <th scope="col">TÉLÉPHONE</th>
                </tr>
              </thead>
              <tbody *ngFor="let i of workers  ;let k =index">
                <tr  (click)="goToDetailsWorker(i._id)" style="cursor: pointer;" *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden; cursor: pointer;">
                  <td> 
                    <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                    {{i.lastName }}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                    {{i.firstName}}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                    {{i.phone}}
                   </td>
                </tr>
                <tr (click)="goToDetailsWorker(i._id)"  *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden; cursor: pointer;">
                  <td> 
                    <span class="aucun-text" *ngIf="!i.lastName">Aucun</span>
                    {{i.lastName }}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.firstName">Aucun</span>
                    {{i.firstName}}</td>
                   <td>
                    <span class="aucun-text" *ngIf="!i.phone">Aucun</span>
                    {{i.phone}}
                   </td>
                </tr>
              </tbody>
            </table>
          </div>
           <div
           *ngIf="workers && workers.length == 0"
           class="table-responsive"
           style="text-align: center"
           >
           <p style="padding-bottom: 45px; padding-top: 35px ">
             Aucun ouvrier n'est trouvé
           </p>
           </div>
         </div>
       </div>
     </div>
     <br>
     
      
    
     <div>
      <div  class="card shadow-sm">
        <div class="card-header bg-transparent border-0">
          <h3 class="mb-0"><i class="bi bi-file-earmark-text-fill pr-1"></i>Liste des documents de {{sub_contrator.companyName}}</h3>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table">
              
              <!-- <h2>fff  {{listFiles | json }}</h2> -->
              <tbody *ngIf="listFiles" class="upload-name-style">
                <tr *ngFor="let item of listFiles; let i=index" (click)="openFileInNewWindow(sub_contrator.key,item)">
                    <td><strong>{{ item }}</strong></td>
                    <!-- <td><i (click)="deleteFileFromserver(myUUID,item)" style="color: #0e309f;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i></td> -->
                    <td>
                    <button
                    class="btn btn-sm btn-square btn-neutral text-danger-hover"
                  >
                    <i
                      class="bi bi-eye"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Visualiser"
                      (click)="openFileInNewWindow(sub_contrator.key,item)"
                     
                    ></i>
                  </button>
                </td>
                </tr>
            </tbody>
        </table>
          </div>
        <div
        *ngIf="listFiles && listFiles.length == 0"
        class="table-responsive"
        style="text-align: center"
        >
        <p style="padding-bottom: 45px; padding-top: 35px ">
          Aucun document n'est trouvé
        </p>
        </div>
    </div>
      </div>
    </div>
    <br>
     <div>
        <div class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="bi bi-building pr-1"></i>Liste des chantiers de {{sub_contrator.companyName}}</h3>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
             <table class="table">
               <thead >
                 <tr style="border-bottom: 1px solid #e7eaf0; border-top-color: transparent;" >
                   <th style="cursor: pointer;" (click)="sort('column2',decend,col2)" scope="col">N° chantier 
                     <i *ngIf="col2 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col2 == true" class="bi bi-chevron-up"></i>
                 </th>
                   <th style="cursor: pointer;" (click)="sort('column3',decend,col3)" scope="col">Nom 
                     <i *ngIf="col3 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col3 == true" class="bi bi-chevron-up"></i>
                 </th>
                   <th style="cursor: pointer;" (click)="sort('adress',decend,col4)" scope="col">Adresse 
                     <i *ngIf="col4 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col4 == true" class="bi bi-chevron-up"></i>
                 </th>
                   <th style="cursor: pointer;" (click)="sort('column6',decend,col6)"  scope="col">Statut 
                     <i *ngIf="col6 == false" class="bi bi-chevron-down"></i>
                     <i *ngIf="col6 == true" class="bi bi-chevron-up"></i>
                 </th>
                 </tr>
               </thead>
               <tbody *ngFor="let i of sites   | sort: criteria  ; let k =index">
                 <tr *ngIf="k%2 != 0" style="background-color: #e9ebff; border: hidden;" routerLink="/admin/chantiers/details/{{i._id}}">
                   <td >{{i.column2}}</td>
                     <td >{{i.column3}}</td>
                     <td>
                       {{ getAddressParts(i.adress).street }}, {{ getAddressParts(i.adress).number }} <br>
                       {{ getAddressParts(i.adress).postalCode }} {{ getAddressParts(i.adress).city }}
                     </td>
                     <td *ngIf="i.column6 == 'STOP'"><span class="badge badge-danger">{{i.column6}}</span></td>
                     <td *ngIf="i.column6 == 'ACTIF'"><span class="badge badge-pill badge-success">{{i.column6}}</span></td>
                     <td  *ngIf="i.column6 == 'ONE SHOT'"><span class="badge badge-pill badge-info">{{i.column6}}</span></td>
                     <td  *ngIf="i.column6 == 'OFFRE'"><span class="badge badge-pill badge-orange">{{i.column6}}</span></td>
                  
                 </tr>
                 <tr *ngIf="k%2 == 0" style="background-color: white; border-bottom: hidden;" routerLink="/admin/chantiers/details/{{i._id}}">
                   <td >{{i.column2}}</td>
                   <td>{{i.column3}}</td>
                   <td >
                     {{ getAddressParts(i.adress).street }}, {{ getAddressParts(i.adress).number }} <br>
                     {{ getAddressParts(i.adress).postalCode }} {{ getAddressParts(i.adress).city }}
                   </td>
                   <td  *ngIf="i.column6 == 'STOP'"><span class="badge badge-danger">{{i.column6}}</span></td>
                   <td  *ngIf="i.column6 == 'ACTIF'"><span class="badge badge-pill badge-success">{{i.column6}}</span></td>
                   <td  *ngIf="i.column6 == 'ONE SHOT'"><span class="badge badge-pill badge-info">{{i.column6}}</span></td>
                   <td *ngIf="i.column6 == 'OFFRE'"><span class="badge badge-pill badge-orange">{{i.column6}}</span></td>
                 
                 </tr>
                
             
               </tbody>
            </table>
            </div>
                <div
                *ngIf="sites && sites.length == 0"
                class="table-responsive"
                style="text-align: center"
                >
                <p style="padding-bottom: 45px; padding-top: 35px ">
                  Aucun chantier n'est trouvé
                </p>
                </div>
              </div>
        </div>
      </div>
     
  </div>
</div>



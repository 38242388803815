import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TypebenefitService {

  private _typeBenefitURL=`${environment.baseUrl}/typesBenefits`
  constructor(private http: HttpClient) { }

     getTypesBenefits() :Observable <any> {
      return this.http.get(`${this._typeBenefitURL}`);
      }

      createTypeBenefit(typeBenefit: any):Observable<Object> {
        return this.http.post(`${this._typeBenefitURL}`,typeBenefit);
      }

      getTypeBenefitById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._typeBenefitURL}/${id}`);
    
      } 
      
      EditTypeBenefit(id:string,TypeBenefit:any):Observable<Object> {
        return this.http.put(`${this._typeBenefitURL}/${id}`,TypeBenefit);
      }
     
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';
import { EtablissementService } from 'src/app/_services/etablissement.service';

@Component({
  selector: 'app-list-etablissement-archived',
  templateUrl: './list-etablissement-archived.component.html',
  styleUrls: ['../../../../_shared/list.css','./list-etablissement-archived.component.css']
})
export class ListEtablissementArchivedComponent implements OnInit {

  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  etablissements: any;
  etablissementsArchived: any;
  siteArchived;
  copyListEtablissemets;
  listManagers: any;
  listCities: any;
  ResponsableToFiltred: any;
  citiesToFiltred: any;
  etablissmentNameToFiltred: any;
  etablissmentAdressToFiltred: any;
  constructor(
    private router: Router,
    private etbalissementService: EtablissementService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getListEtablissements();
  }
  getListEtablissements() {
    this.etablissmentNameToFiltred = undefined
    this.etablissmentAdressToFiltred = undefined
    this.ResponsableToFiltred =undefined
    this.citiesToFiltred = undefined
  
    this.etablissementsArchived = true
    this.page = 1

    this.etbalissementService.getEtablissementArchiveds().subscribe((e) => {

      this.etablissements = e;
      this.copyListEtablissemets = this.etablissements
      this.listManagers = Array.from(new Set(this.etablissements.map(item => item.manager)));
      this.listCities =  Array.from(new Set(this.etablissements.map(item => item.city)));
    });
  }
  getEtablissement(etablissement) {
    this.etablissementsArchived = etablissement;
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.etablissements = this.etablissements;
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.etablissements;
  }
  details(id) {
    this.router.navigate(["admin/etablissements/details", id]);
  }
  filterByResponsable(event) {
    this.ResponsableToFiltred = event
  }
  filterByCities(event) {
    this.citiesToFiltred = event
  }
  filter() {
    this.etablissements = this.copyListEtablissemets;
    let filteredEtablissemets = [...this.copyListEtablissemets];
    // Convert filter criteria to lowercase
    const etablissementNameFilterLower = this.etablissmentNameToFiltred ? this.etablissmentNameToFiltred.toLowerCase() : '';
    const etablissemenAdressFilterLower = this.etablissmentAdressToFiltred ? this.etablissmentAdressToFiltred.toLowerCase() : '';
    const maangerFilterLower = this.ResponsableToFiltred ? this.ResponsableToFiltred.toLowerCase() : '';
    const citiesilterLower = this.citiesToFiltred ? this.citiesToFiltred.toLowerCase() : '';
    filteredEtablissemets = this.etablissements.filter(item => {
      const maangerFilter = maangerFilterLower ? item.manager.toLowerCase() === maangerFilterLower : true;
      const citiesFilter = citiesilterLower ? item.city.toLowerCase() === citiesilterLower : true;
      
  
      // Case-insensitive name search for a single character
      const nameFilter = etablissementNameFilterLower ? item.name.toLowerCase().includes(etablissementNameFilterLower) : true;
      const aderssFilter = etablissemenAdressFilterLower ? item.adress.toLowerCase().includes(etablissemenAdressFilterLower) : true;
  
      return nameFilter && aderssFilter && maangerFilter && citiesFilter ;
    });
    this.etablissements = filteredEtablissemets;

    // Reset pagination
    this.page = 1;
  }

  resetFilters() {
    this.etablissmentNameToFiltred = ""; 
    this.etablissmentAdressToFiltred = ""; 
    this.ResponsableToFiltred = undefined; 
    this.citiesToFiltred = undefined;
    
    this.filter();
 }
 sort(column, descend,col) {
  switch (column) {
    case "name":
      if (this.col1 === false) {
        this.col1 = true;
        this.col2 = false;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "city":
      if (this.col2 === false) {
        this.col1 = false;
        this.col2 = true;
        this.col3 = false;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    case "manager":
      if (this.col3 === false) {
        this.col1 = false;
        this.col2 = false;
        this.col3 = true;
      } else {
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
      }
      break;
    default:
      this.col1 = false;
      this.col2 = false;
      this.col3 = false;
      break;
  }

  if (column === "name" || column === "city" || column === "manager")  {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: descend
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: descend
      };
      this.descend = false;
    }
  } else {
    if (descend === false) {
      this.criteria = {
        property: column,
        descending: true
      };
      this.descend = true;
    } else {
      this.criteria = {
        property: column,
        descending: false
      };
      this.descend = false;
    }
  }
}
restoreEtablissment(etablissement: any) {
  etablissement = this.etablissementsArchived;
  this.etbalissementService
    .archive_restoreEtablissements(etablissement._id)
    .subscribe((e) => {
      this.iziToast.show({
        message: "Désarchivage avec succès",
        messageColor: "#386641",
        progressBarColor: "#6a994e",
        icon: "bi-check-circle",
        imageWidth: 45,
        position: "topRight",
        timeout: 5000,
        backgroundColor: "#dde5b6",
        transitionIn: "flipInX",
        transitionOut: "flipOutX",
      });
      // this.getListEtablissements()
      // this.page = 1
      this.router.navigate(["admin/etablissements"]);
    });
}
getAddressParts(address) {
  const parts = address.split('/n');
  const street = parts.slice(0, parts.length - 3).join('/n');
  const number = parts[parts.length - 3];
  const postalCode = parts[parts.length - 2];
  const city = parts[parts.length - 1];
  return { street, number, postalCode, city };
}
}

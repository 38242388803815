<!-- main app container -->
<app-navbar></app-navbar>
  <header class="bg-surface  pt-6">
    <div style="float: right;padding-right: 9%;" class="col-lg-5 col-md-6 col-sm-12">
      <ul class="breadcrumb float-md-right">
        <li class="breadcrumb-item"> 
          <a class="color-blue" style="cursor: default;" 
            ><i class="zmdi zmdi-home"></i> Accueil</a
          >
        </li>
        <li class="breadcrumb-item color-blue">
          <a class="color-blue" routerLink="../">Suppafacs</a>
        </li>
        <li class="breadcrumb-item active color-blue " style="cursor: default;">Créer un suppafac</li>
      </ul>
    </div>
    <br>
    <br>
    <div class="container-fluid">
      <div class="mb-npx" style="width: 92%;">
        <div class="row align-items-center">
            <div class="col-1"></div>
          <div class="col-sm-11 col-12 mb-4 mb-sm-0 ">
            <!-- Title -->
            <h1 class="h2 mb-0 ls-tight color-blue title-1" >
              Créer un suppafac
            </h1>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-6 col-sm-6"></div>
        </div>
      
       
      </div>
    </div>
  </header>
  <br>
<div class="card m-3-cust">
    
    <div class="card-body ml-5">
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label>Chantier <b style="color: red;"> * </b></label>
                <ng-select [items]="sites"
                bindLabel="column3"
                bindValue="_id"
                formControlName="site"
                class="form-control input-custom"
                [closeOnSelect]="true"
                [ngClass]="{ 'is-invalid': submitted && f.site.errors }"
            >
            </ng-select>
            <div *ngIf="submitted && f.site.errors" class="invalid-feedback">
              <div *ngIf="f.site.errors.required">Champ obligatoire.</div>
            </div> 
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Personne de contact <b style="color: red;"> * </b></label>
              <ng-select
                    [items]="contacts"
                    formControlName="contact"
                    bindLabel="firstName_lastName"
                    bindValue="_id"
                    class="form-control"
                    [closeOnSelect]="true"
                    [ngClass]="{ 'is-invalid': submitted && f.contact.errors }"
                     >
                    </ng-select>
                    <div *ngIf="submitted && f.contact.errors" class="invalid-feedback">
                      <div *ngIf="f.contact.errors.required">Champ obligatoire.</div>
                    </div>
          </div>
        </div>
        <div class="form-row">
        <div class="form-group col">
              <label>Titre <b style="color: red;"> * </b></label>
              <input type="text" formControlName="title" class="form-control input-custom" 
              [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
              <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                  <div *ngIf="f.title.errors.required">Champ obligatoire.</div>
              </div>
            
          </div>
          </div>

       <div class="form-row">
          <div class="form-group col">
              <label>Description <b style="color: red;"> * </b></label>
                <angular-editor
                formControlName="description"
                [config]="editorConfig"
                [ngClass]="{ 'is-invalid': submitted && f.description.errors }">
              ></angular-editor>
                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                  <div *ngIf="f.description.errors.required">Champ obligatoire.</div>
              </div>
           
          </div>
         
      </div>

       <div class="form-row">
        <div class="form-group col">
            <label>Géré par <b style="color: red;"> * </b></label>
            <ng-select [items]="admins"
                 bindLabel="firstName"
                bindValue="_id"
                  formControlName="manager"
                  class="form-control"
                  [closeOnSelect]="true"
                  [ngClass]="{ 'is-invalid': submitted && f.manager.errors }"
                  >
                  </ng-select>
                  <div *ngIf="submitted && f.manager.errors" class="invalid-feedback">
                    <div *ngIf="f.manager.errors.required">Champ obligatoire.</div>
                  </div>
                 
    
         
        </div>
    </div>
   

    <div class="form-row">
        <div class="form-group col">
          <label>Planifié <b style="color: red;"> * </b></label>
          <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <input
                  type="radio"
                  formControlName="planfie"
                  [value]="true"
                />
                Oui
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <input
                  type="radio"
                  formControlName="planfie"
                  [value]="false"
                />
                Non
              </div>
            </div>
      </div>
      <div *ngIf="registerForm.value.planfie == true " class="form-group col">
        <label>Date(s) de planification <b style="color: red;"> * </b>
          <i
          style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addDateField()"></i>
        </label>
        <div  *ngFor="let date of dateForms.controls; let i = index">
        <label> Date {{i+1}}
          <i  *ngIf="i > 0" style="cursor: pointer;" class="bi bi-trash" (click)="removeQuantity(i)"></i>
          </label>  
        <input *ngIf="i == 0"  type="date"  [(ngModel)]="firstDate" [formControl]="date.get('datePlanfie')" class="form-control input-custom"  
         />
        <input *ngIf="i > 0" type="date"  [formControl]="date.get('datePlanfie')" class="form-control input-custom"  
        [ngClass]="{ 'is-invalid': submitted && date.get('datePlanfie').errors }"/>
        <div *ngIf="submitted && date.get('datePlanfie').errors" class="invalid-feedback">
          <div *ngIf="date.get('datePlanfie').errors.required">Champ obligatoire.</div>
        </div>
        </div>
      
    </div>
      </div>
 
      <div class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <label>Statut <b style="color: red;"> * </b></label>
            <ng-select [items]="statuts"
                  formControlName="statut"
                  class="form-control"
                  [closeOnSelect]="true"
                  [ngClass]="{ 'is-invalid': submitted && f.statut.errors }"
                  >
                  </ng-select>
                  <div *ngIf="submitted && f.statut.errors" class="invalid-feedback">
                    <div *ngIf="f.statut.errors.required">Champ obligatoire.</div>
                  </div>
                 
         
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <label>Ouvrier(s)</label>
          <ng-select [items]="workers"
                formControlName="worker"
                bindLabel="firstName_lastName"
                bindValue="_id"
                class="form-control"
                [closeOnSelect]="true"
                multiple = true
                >
            </ng-select>
            
      </div>  
     </div>

    <div class="form-row">
      <div class="form-group col">
        <label>Durée</label>
        <input type="text" formControlName="duration" class="form-control input-custom"
        />
       
      </div>
    </div> 

    <div class="form-group row" class="center">
      <label>Documents</label>
      <ngx-file-drop  dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
      (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
          <ng-template  ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <p style="color: #636363;">Déposer vos fichiers ici </p> 
        
            <a (click)="openFileSelector()"><i class="bi bi-file-earmark-arrow-down i-file"></i>
             
            </a>
          </ng-template>
   
          <p style="color: #636363;">les types des fichiers doivent étre pdf,png,jpg,jpeg,txt </p> 
      </ngx-file-drop>
      <div class="upload-table">
          <table class="table">
              <thead>
                  <tr>
                      <th>Fichier :</th>
                  </tr>
              </thead>
              <!-- <h2>fff  {{listFiles | json }}</h2> -->
              <tbody class="upload-name-style">
                  <tr *ngFor="let item of listFiles; let i=index">
                      <td><strong>{{ item}}</strong></td>
                      <td><i (click)="deleteFileFromListFiles(item)" style="color: #0e309f;cursor: pointer;" title="Supprimer" class="bi bi-trash"></i></td>
                  </tr>
              </tbody>
          </table>
      </div>
    </div>
    <br>

          <div style="padding-top: 3%;" class="text-center">
              <button routerLink="/admin/chantiers/suppafacs" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
              <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
          </div>
      </form>
    </div>
</div>
<br>


<!-- credits -->


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BenefitGlassService } from 'src/app/_services/benefit-glass.service';
import { BenefitService } from 'src/app/_services/benefit.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SharedService } from 'src/app/_services/shared.service';
import { SiteService } from 'src/app/_services/site.service';
import { SuppafacService } from 'src/app/_services/suppafac.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-site',
  templateUrl: './details-site.component.html',
  styleUrls: ['./details-site.component.css','../../../../_shared/list.css']
})
export class DetailsSiteComponent implements OnInit {
  BaseUrl_site = environment.baseUrl.split('/api')[0] + '/media/sites/';

  id 
  site
  adress
  contacts
  benefits
  benefitsGlass
  listFiles
  suppafacs
  benefitSelected
  listOccurrences : any
  constructor(
    private siteService :SiteService,
    private route :ActivatedRoute,
    private benefitService : BenefitService,
    private benefitGlassService :BenefitGlassService,
    private suppafacService : SuppafacService,
    private sharedService :SharedService,
    private router : Router
    ) { }
  
  
    ngOnInit(): void {
      this.id = this.route.snapshot.params["id"];
     this.siteService.getSiteById(this.id).subscribe(data => {
        this.site = data;
        this.adress = this.site.adress[0].street +',' + ' '+ this.site.adress[0].number +' ' +this.site.adress[0].postalCode +' '+this.site.adress[0].city
       
     })
     this.getContactsBySite()
     this.getlistDocumentsSite()
     this.getBenefits()
     this.getBenefitsGlasss()
     this.getSuppafacsBySite()
}

getContactsBySite(){
  this.siteService.listContactsBySite(this.id).subscribe(data => {
    this.contacts = data;
   
 })

}

getSuppafacsBySite(){
  this.suppafacService.getListsSuppafacsBySite(this.id).subscribe(data => {
    this.suppafacs = data;
   
 })

}

getlistDocumentsSite(){
  this.siteService.listNamesFiles(this.id).subscribe(data => {
    this.listFiles = data;
  })
}

// list benefit  by curent site
getBenefits(){
  this.benefitService.getBenefitsBysite(this.id).subscribe(data => {
    this.benefits = data;
    
   
 })
}

// list benefit glass by curent site
getBenefitsGlasss(){
  this.benefitGlassService.getBenefitsGlassBysite(this.id).subscribe(data => {
    this.benefitsGlass = data;
    
   
 })
}

addBenefit(){
  this.sharedService.setIdSiteSendTOBenefit(this.id);
  this.router.navigate(["admin/prestations/ajout"]);
}

addBenefitGlass(){
  this.sharedService.setIdSiteSendTOBenefitGlass(this.id);
  this.router.navigate(["admin/chantiers/vitres/ajout"]);
}
goToUpdateSite(){
  this.router.navigate(["admin/chantiers/modification", this.id]);
}
details(id) {
  this.router.navigate(["admin/prestations/details", id]);
}


detailsBenfitGlass(id) {
  this.router.navigate(["admin/chantiers/vitres/details", id]);
}
openFileInNewWindow(key: string,filename) {
  let url :any

  url =this.BaseUrl_site+key+"/"+filename
  
 
  window.open(url, '_blank');
}


// getBenefit(benefit) {
//   this.benefitService
//     .getOccurrencesByBenefit(benefit._id)
//     .subscribe((e) => {
//       this.listOccurrences =  e 
//     });
// }

getBenefit(benefit) {
    this.router.navigate(["admin/occurrences",benefit._id]);
}
getStyle(i){
  return {
    'background-color': i.color,
  };
}

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BenefitGlassService } from 'src/app/_services/benefit-glass.service';

@Component({
  selector: 'app-details-glass',
  templateUrl: './details-glass.component.html',
  styleUrls: ['./details-glass.component.css','../../../../../_shared/list.css']
})
export class DetailsGlassComponent implements OnInit {

  registerForm: FormGroup;
  id :any 
  benefit: any;
  submitted = false;
  occurenceToUpdated
  listOccurrences :any
  dateConverted
  statuts =["Planifiée","Effectuée","Annulée"]
  page: number = 1;
  count: number = 0;
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  constructor(
    private benefitService :BenefitGlassService,
    private route :ActivatedRoute,
    private formBuilder: FormBuilder,
    private router :Router
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      date: ['',],
      name : ['',],
      benefit : [,],
      site : [,],
      confirmationRequired :[Boolean],
      emailSent :[Boolean],
      confirmationSendMail :[Boolean],
      // type send a backend if date or planning
      typeUpdate : []
        },);
    this.id = this.route.snapshot.params["id"];
    this.benefitService.getDetailsBenefitGlass(this.id).subscribe(data => {
      this.benefit = data;
     
   })
   this.getOccurences()
  }
  getOccurences() {
    this.benefitService
      .getOccurrencesByBenefit(this.id)
      .subscribe((e) => {
        this.listOccurrences =  e 
      });
  }
  onTableDataChange(event: any) {
    this.page = event;
    this.listOccurrences = this.listOccurrences
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getOccurences()
  }
  onSubmit() {
    this.submitted = true;
  
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    this.registerForm.value.status = "Modifiée"
    this.registerForm.value.name = this.occurenceToUpdated.name
    this.registerForm.value.benefit = this.benefit._id
    this.registerForm.value.site =  this.benefit.site._id
    this.registerForm.value.date =  this.dateConverted
    this.registerForm.value.typeUpdate ="typeDate"
    this.registerForm.value.confirmationRequired = this.occurenceToUpdated.confirmationRequired,
    this.registerForm.value.emailSent = this.occurenceToUpdated.emailSent,
    this.registerForm.value.confirmationSendMail = this.occurenceToUpdated.confirmationSendMail,


    this.benefitService.EditOccurence(this.occurenceToUpdated._id,this.registerForm.value).subscribe(
      (data) => {
       this.getOccurences()
  
      })
      }
  updateDate(occurence){
    for(let occ of this.listOccurrences){
      if(occ.toUpdated === true){
        occ.toUpdated = false
      }
    }
    occurence.toUpdated = true
    this.occurenceToUpdated = occurence;
    const [year, month, day] = this.occurenceToUpdated.date.split('-').map(Number);
    const initialDate = new Date(Date.UTC(year, month - 1, day));
    this.dateConverted = initialDate.toISOString().split('T')[0];
  }
  
  
  cancel(occurence){
    occurence.toUpdated = false
    occurence.toUpdatedStatus = false
  }
  getOcccurence(i){
    this.occurenceToUpdated = undefined
    this.occurenceToUpdated = i
    this.registerForm.patchValue({
      date: this.occurenceToUpdated.date,
      name: this.occurenceToUpdated.name,
      benefit: this.occurenceToUpdated.benefit,
      site: this.occurenceToUpdated.site,
      confirmationRequired: this.occurenceToUpdated.confirmationRequired,
      emailSent: this.occurenceToUpdated.emailSent,
      confirmationSendMail: this.occurenceToUpdated.confirmationSendMail,
    });
  }
  onSubmitQuizReccrence()
  {
    this.registerForm.value.typeUpdate ="typeQuiz"
    this.registerForm.value.status = "Modifiée"
    this.registerForm.value.name = this.occurenceToUpdated.name
    this.registerForm.value.benefit = this.benefit._id
    this.registerForm.value.site =  this.benefit.site._id
    this.registerForm.value.typeUpdate ="typeQuiz"
    this.benefitService.EditOccurence(this.occurenceToUpdated._id,this.registerForm.value).subscribe(
      (data) => {
        this.getOccurences()
      })
      }
  
      goToUpdateGlass(){
        this.router.navigate(["admin/chantiers/vitres/modification", this.id]);
      }
 

}

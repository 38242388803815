import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { WorkerService } from 'src/app/_services/worker.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-add-worker',
  templateUrl: './add-worker.component.html',
  styleUrls: ['./add-worker.component.css','../../../../_shared/add_update.css']
})
export class AddWorkerComponent implements OnInit {

  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  registerForm: FormGroup;
  submitted = false;
  worksite = [
    {  name: 'Chantier 1' },
    {  name: 'Chantier 2' },
    {  name: 'Chantier 3' },
   
  ]

  // ServiceProvider = [
  //   {  name: 'Prestataire 1' },
  //   {  name: 'Prestataire 2' },
  //   {  name: 'Prestataire 3' },
   
  // ]

  subContractors : any 
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private workerService :WorkerService,
    private subcontractorService :SubContractorService,
    public iziToast: Ng2IzitoastService) { }


  ngOnInit() {
    this.registerForm = this.formBuilder.group({
        // title: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        subContractor :[],
        phone : ['', Validators.required],
        email:['', Validators.email],
        worksite: ['',],
        comment: ['', Validators.maxLength(200)],
        // acceptTerms: [false, Validators.requiredTrue]
    },);
    this.getListSubContractors()
}
getListSubContractors() {
  this.subcontractorService.getList_subContractor().subscribe((e) => {
    this.subContractors = e;
  });
}
get f() { return this.registerForm.controls; }

onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber 
    this.workerService.createWorker(this.registerForm.value).subscribe(
      (data) => {
       
        
        this.iziToast.show({
          message:'Ajout avec succès',
          messageColor:'#386641',
          progressBarColor:'#6a994e',
          icon:'bi-check-circle',
          imageWidth:45,
          position:'topRight',
          timeout:5000,
          backgroundColor:'#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
           });
           this.router.navigate(['/admin/ouvriers' ])
          })
  
}

onReset() {
    // this.submitted = false;
    // this.registerForm.reset();

}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ClientService } from 'src/app/_services/client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { SortCriteria } from 'src/app/_helpers/sort.pipe';

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['../../../_shared/list.css','./list-clients.component.css']
})
export class ListClientsComponent implements OnInit {
  page: number = 1;
  count: number = 0;
  criteria: SortCriteria;
  descend = false;
  col1 = false
  col2 = false
  col3 = false
  col4 = false
  tableSize: number = 10;
  tableSizes: any = [3, 6, 9, 12];
  clients :any
  clientArchived
  copyListClients;
  ListCompanyName:any;
  ListContactPrincipale:any;
  companyNameToFiltred:any;
  tvaToFiltred:any;
  contactPrincipalToFiltred:any;
  emailFacturationToFiltred:any;
  constructor( private router: Router,
    private clientService :ClientService,
    public iziToast: Ng2IzitoastService,
    private spinnerService :NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.getClients()
    localStorage.removeItem("lastPageContactVisited")
    localStorage.removeItem("lastPageBenefitVisited")
    localStorage.removeItem("lastPageDocumentVisited")
    localStorage.removeItem("lastPageEtablissementVisited")
    localStorage.removeItem("lastPageSiteVisited")
    localStorage.removeItem("lastPageWorkerVisited")
    localStorage.removeItem("lastPageSubContractorVisited")
    localStorage.removeItem("lastPageSuppafacVisited")
    localStorage.removeItem("lastPageGlassVisited")




    if(localStorage.getItem("lastPageClientVisited") && 
    localStorage.getItem("lastPageClientVisited") != undefined && 
    localStorage.getItem("lastPageClientVisited") != "")
   this.page = Number(localStorage.getItem("lastPageClientVisited"))
  }
  getClients() {
    this.clientService.getClientsStorm().subscribe((e) => {
      this.clients = e.reverse();
      this.copyListClients = this.clients;
      this.ListCompanyName = Array.from(new Set(this.clients.map(item => item.companyName)));
      this.ListContactPrincipale =   Array.from(new Set(this.clients.filter(item => item.contactMain !== "").map(item => item.contactMain)))
    });
  }
  create() {
    this.router.navigate(["admin/clients/ajout"]);
  }

  getClient(site) {
    this.clientArchived = site;
  }

  archiveClient(client: any) {
    client = this.clientArchived;
    let index = this.clients.findIndex(cl => cl._id === client._id);
    let lastDigit = index % 10;
     if (lastDigit === 0) {
      this.page--;
      localStorage.setItem("lastPageClientVisited",this.page.toString())
    }
    this.clientService
      .archive_restoreClient(client._id)
      .subscribe((e) => {
        this.iziToast.show({
          message: "Archivage avec succès",
          messageColor: "#343A40",
          progressBarColor: "#212529",
          icon: "bi-check-circle",
          imageWidth: 45,
          position: "topRight",
          timeout: 5000,
          backgroundColor: "#CED4DA",
          transitionIn: "flipInX",
          transitionOut: "flipOutX",
        });
        // this.getClients()
        // this.page = 1
        this.router.navigate(["admin/clients/archives"]);
      });
  }

  
  onTableDataChange(event: any) {
    this.page = event;
    this.clients = this.clients;
    localStorage.setItem("lastPageClientVisited",this.page.toString())

  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getClients()
  }

  details(id) {
    const url = `admin/clients/details/${id}`;
    window.open(url, '_blank');
  }

  update(id) {
    this.router.navigate(["admin/clients/modification", id]);
  }
  filterByCompanyName(event) {
    this.companyNameToFiltred = event
  }
  filterByContactPrincipale(event) {
    this.contactPrincipalToFiltred = event
  }
  filter() {
    this.clients = this.copyListClients;
    let filteredClients = [...this.copyListClients];
    // Convert filter criteria to lowercase
    const companyNameFilterLower = this.companyNameToFiltred ? this.companyNameToFiltred.toLowerCase() : '';
    const tvaFilterLower = this.tvaToFiltred ? this.tvaToFiltred.toLowerCase() : '';
    const contactPrincipalFilterLower = this.contactPrincipalToFiltred ? this.contactPrincipalToFiltred.toLowerCase() : '';
    const emailFacturationFilterLower = this.emailFacturationToFiltred ? this.emailFacturationToFiltred.toLowerCase() : '';
    // if (companyNameFilterLower || tvaFilterLower || contactPrincipalFilterLower || emailFacturationFilterLower) {
    //   this.spinnerService.show();
  
    //   setTimeout(() => {
    //     this.spinnerService.hide();
    //   }, 1000); // 1 second
    // }
  
    filteredClients = this.clients.filter(item => {
      const companyNameFilter = companyNameFilterLower ? item.companyName.toLowerCase() === companyNameFilterLower : true;
      const contactPrincipalFilter = contactPrincipalFilterLower ? item.contactMain.toLowerCase() === contactPrincipalFilterLower : true;
  
      // Case-insensitive name search for a single character
      const tvaFilter = tvaFilterLower ? item.tva.toLowerCase().includes(tvaFilterLower) : true;
      const mailFacturationFilter = emailFacturationFilterLower ? item.email.toLowerCase().includes(emailFacturationFilterLower) : true;
      
      return companyNameFilter && tvaFilter && contactPrincipalFilter && mailFacturationFilter ;
    });
    this.clients = filteredClients;

    // Reset pagination
    this.page = 1;
  }

  resetFilters() {
    this.tvaToFiltred = ""; 
    this.emailFacturationToFiltred = ""; 
    this.companyNameToFiltred = undefined; 
    this.contactPrincipalToFiltred = undefined;
    
    this.filter();
 }
  
  exportToExcel(clients: any[]): void {
    const currentDate = new Date().toLocaleDateString('en-GB').replace(/\//g, '');
    const data = clients.map(client => {
      return {
        'NOM DE LA SOCIÉTÉ': client.companyName,
        'TVA': client.tva,
        'CONTACT PRINCIPAL': client.contactMain,
        'ADRESSE MAIL ': client.email,
      };
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const columnWidths = [
      { wpx: 380 },
      { wpx: 200 }, 
      { wpx: 200 }, 
      { wpx: 230 }, 
    ];
    ws['!cols'] = columnWidths;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Clients');
    const filename = `${currentDate}_clients.xls`;
    XLSX.writeFile(wb, filename );
  }
  sort(column, descend,col) {
    switch (column) {
      case "companyName":
        if (this.col1 === false) {
          this.col1 = true;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      case "tva":
        if (this.col2 === false) {
          this.col1 = false;
          this.col2 = true;
          this.col3 = false;
          this.col4 = false;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      case "contactMain":
        if (this.col3 === false) {
          this.col1 = false;
          this.col2 = false;
          this.col3 = true;
          this.col4 = false;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      case "email":
        if (this.col4 === false) {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = true;
        } else {
          this.col1 = false;
          this.col2 = false;
          this.col3 = false;
          this.col4 = false;
        }
        break;
      default:
        this.col1 = false;
        this.col2 = false;
        this.col3 = false;
        this.col4 = false;
        break;
    }
  
    // if (column === "column4") {
    //   if (descend === false) {
    //     this.criteria = {
    //       property: column,
    //       descending: descend
    //     };
    //     this.descend = true;
    //   } else {
    //     this.criteria = {
    //       property: column,
    //       descending: descend
    //     };
    //     this.descend = false;
    //   }
    // } else {
      if (descend === false) {
        this.criteria = {
          property: column,
          descending: true
        };
        this.descend = true;
      } else {
        this.criteria = {
          property: column,
          descending: false
        };
        this.descend = false;
      }
    }
  // }
  
}

<div class="modal-header">
    <h4 class="modal-title">Créer un client</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
       
    <div >
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
          <div class="form-row">
              <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Nom de la société<b style="color: red;"> *</b></label>
                  <input type="text" formControlName="companyName" class="form-control input-custom" 
                  [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
                  <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                      <div *ngIf="f.companyName.errors.required">Champ obligatoire.</div>
                  </div>
               
              </div>

              <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-8 col-12">
                <label>TVA</label>
                <input type="text" formControlName="tva"  class="form-control input-custom"  />
              
            </div>
            <div class="form-group col-xl-1 col-lg-1 col-md-6 col-sm-4 col-12">
              <label>Autoliquidation</label><br>
              <ui-switch
              formControlName="autoliquidation"
              
              size="small"
              
              
            >
            </ui-switch>
            
          </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
                <label>Pays</label>
                <ng-select [items]="list_contries"
                formControlName="country"
                class="form-control input-custom"
                [closeOnSelect]="true"
                >
                </ng-select>
         
        </div>
             
            </div>
          <div class="form-row">
            <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Rue</label>
                <input type="text" formControlName="street" class="form-control input-custom"  />
             
            </div>
            <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <label>Numéro</label>
              <input type="text" formControlName="number"  class="form-control input-custom"  />
    
            
          </div>
        </div>

        <div *ngIf="registerForm.value.country && registerForm.value.country == 'Belgique'" class="form-row">
          <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <label>Code postal</label>
              <ng-select [items]="codes_postales"
                  bindLabel="postal_code"
                  formControlName="postalCode"
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  (change)="getCity()"
                  >
                  </ng-select>
           
          </div>
          <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <label>Ville</label>
            <input disabled type="text"  formControlName="city" [(ngModel)]="this.city_codePostal" class="form-control input-custom"  />
          
        </div>
      </div>

      <div *ngIf="registerForm.value.country != 'Belgique'" class="form-row">
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <label>Code postal</label>
                <input  type="text"  formControlName="postalCode"  class="form-control input-custom"  />
         
        </div>
        <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Ville</label>
          <input type="text"  formControlName="city"  class="form-control input-custom"  />
        
      </div>
    </div>

      <div class="form-row">
        <div class="form-group col">
            <label>Contact(s)</label>
            <ng-select [items]="contacts"
                  bindLabel="firstName_lastName"
                  bindValue="_id"                  
                  formControlName="contact"
                  class="form-control"
                  [closeOnSelect]="true"
                  multiple = true
                  (change)="getContactByThisClient()"
                  >
                  </ng-select>
    
         
        </div>
        </div>
        <div class="form-row">
      <div class="form-group col">
        <label>Contact principal</label>
        <ng-select [items]="listContactsByThisClient"
              bindLabel="firstName_lastName"
              bindValue="_id"
              formControlName="contactMain"
              class="form-control"
              [closeOnSelect]="true"
              [ngClass]="{ 'is-invalid': submitted && f.contactMain.errors }"
              >
              </ng-select>
              <div *ngIf="submitted && f.contactMain.errors" class="invalid-feedback">
                <div *ngIf="f.contactMain.errors.required">Champ obligatoire.</div>
              </div>

     
    </div>
        <!-- <div class="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <label>Chantier(s)</label>
          <ng-select [items]="worksite"
          bindLabel="name"
          formControlName="constructionSite"
          class="form-control input-custom"
          [closeOnSelect]="true"
          [multiple]="true"
          >
          </ng-select>
        
      </div> -->
    </div>
    <div class="form-row">
      <div *ngIf="!etab" class="form-group col">
        <label>Etablissement(s)</label>
        <ng-select [items]="etablissements"
              bindLabel="name"
              bindValue="_id"
              [(ngModel)]="etablissementRecovered"
              formControlName="etablissement"
              class="form-control"
              [closeOnSelect]="true"
              multiple = true
              >
              </ng-select>
    </div>
    <div *ngIf="etab && etablissementRecovered" class="form-group col">
      <label>Etablissement(s)</label>
      <input formControlName="etablissement"  class="form-control input-custom" type="text" [(ngModel)]="etablissementRecovered.name" disabled>
  </div>
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label>Adresse mail </label>
          <input type="text" formControlName="email" class="form-control input-custom" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <!-- <div *ngIf="f.email.errors.required">Email is required</div> -->
              <div *ngIf="f.email.errors.email">Merci de saisir une adresse mail valide.</div>
          </div>
       
      </div>
      </div>   
         
          <div style="padding-top: 3%;" class="text-center">
              
              <button (click)="onReset()" class="btn btn-secondary mr-4 button_list mb-2" type="reset">Annuler</button>
              <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
          </div>
      </form>
      </div>
  </div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-templ-admin',
  templateUrl: './templ-admin.component.html',
  styleUrls: ['./templ-admin.component.css']
})
export class TemplAdminComponent implements OnInit {
  currentUser
  idp :string[];
  ids: string;
  navbarclass:any = "collapse navbar-collapse";
  user
  d
  curentUrl
  constructor(private authentificationService :AuthenticationService, private token :TokenStorageService,private router: Router , private userService :UserService
    ,private spinnerService: NgxSpinnerService ,private sharedService :SharedService) { 
    this.authentificationService.currentUser.subscribe(

      (x) => (this.currentUser = x)
      
    )  
  }

  ngOnInit(): void {
    this.sharedService.currentUrl.subscribe((value) => {
      this.curentUrl = value;
    });
  
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 2000); // 2 seconds
    this.currentUser=localStorage.getItem('user')
   
  }
  logout()
  {
    this.userService.disconnetUser(this.currentUser).subscribe(async data=>{
    })
    this.authentificationService.logout()
  }

  changenavbarclass(){
    if(this.navbarclass.indexOf("show") == -1 ){
      this.navbarclass = "collapse navbar-collapse show";
    }else{
      this.navbarclass = "collapse navbar-collapse";
    }
  }
 
  
  setVariableNotArchive(){
    this.sharedService.setVariable("NotArchive");
  }
   
  gotoListSites() //gotoListNotArchivedSites
  {
    this.router.navigate(['/admin/chantiers' ])
    this.setVariableNotArchive()
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { ClientService } from 'src/app/_services/client.service';
import { ContactService } from 'src/app/_services/contact.service';
import { SubContractorService } from 'src/app/_services/sub-contractor.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-add-contact',
  templateUrl: './popup-add-contact.component.html',
  styleUrls: ['./popup-add-contact.component.css']
})
export class PopupAddContactComponent implements OnInit {

  separateDialCode = true ;
	SearchCountryField = SearchCountryField;
	TooltipLabel ;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
 
  registerForm: FormGroup;
    submitted = false;
    idClient_subContractor 
    gendres = ['Mr','Mme']
    clients :any 
    clients_subContractors :any 
    languages = ["FR","NL","EN"]
  value: any;
  client
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private contactService :ContactService,
    private clientService :ClientService,
    private subContractorService :SubContractorService,
    public iziToast: Ng2IzitoastService,
    public modal: NgbActiveModal) { }

   
    ngOnInit() {
      this.registerForm = this.formBuilder.group({
          gendre: [''],
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          companyClient: [],
          companySubContractor: [],
          phone: ['',],
          email: ['', [Validators.required, Validators.email]],
          language: ['',],
          comment : ['',],
      },);
      this.getClients()
      this.getClients_subContractors()
  }

  getClients() {
    this.clientService.getClients().subscribe((e) => {
      this.clients = e;
    });
  }

  getClients_subContractors(){
    this.contactService.getList_clients_subContractors().subscribe((e) => {
      this.clients_subContractors = e;
    });
  
  }

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
  this.submitted = true;

  if (this.registerForm.invalid) {
    return;
}

if(this.registerForm.value.phone){
  this.registerForm.value.phone = this.registerForm.value.phone.internationalNumber 
}
// display form values on success
//
let clientsResults = []
clientsResults.push(this.client)
this.registerForm.value.companyClient=clientsResults
this.contactService.createContact(this.registerForm.value).subscribe(
(data) => {
 
  
  // this.iziToast.show({
  //   message:'Ajout avec succés',
  //   messageColor:'#386641',
  //   progressBarColor:'#6a994e',
  //   icon:'bi-check-circle',
  //   imageWidth:45,
  //   position:'topRight',
  //   timeout:5000,
  //   backgroundColor:'#dde5b6',
  //   transitionIn: 'flipInX',
  //   transitionOut: 'flipOutX',
  //    });
  //    this.router.navigate(['/admin/contacts' ])
  this.modal.dismiss();
    })
 
  this.modal.dismiss();
}

onReset() {
  this.modal.dismiss();

}

}

<div class="modal-header">
    <h4 class="modal-title">Créer une prestation</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
       
    <div >
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
            
             
            <!-- <div class="form-row">
              <div class="form-group col-4">
                  <label>Genre</label>
                  <ng-select [items]="gendres"
                  bindLabel="name"
                  class="form-control input-custom"
                  [closeOnSelect]="true"
                  >
             </ng-select>
                  
                </div>
              <div class="form-group col-4">
                  <label></label>
                  <input type="text" class="form-control input-custom"  />
                
              </div>
              <div class="form-group col-4">
                  <label>Prénom</label>
                  <input type="text"  class="form-control input-custom" />
              </div>
          </div> -->
              <div class="form-row">
                  <div class="form-group col">
                      <label>Nom </label>
                      <input type="text" formControlName="name" class="form-control input-custom" 
                     />
                    
                   
                  </div>
    
                 
                
              </div>
             
    
              
    
    

     
          
    
            
    
       
       
             
              <div style="padding-top: 3%;" class="text-center">
                  
                  <button (click)="onReset()" class="btn btn-secondary mr-4 button_list" type="reset">Annuler</button>
                  <button  class="btn btn-primary button_list">Sauvegarder</button>
              </div>
          </form>
      </div>
  </div>


<!-- main app container -->
<div class="modal-header">
    <h4 class="modal-title">Créer un établissement</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
       
    <div>
    <form
      [formGroup]="registerForm"
      (ngSubmit)="onSubmit()"
      
    >
    <div class="form-row">
        <div class="form-group col-12">
          <label>Nom de l'établissement <b style="color: red;"> *</b></label>
          <input
            type="text"
            formControlName="name"
            class="form-control input-custom"
            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="f.name.errors.required">Champ obligatoire.</div>
                  </div>
          
        </div>
 
      </div>
    
       <div class="form-row">
    <div class="form-group col-12" formArrayName="adress">
        <label>Adresse <b style="color: red;"> * </b>
          <i *ngIf="this.registerForm.value.adress[0].street  &&  this.registerForm.value.adress[0].number && this.registerForm.value.adress[0].postalCode" 
          style="cursor: pointer;" class="bi bi-plus-circle-fill" (click)="addQuantity()"></i>
        </label>
        <div *ngFor="let quantity of adress().controls; let i=index" class="row" [formGroupName]="i">
          <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 margin-custom padding-custom" >
            <label *ngIf="i > 0">
            <i style="cursor: pointer;" class="bi bi-trash" (click)="removeQuantity(i)"></i>
            </label>  
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11">
           <label>Rue</label>
              <input type="text" formControlName="street" class="form-control">
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label>Numéro</label>  
              <input type="text" formControlName="number"  class="form-control">
            </div>

            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 margin-custom padding-custom" >
              <label *ngIf="i > 1000">
              <i style="cursor: pointer;" class="bi bi-trash"></i>
              </label>  
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-11">
              <label>Code postal</label>
              <ng-select
              [items]="codes_postales"
              bindLabel="postal_code"
              formControlName="postalCode"
              class="form-control input-custom"
              [closeOnSelect]="true"
              (change)="getCity(i)"
            >
            </ng-select>
               </div>
               <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                 <label>Ville</label>  
                 <input 
                 disabled
                 type="text"
                 formControlName="city"
                [value]="this.registerForm.value.adress[i].postalCode.place_name"
                 class="form-control input-custom"
               />
               </div>
              <br>
      </div>

</div>
</div>




      

      <div class="form-row">
        <div class="form-group col-12">
            <label>Responsable<b style="color: red;"> * </b></label>
            <ng-select
              [items]="admins"
              bindLabel="firstName"
              bindValue="_id"
              formControlName="manager"
              class="form-control input-custom"
              [closeOnSelect]="true"
              [ngClass]="{ 'is-invalid': submitted && f.manager.errors }"
              >
              </ng-select>
              <div *ngIf="submitted && f.manager.errors" class="invalid-feedback">
                <div *ngIf="f.manager.errors.required">Champ obligatoire.</div>
              </div>
        </div>
          
          <div class="form-group col-12">
            <label>Client(s) </label>
            <ng-select
              [items]="clients"
              bindLabel="companyName"
              bindValue="_id"
              formControlName="client"
              class="form-control input-custom"
              [closeOnSelect]="true"
              multiple = true
              
             
            >
            </ng-select>
          </div>
        </div>

    

     

      <div style="padding-top: 3%" class="text-center">
        <button
        (click)="onReset()"
          class="btn btn-secondary mr-4 button_list mb-2"
          type="reset"
        >
          Annuler
        </button>
        <button class="btn btn-primary button_list mb-2">Sauvegarder</button>
      </div>
    </form>
  </div>
</div>
<br>

<!-- credits -->


import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TemplAdminComponent } from './admin/templ-admin/templ-admin.component';
import { AuthGuard } from './_guards/auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NgxPaginationModule } from 'ngx-pagination';
import { environment } from 'src/environments/environment';
import { DashbordComponent } from './admin/templ-admin/dashbord/dashbord.component';
import { Ng2IziToastModule } from 'ng2-izitoast';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TokenInterceptorService } from './_services/token-interceptor.service';
import { ResetPassowrdComponent } from './components/reset-passowrd/reset-passowrd.component';
import { UpdatePassowrdComponent } from './components/update-passowrd/update-passowrd.component';
import { ListContactComponent } from './admin/templ-admin/list-contact/list-contact.component';
import { AddContactComponent } from './admin/templ-admin/list-contact/add-contact/add-contact.component';
import { ListClientsComponent } from './admin/templ-admin/list-clients/list-clients.component';
import { AddClientComponent } from './admin/templ-admin/list-clients/add-client/add-client.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ListSubContractorsComponent } from './admin/templ-admin/list-sub-contractors/list-sub-contractors.component';
import { AddSubContractorComponentComponent } from './admin/templ-admin/list-sub-contractors/add-sub-contractor-component/add-sub-contractor-component.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ListWorkersComponent } from './admin/templ-admin/list-workers/list-workers.component';
import { AddWorkerComponent } from './admin/templ-admin/list-workers/add-worker/add-worker.component';
import { NavbarComponent } from './admin/navbar/navbar.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ListSitesComponent } from './admin/templ-admin/list-sites/list-sites.component';
import { SortPipe } from './_helpers/sort.pipe';
import { AddSiteComponent } from './admin/templ-admin/list-sites/add-site/add-site.component';
import { PopupAddClientComponent } from './admin/templ-admin/popupAdd/popup-add-client/popup-add-client.component';
import { PopupAddContactComponent } from './admin/templ-admin/popupAdd/popup-add-contact/popup-add-contact.component';
import { PopupAddWorkerComponent } from './admin/templ-admin/popupAdd/popup-add-worker/popup-add-worker.component';
import { ListGlassComponent } from './admin/templ-admin/list-sites/list-glass/list-glass.component';
import { AddGlassComponent } from './admin/templ-admin/list-sites/list-glass/add-glass/add-glass.component';
import { PopupAddSubContractorComponent } from './admin/templ-admin/popupAdd/popup-add-sub-contractor/popup-add-sub-contractor.component';
import { PopupAddBenefitComponent } from './admin/templ-admin/popupAdd/popup-add-benefit/popup-add-benefit.component';
import { ListSuppafacsComponent } from './admin/templ-admin/list-sites/list-suppafacs/list-suppafacs.component';
import { AddSuppafacComponent } from './admin/templ-admin/list-sites/list-suppafacs/add-suppafac/add-suppafac.component';
import { ListEtablissementComponent } from './admin/templ-admin/list-etablissement/list-etablissement.component';
import { AddEtablissementComponent } from './admin/templ-admin/list-etablissement/add-etablissement/add-etablissement.component';
import { PopupAddSiteComponent } from './admin/templ-admin/popupAdd/popup-add-site/popup-add-site.component';
import { DetailsEtablissementComponent } from './admin/templ-admin/list-etablissement/details-etablissement/details-etablissement.component';
import { UpdateEtablissementComponent } from './admin/templ-admin/list-etablissement/update-etablissement/update-etablissement.component';
import { DetailsContactComponent } from './admin/templ-admin/list-contact/details-contact/details-contact.component';
import { UpdateContactComponent } from './admin/templ-admin/list-contact/update-contact/update-contact.component';
import { DetailsClientComponent } from './admin/templ-admin/list-clients/details-client/details-client.component';
import { UpdateClientComponent } from './admin/templ-admin/list-clients/update-client/update-client.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DetailsWorkerComponent } from './admin/templ-admin/list-workers/details-worker/details-worker.component';
import { UpdateWorkerComponent } from './admin/templ-admin/list-workers/update-worker/update-worker.component';
import { UpdateSubContractorComponent } from './admin/templ-admin/list-sub-contractors/update-sub-contractor/update-sub-contractor.component';
import { ListDocumentsComponent } from './admin/templ-admin/list-documents/list-documents.component';
import { DetailsSubContractorComponent } from './admin/templ-admin/list-sub-contractors/details-sub-contractor/details-sub-contractor.component';
import { DetailsSiteComponent } from './admin/templ-admin/list-sites/details-site/details-site.component';
import { UpdateSiteComponent } from './admin/templ-admin/list-sites/update-site/update-site.component';
import { ListBenefitsComponent } from './admin/templ-admin/list-benefits/list-benefits.component';
import { AddBenefitComponent } from './admin/templ-admin/list-benefits/add-benefit/add-benefit.component';
import { ListoccurencesComponent } from './admin/templ-admin/listoccurences/listoccurences.component';
import { UpdateoccurenceComponent } from './admin/templ-admin/popupAdd/updateoccurence/updateoccurence.component';
import { ListDocumentsArchivedComponent } from './admin/templ-admin/list-documents/list-documents-archived/list-documents-archived.component';
import { ListWorkersArchivedComponent } from './admin/templ-admin/list-workers/list-workers-archived/list-workers-archived.component';
import { ListSubContractorsArchivedComponent } from './admin/templ-admin/list-sub-contractors/list-sub-contractors-archived/list-sub-contractors-archived.component';
import { ListContactsArchivedComponent } from './admin/templ-admin/list-contact/list-contacts-archived/list-contacts-archived.component';
import { ListClientsArchivedComponent } from './admin/templ-admin/list-clients/list-clients-archived/list-clients-archived.component';
import { ListEtablissementArchivedComponent } from './admin/templ-admin/list-etablissement/list-etablissement-archived/list-etablissement-archived.component';
import { CalendarComponent } from './admin/templ-admin/list-benefits/calendar/calendar.component';
import { PopupAddEtablissementComponent } from './admin/templ-admin/popupAdd/popup-add-etablissement/popup-add-etablissement.component';
import { DetailsBenefitComponent } from './admin/templ-admin/list-benefits/details-benefit/details-benefit.component';
import { EventServiceService } from './_services/event-service.service';
import { DetailsSuppafacComponent } from './admin/templ-admin/list-sites/list-suppafacs/details-suppafac/details-suppafac.component';
import { ListSuppafacsArchivedComponent } from './admin/templ-admin/list-sites/list-suppafacs/list-suppafacs-archived/list-suppafacs-archived.component';
import { UpdateSuppafacComponent } from './admin/templ-admin/list-sites/list-suppafacs/update-suppafac/update-suppafac.component';
import { DetailsGlassComponent } from './admin/templ-admin/list-sites/list-glass/details-glass/details-glass.component';
import { UpdateBenefitComponent } from './admin/templ-admin/list-benefits/update-benefit/update-benefit.component';
import { UpdateGlassComponent } from './admin/templ-admin/list-sites/list-glass/update-glass/update-glass.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ListTypesBenfitsComponent } from './admin/templ-admin/list-benefits/list-types-benfits/list-types-benfits.component';
import { PopupAddTypeBenefitComponent } from './admin/templ-admin/popupAdd/popup-add-type-benefit/popup-add-type-benefit.component';
import { PopupUpdateTypeBenefitComponent } from './admin/templ-admin/popupAdd/popup-update-type-benefit/popup-update-type-benefit.component';
import { SearchPipe } from './_helpers/search.pipe';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TemplAdminComponent,
    DashbordComponent,
    ResetPassowrdComponent,
    UpdatePassowrdComponent,
    ListContactComponent,
    AddContactComponent,
    ListClientsComponent,
    AddClientComponent,
    ListSubContractorsComponent,
    AddSubContractorComponentComponent,
    ListWorkersComponent,
    AddWorkerComponent,
    NavbarComponent,
    ListSitesComponent,
    SortPipe,
    AddSiteComponent,
    PopupAddClientComponent,
    PopupAddContactComponent,
    PopupAddWorkerComponent,
    ListGlassComponent,
    AddGlassComponent,
    PopupAddSubContractorComponent,
    PopupAddBenefitComponent,
    ListSuppafacsComponent,
    AddSuppafacComponent,
    ListEtablissementComponent,
    AddEtablissementComponent,
    PopupAddSiteComponent,
    DetailsEtablissementComponent,
    UpdateEtablissementComponent,
    DetailsContactComponent,
    UpdateContactComponent,
    DetailsClientComponent,
    UpdateClientComponent,
    DetailsWorkerComponent,
    UpdateWorkerComponent,
    UpdateSubContractorComponent,
    ListDocumentsComponent,
    DetailsSubContractorComponent,
    DetailsSiteComponent,
    UpdateSiteComponent,
    ListBenefitsComponent,
    AddBenefitComponent,
    ListoccurencesComponent,
    UpdateoccurenceComponent,
    ListDocumentsArchivedComponent,
    ListWorkersArchivedComponent,
    ListSubContractorsArchivedComponent,
    ListContactsArchivedComponent,
    ListClientsArchivedComponent,
    ListEtablissementArchivedComponent,
    CalendarComponent,
    PopupAddEtablissementComponent,
    DetailsBenefitComponent,
    DetailsSuppafacComponent,
    ListSuppafacsArchivedComponent,
    UpdateSuppafacComponent,
    DetailsGlassComponent,
    UpdateBenefitComponent,
    UpdateGlassComponent,
    ListTypesBenfitsComponent,
    PopupAddTypeBenefitComponent,
    PopupUpdateTypeBenefitComponent,
    SearchPipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiSwitchModule.forRoot({
      color: '#0e309f',
      
     
    }),
    HttpClientModule,
    AngularEditorModule,
    NgxIntlTelInputModule,
    RecaptchaV3Module,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    BrowserAnimationsModule,
    Ng2IziToastModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgSelectModule,
    NgxFileDropModule,
  ],
  providers: [EventServiceService,AuthGuard,{
    provide :HTTP_INTERCEPTORS,
    useClass :TokenInterceptorService,
    multi:true
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
  },],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }


    <!-- Header -->

    <!-- Main -->
    <app-navbar></app-navbar>
    <main class="py-6 bg-surface-secondary">
        <div class="container-fluid">
            <!-- Card stats -->
            <div class="row g-6 mb-6">
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Chantiers terminés</span>
                                    <br>
                                    <span class="h3 font-bold mb-0">40</span>
                                </div>
                                
                                <div class="col-auto">
                                    <div style="color: #9feec7;padding-top: 130%;;" class="icon icon-shape text-lg rounded-circle">
                                        <i class="bi bi-bar-chart-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">Chantiers en cours </span>
                                    <br>
                                    <span class="h3 font-bold mb-0">10</span>
                                </div>
                                <div class="col-auto">
                                    <div style="color: #f4827a;padding-top: 130%;;" class="icon icon-shape text-lg rounded-circle">
                                        <i class="bi bi-bar-chart-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-6 col-12">
                    <div class="card shadow border-0">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <span class="h6 font-semibold text-muted text-sm d-block mb-2">One shot</span>
                                    <br>
                                    <span class="h3 font-bold mb-0">2</span>
                                </div>
                                <div class="col-auto">
                                    <div style="color: #8ee5ff;padding-top: 130%;" class="icon icon-shape text-lg rounded-circle">
                                        <i class="bi bi-bar-chart-fill"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 text-sm">
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
           </div>
           </main>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  private _workerURL=`${environment.baseUrl}/workers`
  constructor(private http: HttpClient) { }

     getWorkers() :Observable <any> {
      return this.http.get(`${this._workerURL}`);
      }

      createWorker(user: any):Observable<Object> {
        return this.http.post(`${this._workerURL}`,user);
      }

      getWorkerById(id : string):Observable<any>
      {
        return this.http.get<any>(`${this._workerURL}/${id}`);
    
      } 
      
      EditWorker(id:string,worker:any):Observable<Object> {
        return this.http.put(`${this._workerURL}/${id}`,worker);
      }
     
    archive_restoreWorker(id :any){
      return this.http.get(`${this._workerURL}/archive-restoreWorker/${id}`);
    }
    // date  21/08/2023 // get worker dont have subcontractor
    getWorkerNotAffected(){
      return this.http.get(`${this._workerURL}/unaffected`);
     }
     getWorkersArchiveds() :Observable <any> {
      return this.http.get(`${this._workerURL}/list_workers/archived`);
      }
}

<div class="modal-header">
    <h4 class="modal-title">Modifier un type de prestation</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
    
    </button>
  </div>
  <div class="modal-body">
       
    <div>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" class="margin-right-50">
              <div *ngIf="typeBenefit" class="form-row">
                  <div class="form-group col">
                      <label>Type </label>
                      <input type="text" formControlName="type" class="form-control input-custom" [(ngModel)]="typeBenefit.type"
                      [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
                     />
                     <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                        <div *ngIf="f.type.errors.required">Champ obligatoire.</div>
                    </div>
                  </div>
              </div>
              <div style="padding-top: 3%;" class="text-center">
                  
                  <button (click)="onReset()" class="btn btn-secondary mr-4 button_list" type="reset">Annuler</button>
                  <button  class="btn btn-primary button_list">Sauvegarder</button>
              </div>
          </form>
      </div>
  </div>


